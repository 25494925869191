import instance from 'helpers/interceptor';

export type updatePermissionByUserRequest = {
	userID: string;
	permissionID: string;
	status: boolean;
};

const execute = ({
	userID,
	permissionID,
	status,
}: updatePermissionByUserRequest): Promise<void> => {
	return instance
		.patch(
			`${process.env.REACT_APP_API_URL}/users/${userID}/permissions/${permissionID}`,
			{
				status,
			}
		)
		.then((response) => response.data)
		.catch((response) => {
			throw new Error(response.response.data.error);
		});
};

export default execute;
