import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import Select from 'react-select';

export type ModeSelectProps = {
	onChange?: (e: { value: string | undefined }) => void;
	placeholder: string;
	value?: string[];
};

export const ModeSelect = ({
	onChange,
	placeholder,
	value,
}: ModeSelectProps) => {
	const options = ['Terrestre', 'Aereo', 'Maritimo'].map((item) => ({
		label: item,
		value: item,
	}));

	return (
		<Select
			classNamePrefix="react-select"
			placeholder={placeholder}
			options={options}
			isMulti
			value={options.filter((x) => value?.includes(x.value))}
			onChange={(newValue) => {
				if (onChange != undefined) {
					onChange({ value: newValue.map((i) => i.value).join(',') });
				}
			}}
		/>
	);
};
