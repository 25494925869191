import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
// import './HomePage.css';
import { useQuery } from '@tanstack/react-query';
import { BasicTable } from 'components/Table/BasicTable';
import { Filters, PaginationState, SortingState } from '@tanstack/react-table';
import getNationalServices from 'services/getNationalTraking';
import { nationalParamsQuery } from 'types/nationalParamsQuery';
import NationalTableColumDef from './components/NationalTableColumDef';
import { useRecoilState } from 'recoil';
import { Datum } from 'types/nationalTrackingResponse';
import excelNationalDataAtom from 'atoms/excelNationalDataAtom';
import { Header } from './components/Header/Header';
import IIndexable from 'types/IIndexable';
import { difference } from 'lodash';
import moment from 'moment';
import toast from 'react-hot-toast';

var Spinner = require('react-spinkit');

function NationalPage() {
	const [filter, setFilters] = useState<any>({
		ot: '',
		products: '',
		servicios: '',
		tipoCarga: '',
		estadoEmbarque: '',
		tipoEmbalaje: '',
		orderBy: '',

		// sinceDateEmision: moment().subtract(1, 'y'),
		// untilDateEmision: moment(),
		sinceDateEmision: '',
		untilDateEmision: '',

		sinceDateRecogida: '',
		untilDateRecogida: '',
		sinceDateEntrega: '',
		untilDateEntrega: '',
		rawRefs: '',
	});

	const [, setExcelData] = useRecoilState<Array<Datum>>(
		excelNationalDataAtom
	);
	const defaultRange = useMemo(
		() => ({ start: moment().subtract(6, 'months'), end: moment() }),
		[]
	);

	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});

	const [sorting, setSorting] = useState<SortingState>([]);
	useEffect(() => {
		let sort = sorting
			.map((item) => `${item.id} ${item.desc ? 'DESC' : 'ASC'}`)
			.join(',');
		setFilters((oldFilter: any) => {
			return { ...oldFilter, orderBy: sort };
		});
	}, [sorting]);

	const dateFormat = (date: string, separator: string = '/') =>
		date !== null && date !== ''
			? moment(date).format(`DD${separator}MM${separator}YYYY`)
			: date;
	const fetchDataOptions: nationalParamsQuery = {
		page: pageIndex.toString(),
		size: pageSize.toString(),
		ot: filter.ot,
		products: filter.products,
		servicios: filter.servicios,
		tipoCarga: filter.tipoCarga,
		estadoEmbarque: filter.estadoEmbarque,
		tipoEmbalaje: filter.tipoEmbalaje,

		sinceDateEmision: dateFormat(
			filter.sinceDateEmision === ''
				? defaultRange.start
				: filter.sinceDateEmision,
			'-'
		),
		untilDateEmision: dateFormat(
			filter.untilDateEmision === ''
				? defaultRange.end
				: filter.untilDateEmision,
			'-'
		),
		// sinceDateEmision: dateFormat(filter.sinceDateEmision),
		// untilDateEmision: dateFormat(filter.untilDateEmision),

		sinceDateRecogida: dateFormat(filter.sinceDateRecogida),
		untilDateRecogida: dateFormat(filter.untilDateRecogida),
		sinceDateEntrega: dateFormat(filter.sinceDateEntrega),
		untilDateEntrega: dateFormat(filter.untilDateEntrega),
		orderBy: filter.orderBy,
		rawRefs: filter.rawRefs,
	};

	const columns = useMemo(() => NationalTableColumDef, []);
	const { data, error, isFetching, isPreviousData, isInitialLoading } =
		useQuery({
			queryKey: ['tracking-national-table-data', fetchDataOptions],
			queryFn: async () => {
				if (!isInitialLoading) {
					toast.dismiss();
					toast.loading('Cargando...');
				}
				const data = await getNationalServices(fetchDataOptions);
				return data;
			},
			keepPreviousData: true,
			staleTime: 5000,
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				if (!isInitialLoading) {
					toast.dismiss();
					toast.success('Carga Exitosa');
				}
			},
			onError: () => {
				toast.dismiss();
				toast.error('Ocurrio un error');
			},
		});

	const param: nationalParamsQuery = {
		...fetchDataOptions,
		page: '0',
		size: '1000',
	};
	useQuery(
		['tracking-national-excel-data', param],
		() => getNationalServices(param),
		{
			staleTime: 5000,
			refetchOnWindowFocus: false,
			onSuccess(data) {
				setExcelData(data.data);
			},
		}
	);

	const defaultData = useMemo(() => [], []);

	if (isInitialLoading) {
		return <Spinner className="main-loading" name="wandering-cubes" />;
	}

	const clean = (selected: string) => {
		const dic: IIndexable = {
			ot: 'ot',
			products: 'products',
			servicios: 'servicios',
			tipoCarga: 'tipoCarga',
			estadoEmbarque: 'estadoEmbarque',
			tipoEmbalaje: 'tipoEmbalaje',

			dateEmision: 'sinceDateEmision-untilDateEmision',
			dateRecogida: 'sinceDateRecogida-untilDateRecogida',
			dateEntrega: 'sinceDateEntrega-untilDateEntrega',
			referencia_destinatario: 'rawRefs',
		};

		const filtrableKeys: string[] = Object.values(dic).flatMap((val) =>
			val.split('-')
		);
		let filterToShow = selected
			.split(',')
			.filter((item) => item !== '')
			.flatMap((element) => dic[element].split('-'));
		let filterStateCleaned = difference(filtrableKeys, filterToShow)
			.map((item) => ({ [item]: '' }))
			.reduce((acc, item) => ({ ...acc, ...item }), {});
		setFilters((old: any) => ({ ...old, ...filterStateCleaned }));
	};

	return (
		<Fragment>
			<Header
				defaultValues={['dateEmision']}
				otChange={(e) =>
					setFilters((oldFilter: any) => {
						return { ...oldFilter, ot: e };
					})
				}
				refDestinatarioChange={(e) =>
					setFilters((oldFilter: any) => {
						return { ...oldFilter, rawRefs: e };
					})
				}
				productsChange={(e) =>
					setFilters((oldFilter: any) => {
						return { ...oldFilter, products: e };
					})
				}
				serviceChange={(e) =>
					setFilters((oldFilter: any) => {
						return { ...oldFilter, servicios: e };
					})
				}
				typeLoadChange={(e) =>
					setFilters((oldFilter: any) => {
						return { ...oldFilter, tipoCarga: e };
					})
				}
				shipmentStatusLoadChange={(e) =>
					setFilters((oldFilter: any) => {
						return { ...oldFilter, estadoEmbarque: e };
					})
				}
				tipoEmbalaChange={(e) =>
					setFilters((oldFilter: any) => {
						return { ...oldFilter, tipoEmbalaje: e };
					})
				}
				dateEntregaDateRange={{
					startDate:
						filter.sinceDateEmision !== ''
							? filter.sinceDateEmision
							: defaultRange.start,
					endDate:
						filter.untilDateEmision !== ''
							? filter.untilDateEmision
							: defaultRange.end,
					// startDate: filter.sinceDateEmision ?? moment(),
					// endDate: filter.untilDateEmision ?? moment(),
					onChange: (e) =>
						setFilters((oldFilter: any) => {
							return {
								...oldFilter,
								sinceDateEmision: e[0] == null ? '' : e[0],
								untilDateEmision: e[1] == null ? '' : e[1],
							};
						}),
				}}
				dateRecogidaDateRange={{
					startDate: filter.sinceDateRecogida ?? moment(),
					endDate: filter.untilDateRecogida ?? moment(),
					onChange: (e) =>
						setFilters((oldFilter: any) => {
							return {
								...oldFilter,
								sinceDateRecogida: e[0] == null ? '' : e[0],
								untilDateRecogida: e[1] == null ? '' : e[1],
							};
						}),
				}}
				dateEntregateRange={{
					startDate: filter.sinceDateEntrega ?? moment(),
					endDate: filter.untilDateEntrega ?? moment(),
					onChange: (e) =>
						setFilters((oldFilter: any) => {
							return {
								...oldFilter,
								sinceDateEntrega: e[0] == null ? '' : e[0],
								untilDateEntrega: e[1] == null ? '' : e[1],
							};
						}),
				}}
				masterSelectChange={clean}
			/>
			<BasicTable
				registerCount={
					data != undefined ? data.pagination.registerCount : 0
				}
				data={data != undefined ? data.data : defaultData}
				columns={columns}
				loading={isFetching}
				pagination={{
					pageIndex,
					pageSize,
				}}
				setPagination={setPagination}
				pageCount={
					data != undefined ? data.pagination.numberOfPages : 0
				}
				sorting={sorting}
				setSorting={setSorting}
			/>
		</Fragment>
	);
}

export default NationalPage;
