import instance from 'helpers/interceptor';
export type createQuestionRequest = {
	question: string;
	transport_way: string;
};

const execute = (data: createQuestionRequest): Promise<void> => {
	return instance
		.post('/questions', data)
		.then((response) => response.data)
		.catch((response) => {
			throw new Error(response.response.data.error);
		});
};

export default execute;
