import { useQuery } from '@tanstack/react-query';
import Select from 'react-select';
import getSenders from 'services/getSenders';

export type SenderSelectProps = {
	onChange?: (e: { value: string | undefined }) => void;
	placeholder: string;
};

export const SenderSelect = ({ onChange, placeholder }: SenderSelectProps) => {
	const { status, data, error, isFetching, isPreviousData, isLoading } =
		useQuery(['sender-data'], () => getSenders(), {
			refetchOnWindowFocus: false,
		});

	const options =
		data === undefined
			? []
			: data.map((item) => ({
					label: item.name,
					value: item.ref,
			  }));

	return (
		<Select
			classNamePrefix="react-select"
			placeholder={placeholder}
			options={options}
			isMulti
			onChange={(newValue) => {
				if (onChange != undefined) {
					onChange({ value: newValue.map((i) => i.value).join(',') });
				}
			}}
		/>
	);
};
