import axios from "axios";
import instance from "helpers/interceptor";
import TokenService from "./tokenService";


export type validateUserParams = {
    email:string
} 


export type UserValidated = {
    rut_usuario: string;
    nombre_usuario: string;
    empresa_usuario: string;
    email: string;
    rut_cliente: string;
    nombre_fantasia: string;
    selected: boolean;
} 

const execute = async (data:validateUserParams) : Promise<UserValidated[]> => {
    return instance.get<UserValidated[]>('/users/ruts', { params:data })
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;