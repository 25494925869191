import './MiddlewarePage.css'
import { Container, Row, Col } from 'react-bootstrap';
import React from 'react';

const MiddlewarePage = () => {
	return (
		<Container>
			<Row>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'center'}}
				>
					<img
						src={require('../../assets/img/middleware.png')}
						className="w-75"
						alt="embebido"
					/>
					<p className='my-3 text-3xl'>MIDDLEWARE</p>
				</Col>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'justify'}}
				>
					<p className='text-xl'>El middleware es un software que permite la interconexión de sistemas y aplicaciones. Este proceso es esencial para procesar la información en tiempo real. Gracias a él, se pueden completar cotizaciones y seguir la carga estableciendo una conexión entre sistemas informáticos. La integración de APIs mediante el middleware es esencial para realizar el seguimiento exacto de los envíos. La API es lo que el cliente utiliza para hacer solicitudes y la data solicitada viaja a través del middleware y, finalmente, conecta con otro extremo mediante Webhooks para brindar el resultado solicitado.</p>
				</Col>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'center'}}
				>
					<p className='text-lg font-bold'>BENEFICIOS PARA NUESTROS CLIENTES</p>
				</Col>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'justify'}}
				>
					<ul className="list-disc ml-5 text-lg lg:text-xl flex flex-col gap-2"><li>Permite reducir tiempos en tareas manuales.</li><li>Errar es humano y puede suceder en tareas repetitivas. Con este software se disminuyen los errores y se optimiza el tiempo.</li><li>La automatización permite a las personas dedicarse a otras labores.</li></ul>
				</Col>
			</Row>
		</Container>
	);
};

export default MiddlewarePage;
