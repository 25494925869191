import PopupEvent from 'components/PopupEvent/PopupEvent';
import PopupOrderFiles from 'components/PopupOrderFiles/PopupOrderFiles';
import { FaEye, FaBars, FaBell } from 'react-icons/fa';
import 'reactjs-popup/dist/index.css';
import '@szhsin/react-menu/dist/index.css';
import { trackingType } from 'types/trackingType';
import PopupInternationalCloneOrder from 'components/PopupInternationalCloneOrder/PopupInternationalCloneOrder';
import './PopupActions.css';
import { Button, Dropdown } from 'react-bootstrap';
import PopupNationalCloneOrder from 'components/PopupNationalCloneOrder/PopupNationalCloneOrder';
import PopupLastMile from 'components/PopupLastMile/PopupLastMile';
import { useAuth } from 'hooks/useAuth';
import { permission } from 'services/getPermissionByUser';
import { userPermissions } from 'enums/userPermissions';
import { useNavigate } from 'react-router-dom';
// import { mdiBellCog } from '@mdi/js';
// import Icon from '@mdi/react';

export type PopupActionsProps = {
	data: any;
	type: trackingType;
};

export const PopupActions = ({ data, type }: PopupActionsProps) => {
	const tType = type == 'international' ? 'in' : 'na';
	const id = type == 'international' ? data.ot : data.ot;
	const { userLogged } = useAuth();
	const navigate = useNavigate();

	return (
		<Dropdown className="position-static">
			<Dropdown.Toggle variant="primary">
				<FaBars />
			</Dropdown.Toggle>

			<Dropdown.Menu>
				<Dropdown.Item as="span">
					<Button
						variant="primary"
						target="_blank"
						style={{ width: '100%' }}
						href={`https://trackingdelpa.com/index.html?order=${id}&type=${tType}`}
					>
						<FaEye /> Visualizar tracking
					</Button>
				</Dropdown.Item>
				<Dropdown.Item as="span">
					<PopupEvent orden={id} type={type} />
				</Dropdown.Item>
				<Dropdown.Item as="span">
					<PopupOrderFiles orden={id} type={type} />
				</Dropdown.Item>
				{/* <CloneMenuButton
					type={type}
					id={id}
					permissions={userLogged?.permissions ?? []}
				/> */}

				{type == 'national' && data.ultima_milla ? (
					<Dropdown.Item as="span">
						<PopupLastMile orden={id} />
					</Dropdown.Item>
				) : null}

				<Dropdown.Item as="span">
					<Button
						className="w-100"
						onClick={() => {
							navigate({
								pathname: `/settings/${type}/${id}`,
								search:
									type === 'national'
										? ''
										: `?way=${
												data.mode === 'Aereo'
													? 'a'
													: data.mode === 'Terrestre'
													? 't'
													: 'm'
										  }`,
							});
						}}
					>
						<FaBell />
						{/* <span style={{ width: '14px' }}>
							<Icon
								path={mdiBellCog}
								size={1}
								color="#ffff"
							/>
						</span> */}
						Configurar notificaciones
					</Button>
				</Dropdown.Item>
				{/* <Link to={`/settings?type_f=${type}&ot=${id}`} /> */}
			</Dropdown.Menu>
		</Dropdown>
	);
};

const CloneMenuButton = ({
	type,
	id,
	permissions,
}: {
	type: string;
	id: number;
	permissions: Array<permission>;
}) => {
	let found =
		permissions.findIndex(
			(p) => p.id == userPermissions.clone && p.active
		) !== -1;
	if (!found) return null;

	if (type == 'international') {
		return (
			<>
				<Dropdown.Item as="span">
					<PopupInternationalCloneOrder orden={id} />
				</Dropdown.Item>
			</>
		);
	}

	if (type == 'national') {
		return (
			<>
				<Dropdown.Item as="span">
					<PopupNationalCloneOrder orden={id} />
				</Dropdown.Item>
			</>
		);
	}

	return null;
};
