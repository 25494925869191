import { FC, useEffect, useRef, useState } from 'react';
import { FaEye, FaStarHalfAlt } from 'react-icons/fa';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// import './PopupLastMile.css';
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	Modal,
	ProgressBar,
	Row,
} from 'react-bootstrap';
import Rating from '@mui/material/Rating';
import IIndexable from 'types/IIndexable';
import getReviewQuestions, { question } from 'services/getReviewQuestions';
import insertReview, { insertReviewRequest } from 'services/insertReview';
import toast from 'react-hot-toast';
import { nationalTrackingResponse } from 'types/nationalTrackingResponse';
import getReviewByOT, { answers } from 'services/getReviewByOT';
import updateReview, { updateReviewRequest } from 'services/updateReview';

var Spinner = require('react-spinkit');

export type PopupLastStatusProps = {
	text: string;
};

const PopupLastStatus: FC<PopupLastStatusProps> = ({ text }) => {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<Button
				style={{
					border: 'none',
					background: 'inherit',
				}}
				variant="secondary"
				size="sm"
				onClick={handleShow}
			>
				<FaEye
					color="black"
					style={{ fontSize: '25px' }}
				/>
			</Button>

			<Modal
				show={show}
				onHide={handleClose}
				centered={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>Ultimo estado</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<div dangerouslySetInnerHTML={{ __html: text }}></div>
					</Container>
				</Modal.Body>
			</Modal>
		</>
	);
};
export default PopupLastStatus;
