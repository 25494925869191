// import * as BTable from 'react-bootstrap/Table';
import {
  ColumnDef,
    ColumnOrderState,
    flexRender,
    getCoreRowModel,
    Header,
    useReactTable,
    // Table,
    Column,
    OnChangeFn,
    SortingState,
    getSortedRowModel,
  } from '@tanstack/react-table'
import PopupVisibilityColums from 'components/PopupVisibilityColums/PopupVisibilityColums'
import { FC, forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { Col, Container, Form, Pagination, Row, Table } from 'react-bootstrap'

import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { FaAngleUp, FaAngleDown } from "react-icons/fa";

// import './_tables.scss'

// import './BasicTable.css'
import { range } from 'underscore';
import { ExportExcelButton } from 'components/ExportExcelButton/ExportExcelButton';
import PopupInternationalCreateOrder from 'components/PopupInternationalCreateOrder/PopupInternationalCreateOrder';
import { useLocation } from 'react-router-dom'
import PopupCreateUser from 'components/PopupCreateUser/PopupCreateUser'

// import '../../App.css'
// import './BasicTable.css'
var Spinner = require('react-spinkit');

export type Pagination = {
  pageIndex:number,
  pageSize:number
}

export type TableProps<T> = {
  data: Array<T>
  columns: Array<ColumnDef<T>>

  loading?: boolean
  pagination: Pagination
  setPagination: OnChangeFn<any>
  pageCount: number
  registerCount: number

  sorting: SortingState
  setSorting: OnChangeFn<any>
}

const reorderColumn = (
  draggedColumnId: string,
  targetColumnId: string,
  columnOrder: string[]
): ColumnOrderState => {
  columnOrder.splice(
    columnOrder.indexOf(targetColumnId),
    0,
    columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string
  )
  return [...columnOrder]
}

const DraggableColumnHeader: FC<{
  header: Header<any, unknown>
  // table: Table<any>
  table:any,
  storageKey:string
}> = ({ header, table, storageKey }) => {
  const { getState, setColumnOrder } = table
  const { columnOrder } = getState()
  const { column } = header

  const [, dropRef] = useDrop({
    accept: 'column',
    drop: (draggedColumn: Column<any>) => {
      const newColumnOrder = reorderColumn(
        draggedColumn.id,
        column.id,
        columnOrder
      )
      setColumnOrder(newColumnOrder)
      localStorage.setItem(storageKey, JSON.stringify(table.getState().columnOrder))
    },
  })

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: 'column',
  })

  return (
    <th
      className={ column.id == 'action' ? 'action_col': '' }
      ref={dropRef}
      colSpan={header.colSpan}
      style={{ opacity: isDragging ? 0.5 : 1, cursor: 'grab'}}
    >
      <div ref={previewRef} {...{
          className:  'content-infotable',
          onClick: (e)=>{
            let handle = header.column.getToggleSortingHandler();
            if(handle !== undefined){
              handle(e);
            }
          },
      }}>
        <span ref={dragRef} className='d-flex' style={{ alignItems: 'center' }}>
          {
            header.isPlaceholder
            ? null
            : flexRender(header.column.columnDef.header, header.getContext())
          }
          {{
            // asc: <FaAngleUp />,
            // desc: <FaAngleDown />,
            asc: <span className='d-flex flex-column'><FaAngleUp /><FaAngleDown color='lightgray'/></span>,
            desc: <span className='d-flex flex-column'><FaAngleUp color='lightgray' /><FaAngleDown/></span>,
          }[header.column.getIsSorted() as string] ?? (column.columnDef.enableSorting ? <span className='d-flex flex-column'><FaAngleUp color='lightgray'/><FaAngleDown color='lightgray'/></span> : null)}
        </span>
      </div>
    </th>
  )
}

// const BasicTable = forwardRef<HTMLTableElement, TableProps<any>>(({ 
const BasicTable = (({ 
  data, 
  columns, 
  loading,
  pagination,
  setPagination,
  pageCount,

  sorting,
  setSorting,

  registerCount
}:TableProps<any>) => {

  const defaultOrderColum = useMemo(()=> columns.map(column => column.id as string), []);
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>(defaultOrderColum)
  const [columnVisibility, setColumnVisibility] = useState({});
  const location = useLocation();
  // const [sorting, setSorting] = useState<SortingState>([])
  // useEffect(()=>{
  //   console.log(pagination);
  // }, [pagination]);

  const table = useReactTable({
    data,
    columns,
    pageCount: pageCount,
    // autoResetPageIndex: true,
    // autoResetPage: false,
    // autoResetFilters: false
    state: {
      sorting,
      pagination,
      columnOrder,
      columnVisibility
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    manualSorting:true
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
  })



  // use this ref to keep track of updating internal table state
  const tableStateUpdateRef = useRef(false);

  useEffect(() => {
    tableStateUpdateRef.current = true;
    // setFetchInfo({ sorted: sortBy, pageIndex, pageSize });
  }, [ pagination.pageIndex, pagination.pageSize]);

  // reset the page index to 0 when sorting changes
  useEffect(() => {
    table.setPageIndex(0);
  }, [table.setPageIndex]);

  /* reset the page index to 0 when the table data updates due to something
  other than internal table state changes
  */
  useEffect(() => {
    if (!tableStateUpdateRef.current) {
      table.setPageIndex(0);
    }
  }, [data, table.setPageIndex]);

  // clear our ref when the data is loaded, after we perform any side effects
  useEffect(() => {
    tableStateUpdateRef.current = false;
  }, [data]);


  useEffect(() => {
      let savedOrder = localStorage.getItem(location.pathname+'col-order');
      setColumnOrder(savedOrder !== null ? JSON.parse(savedOrder) : defaultOrderColum);

      let savedVisibility = localStorage.getItem(location.pathname+'col-visibility') ?? "{}";
      setColumnVisibility(JSON.parse(savedVisibility))
  }, []);
  
  const current = table.getState().pagination.pageIndex;
  let range_array:number[] = [];
  if(current === 0 && table.getPageCount() < 2){
  }else if(current === 0 || current === 1){
    range_array = range(0, table.getPageCount() > 4 ? 5 : table.getPageCount());
  }else if(current > 1 && current < table.getPageCount() - 3){
    range_array = range(current - 2, current + 3);
  }else{
    let init = table.getPageCount() - 5;
    range_array = range(init < 0 ? 0 : init, table.getPageCount());
  }
  
  return (<DndProvider backend={HTML5Backend}>
      <Container fluid>
          <Row>
            <Col sm={2}>
              <Form.Select 
                value={table.getState().pagination.pageSize}
                onChange={e => {
                  table.setPageSize(Number(e.target.value))
                }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Form.Select>
            </Col>
            {(location.pathname === '/international/' || location.pathname === '/national/') && 
            <Col sm={10}>
              <ExportExcelButton 
                  columOrder={table.getState().columnOrder}
                  columVisibility={table.getState().columnVisibility}
              />
              <PopupVisibilityColums
                table={table}
              />
              {/* { location.pathname === '/international/' && <PopupInternationalCreateOrder /> } */}
            </Col>}
          </Row>
          <div className='my-2' style={{overflowX:"auto"}}>
            <Table className='table table-bordered table-striped mg-b-0 text-md-nowrap'>
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <DraggableColumnHeader
                        key={header.id}
                        header={header}
                        table={table}
                        storageKey={ location.pathname+'col-order' }
                      />
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {
                  table.getRowModel().rows.map(row => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map(cell => (
                        <td 
                          key={cell.id}
                          className={ cell.column.id == 'action' ? 'action_col': '' }
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))
                  // table.getRowModel().rows.length > 0 
                  // && 
                  // (table.getRowModel().rows.map(row => (
                  //   <tr key={row.id}>
                  //     {row.getVisibleCells().map(cell => (
                  //       <td 
                  //         key={cell.id}
                  //         className={ cell.column.id == 'action' ? 'action_col': '' }
                  //       >
                  //         {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  //       </td>
                  //     ))}
                  //   </tr>
                  // ))) 
                  // || 
                  // (<tr>
                  //   <td align='center' colSpan={table.getAllLeafColumns().length}>No hay información disponible.</td>
                  // </tr>) 
                }
              </tbody>
            </Table>
          </div>
          <div className='d-flex justify-content-between'>
            <div>
              <span>
                <span>
                  Mostrar {table.getState().pagination.pageIndex + 1} de{' '}
                  {table.getPageCount()}{' '}
                </span>
              </span>
              <span>
              {' - '}
              </span>
              {/* <span>
                Ir a la página:
                <input
                  type="number"
                  defaultValue={table.getState().pagination.pageIndex + 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    table.setPageIndex(page)
                  }}
                  className="border p-1 rounded w-16 paginationSelect"
                />
              </span> */}
              <span>Total registros: {registerCount} </span>
            </div>
            <Pagination>
              
              <Pagination.First 
                onClick={() => table.setPageIndex(0)}
                disabled={!table.getCanPreviousPage()}
              />
              <Pagination.Prev
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              />

              {range_array.map(item => {
                if(item === table.getState().pagination.pageIndex){
                  return <Pagination.Item key={item} active onClick={()=> table.setPageIndex(item)}>{ item + 1 }</Pagination.Item>;
                }
                return <Pagination.Item key={item} onClick={()=> table.setPageIndex(item)}>{ item + 1 }</Pagination.Item>;
              })}

              <Pagination.Next 
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              />
              <Pagination.Last 
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                disabled={!table.getCanNextPage()}
              />
            </Pagination>
          </div>
      </Container>
  </DndProvider>)
});

export{ BasicTable };