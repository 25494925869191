import { useQuery } from '@tanstack/react-query';
import Select from 'react-select';
import getExecutiveUser from 'services/getExecutiveUser';

export type ExecutiveSelectProps = {
	onChange?: (e: { value: string | undefined }) => void;
	placeholder: string;
};

export const ExecutiveSelect = ({
	onChange,
	placeholder,
}: ExecutiveSelectProps) => {
	const { data, error, isFetching, isLoading } = useQuery(
		['executive-data'],
		() => getExecutiveUser(),
		{ staleTime: 5000, refetchOnWindowFocus: false }
	);

	const options =
		data === undefined
			? []
			: data
					.filter((x) => x)
					.map((item) => ({
						label: item.name,
						value: item.id,
					}));

	return (
		<Select
			classNamePrefix="react-select"
			placeholder={placeholder}
			options={options}
			isMulti
			onChange={(newValue) => {
				if (onChange != undefined) {
					onChange({ value: newValue.map((i) => i.value).join(',') });
				}
			}}
		/>
	);
};
