import instance from 'helpers/interceptor';

export type answers = {
	id: number;
	id_question: number;
	id_user: number;
	ot: string;
	review: number;
	transport_way: 'international' | 'national';
};

export type reviewByOtResponse = {
	stats: { votes: number; points: number; perc_votes: number }[];
	total_votes: number;
	user_reviews: answers[];
};

const execute = ({
	ot,
	transport_way,
	user_id,
}: {
	ot: string;
	transport_way: 'international' | 'national';
	user_id?: number;
}): Promise<reviewByOtResponse> => {
	return instance
		.get(`/answers/${ot}`, { params: { transport_way, user_id } })
		.then((response) => response.data)
		.catch((response) => {
			throw new Error(response.response.data.error);
		});
};

export default execute;
