import { FaTruck, FaShip, FaPlane } from 'react-icons/fa';
import React, { useMemo, useState } from 'react';
import './Header.css';
import { MyCard } from '../Bootstrap/Componentes';
import { FC } from 'react';
import {
	OriginCountrySelect,
	DestineCountrySelect,
} from 'components/CountrySelect/CountrySelect';
import { ModeSelect } from 'components/ModeSelect/ModeSelect';
// import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { ServiceSelect } from 'components/ServiceSelect/ServiceSelect';
import { StatusSelect } from 'components/StatusSelect/StatusSelect';
import { DatePicker } from 'components/DatePicker/DatePicker';
import { ReceptorSelect } from 'components/ReceptorSelect/ReceptorSelect';
import { SenderSelect } from 'components/SenderSelect/SenderSelect';
import { useRecoilValue } from 'recoil';
import headerCartAtom from 'atoms/headerCartAtom';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import { LastStatusSelect } from 'components/LastStatusSelect/LastStatusSelect';
import IIndexable from 'types/IIndexable';
import { DateRangePickerProps } from 'types/DateRangePickerProps';

export type HeaderProps = {
	originChange: (event: string) => void;
	destinationChange: (event: string) => void;
	modeChange: (event: string) => void;
	serviceChange: (event: string) => void;
	statusChange: (event: string) => void;
	receptorChange: (event: string) => void;
	senderChange: (event: string) => void;
	otChange: (event: string) => void;

	refSenderChange: (event: string) => void;
	refReceiverChange: (event: string) => void;

	// refSender: 'Ref. Remitente',
	//   refReceiver: 'Ref. Consignatario',

	dateRange: DateRangePickerProps;
	etdDateRange: DateRangePickerProps;
	etaDateRange: DateRangePickerProps;

	mercaderiaListaParaEmbarqueDateRange: DateRangePickerProps;
	recogidaEnOrigenDateRange: DateRangePickerProps;
	arriboAduanaOrigenDateRange: DateRangePickerProps;
	liberacionAduanaOrigenDateRange: DateRangePickerProps;
	ingresoAduanaDestinoDateRange: DateRangePickerProps;
	liberacionAduanaDestinoDateRange: DateRangePickerProps;
	entregaFinalDateRange: DateRangePickerProps;

	masterSelectChange: (e: string) => void;
	lastStateChange: (e: string) => void;

	defaultValues?: Array<string>;
};

export const Header: FC<HeaderProps> = ({
	originChange,
	destinationChange,
	modeChange,
	serviceChange,
	statusChange,
	receptorChange,
	senderChange,
	otChange,
	dateRange,
	etdDateRange,
	etaDateRange,

	mercaderiaListaParaEmbarqueDateRange,
	recogidaEnOrigenDateRange,
	arriboAduanaOrigenDateRange,
	liberacionAduanaOrigenDateRange,
	ingresoAduanaDestinoDateRange,
	liberacionAduanaDestinoDateRange,
	entregaFinalDateRange,

	masterSelectChange,
	lastStateChange,

	refSenderChange,
	refReceiverChange,

	defaultValues,
}) => {
	const headerValues = useRecoilValue(headerCartAtom);
	const [drops, setDrops] = useState(defaultValues ?? []);
	const [mode, setMode] = useState<any[]>([]);

	const options = [
		{
			label: 'Estado embarque',
			value: 'status',
		},
		{
			label: 'Pais origen',
			value: 'origin',
		},
		{
			label: 'Pais destino',
			value: 'destiny',
		},
		{
			label: 'Consignatario',
			value: 'receptor',
		},
		{
			label: 'Fecha ot',
			value: 'otDate',
		},
		{
			label: 'ETD',
			value: 'etd',
		},
		{
			label: 'ETA',
			value: 'eta',
		},
		{
			label: 'Remitente',
			value: 'sender',
		},
		{
			label: 'Modal(via de transporte)',
			value: 'mode',
		},
		{
			label: 'Tipo de servicio',
			value: 'service',
		},
		{
			label: 'Ot delpa',
			value: 'ot',
		},
		{
			label: 'Ref. consignatario',
			value: 'refReceiver',
		},
		{
			label: 'Ref. remitente',
			value: 'refSender',
		},
		{
			label: 'Ultimo estado',
			value: 'lastStatus',
		},
	];

	const filters: IIndexable = useMemo(
		() => ({
			status: (
				<StatusSelect
					key="status"
					placeholder={'Estado embarque'}
					onChange={(e) => {
						if (e.value == undefined) return;

						statusChange(e.value);
					}}
				/>
			),
			origin: (
				<OriginCountrySelect
					key="origin"
					placeholder={'País de origen'}
					onChange={(e) => {
						if (e.value == undefined) return;

						originChange(e.value);
					}}
				/>
			),
			destiny: (
				<DestineCountrySelect
					key="destiny"
					placeholder={'País de destino'}
					onChange={(e) => {
						if (e.value == undefined) return;

						destinationChange(e.value);
					}}
				/>
			),
			receptor: (
				<ReceptorSelect
					key="receptor"
					placeholder={'Consignatario'}
					onChange={(e) => {
						if (e.value == undefined) return;

						receptorChange(e.value);
					}}
				/>
			),
			otDate: (
				<span key="otDate">
					<DatePicker
						startDate={dateRange.startDate}
						endDate={dateRange.endDate}
						placeholder="Fecha ot inicio - Fecha ot final"
						onChange={dateRange.onChange}
					/>
				</span>
			),
			etd: (
				<span key="etd">
					<DatePicker
						placeholder="ETD inicio - ETD fin"
						onChange={etdDateRange.onChange}
						initialEmpty={true}
					/>
				</span>
			),
			eta: (
				<span key="eta">
					<DatePicker
						placeholder="ETA inicio - ETA fin"
						onChange={etaDateRange.onChange}
						initialEmpty={true}
					/>
				</span>
			),
			sender: (
				<SenderSelect
					key="sender"
					placeholder={'Remitente'}
					onChange={(e) => {
						if (e.value == undefined) return;

						senderChange(e.value);
					}}
				/>
			),
			mode: (
				<ModeSelect
					key="mode"
					placeholder={'Modo de transporte'}
					onChange={(e) => {
						if (e.value === undefined) return;

						let values: string[] = e.value
							.split(',')
							.filter((x) => x !== '');
						setMode(values);

						modeChange(e.value);
					}}
					value={mode}
				/>
			),
			service: (
				<ServiceSelect
					key="service"
					placeholder={'Tipo de servicio'}
					onChange={(e) => {
						if (e.value == undefined) return;

						serviceChange(e.value);
					}}
				/>
			),
			ot: (
				<span key="ot">
					<Form.Control
						type="text"
						placeholder="Ot delpa"
						onChange={(e) => {
							setTimeout(() => {
								if (e.target.value.length > 3) {
									otChange(e.target.value);
								}

								if (e.target.value.length === 0) {
									otChange('');
								}
							}, 3000);
						}}
					/>
				</span>
			),
			refSender: (
				<span key="refSender">
					<Form.Control
						type="text"
						placeholder="Ref. remitente"
						onChange={(e) => {
							setTimeout(() => {
								if (e.target.value.length > 3) {
									refSenderChange(e.target.value);
								}

								if (e.target.value.length === 0) {
									refSenderChange('');
								}
							}, 2000);
						}}
					/>
				</span>
			),
			refReceiver: (
				<span key="refReceiver">
					<Form.Control
						type="text"
						placeholder="Ref. consignatario"
						onChange={(e) => {
							setTimeout(() => {
								if (e.target.value.length > 3) {
									refReceiverChange(e.target.value);
								}

								if (e.target.value.length === 0) {
									refReceiverChange('');
								}
							}, 2000);
						}}
					/>
				</span>
			),
			lastStatus: (
				<LastStatusSelect
					key="lastStatus"
					placeholder={'Ultimo estado'}
					onChange={(e) => {
						if (e.value == undefined) return;

						lastStateChange(e.value);
					}}
				/>
			),
		}),
		[mode]
	);

	const handleOnClickCard = (way: string) => {
		setMode([way]);
		setDrops((prev) => (prev.includes('mode') ? prev : [...prev, 'mode']));
		modeChange(way);
	};

	return (
		<header className="App-header">
			<Container fluid>
				<Row>
					<Col md={4}>
						<MyCard
							icon={<FaPlane className="fa-icon" />}
							msg="Total Aereo"
							value={headerValues.aereo}
							onClick={() => {
								// setMode((prev) =>
								// 	prev.includes('Aereo')
								// 		? prev
								// 		: [...prev, 'Aereo']
								// );
								handleOnClickCard('Aereo');
							}}
						/>
					</Col>
					<Col md={4}>
						<MyCard
							icon={<FaShip className="fa-icon" />}
							msg="Total Maritimo"
							value={headerValues.maritimo}
							onClick={() => handleOnClickCard('Maritimo')}
						/>
					</Col>
					<Col md={4}>
						<MyCard
							icon={<FaTruck className="fa-icon" />}
							msg="Total Terrestre"
							value={headerValues.terrestre}
							onClick={() => handleOnClickCard('Terrestre')}
						/>
					</Col>
				</Row>
			</Container>
			<Container fluid>
				<Select
					classNamePrefix="react-select"
					placeholder="Filtro maestro"
					defaultValue={(defaultValues ?? []).map(
						(x) =>
							options.find((y) => y.value == x) ?? {
								label: 'undefined',
								value: '',
							}
					)}
					value={(drops ?? []).map(
						(x) =>
							options.find((y) => y.value == x) ?? {
								label: 'undefined',
								value: '',
							}
					)}
					options={options}
					isMulti
					onChange={(newValue) => {
						let list = newValue.map(({ value }) => value);
						setDrops(list);
						masterSelectChange(list.join(','));
					}}
				/>
			</Container>
			<Container
				fluid
				className="mt-3"
			>
				<Row className="row-container">
					{drops.map((key) => filters[key])}
				</Row>
			</Container>
		</header>
	);
};
