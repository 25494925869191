import React from 'react';
import Select from 'react-select';
import Card from 'react-bootstrap/Card';
import { Badge, Col, Row } from 'react-bootstrap';

type Props = {
	msg: string;
	icon: JSX.Element;
	value: number;
	onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

export function MyCard(props: Props) {
	return (
		<Card className="mb-3">
			<Card.Body>
				<Row>
					<Col md={4}>
						<div className="col-md-4 card-icon">{props.icon}</div>
					</Col>
					<Col md={8}>
						<Card.Title>{props.msg}</Card.Title>
						<Badge
							className="card-badge"
							bg="primary"
							onClick={props.onClick}
						>
							<Card.Text>{props.value}</Card.Text>
						</Badge>
					</Col>
				</Row>
			</Card.Body>

			{/* <div className='row g-0'>
          <Card.Header className='col-md-4'>{props.icon}</Card.Header>
        </div>
        <div className='col-md-8'>
          <Card.Body>
            <Card.Title>{props.msg}</Card.Title>
            <span className='badge'>
              <Card.Text>
                {props.value}
              </Card.Text>
            </span>
          </Card.Body>
        </div> */}
		</Card>
	);
}

export interface TrackOption {
	readonly value: string;
	readonly label: string;
}

const options: readonly TrackOption[] = [
	{ value: '', label: 'Total Embarques' },
	{ value: '', label: 'Pendiente Estado' },
	{ value: '', label: 'Mercaderia Lista para Embarque' },
	{ value: '', label: 'Recogida en Origen o recepcion CD' },
	{ value: '', label: 'Arribo Aduana de Origen' },
	{ value: '', label: 'Liberacion Aduana de Origen' },
	{ value: '', label: 'Salida Puerto Embarque (ETD)' },
	{ value: '', label: 'Arribo Puerto Embarque (ETA)' },
	{ value: '', label: 'Ingreso Aduana de destino' },
	{ value: '', label: 'Liberacion Aduana Destino' },
	{ value: '', label: 'Recogida en Destino' },
	{ value: '', label: 'Entrega  Finalizada' },
	{ value: '', label: 'Problemas' },
];

export const MySelect = () => (
	<Select
		defaultValue={{
			label: 'Selecciones los estatus a mostrar',
			value: 'empty',
		}}
		options={options}
	/>
);
