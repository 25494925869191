import IIndexable from 'types/IIndexable';

import a1 from './icons/AEREO/1.jpg';
import a2 from './icons/AEREO/2.jpg';
import a3 from './icons/AEREO/3.jpg';
import a4 from './icons/AEREO/4.jpg';
import a5 from './icons/AEREO/5.jpg';
import a6 from './icons/AEREO/6.jpg';
import a7 from './icons/AEREO/7.jpg';
import a8 from './icons/AEREO/8.jpg';
import a9 from './icons/AEREO/9.jpg';
import a10 from './icons/AEREO/10.jpg';

const root: IIndexable = {
	orden_creada: a1,
	mercaderia_lista_para_embarque: a2,
	recogida_en_origen: a3,
	arribo_aduana_de_origen: a4,
	liberacion_aduana_origen: a5,
	etd: a6,
	eta: a7,
	ingreso_aduana_destino: a8,
	liberacion_aduana_destino: a9,
	entrega_cliente_final: a10,
};
export default root;
