import { ColumnDef } from "@tanstack/react-table";
import Tooltip from "components/Tooltip/Tooltip";
import IIndexable from "types/IIndexable";
import { PopupExecutiveActions } from "components/PopupExecutiveActions/PopupExecutiveActions";
import { FaTimesCircle } from "react-icons/fa";
import { Badge } from "react-bootstrap";
import moment from "moment";
import EnableExecutive from "components/PopupUpdateExecutive/EnableExecutive";

export const ColunmHeader:IIndexable = {
  id: 'ID',
  email: 'Username',
  name: 'Nombre',
  last_login: 'Ultimo acceso',
  enable: 'Activo',
  created_at: 'Fecha de creacion',
  country_name: 'Pais',
  created_by_name: 'Creado por'
}

const UserTableColumDef:ColumnDef<any>[] = [
  { 
    enableSorting:false,
    enableHiding: false,
    header: "",
    id: 'action',
    accessorFn: (val:any) => val,
    cell: (props:any) => <PopupExecutiveActions user={ props.getValue() }/>
  },
  {
    accessorKey: 'name',
    id: 'name',
    header: () => <Tooltip text="Nombre" children={<span>{ ColunmHeader['name'] }</span>}/>
  },
  {
    accessorKey: 'email',
    id: 'email',
    header: () => <Tooltip text="Username" children={<span>{ ColunmHeader['email'] }</span>}/>
  },
  {
    accessorKey: 'last_login',
    id: 'last_login',
    header: () => <Tooltip text="Ultimo acceso" children={<span>{ ColunmHeader['last_login'] }</span>}/>,
    cell: (props:any) => <LastLogin last_login={props.getValue()} />
  },
  {
    accessorKey: 'created_at',
    id: 'created_at',
    header: () => <Tooltip text="Fecha de creacion" children={<span>{ ColunmHeader['created_at'] }</span>}/>,
    cell: (props:any) => moment(props.getValue()).format('DD/MM/YYYY')
  },
  {
    accessorKey: 'country_name',
    id: 'country_name',
    header: () => <Tooltip text="Pais" children={<span>{ ColunmHeader['country_name'] }</span>}/>,
  },
  {
    accessorKey: 'created_by_name',
    id: 'created_by_name',
    header: () => <Tooltip text="Usuario creador" children={<span>{ ColunmHeader['created_by_name'] }</span>}/>,
  },
  {
    accessorKey: 'enable',
    id: 'enable',
    header: () => <Tooltip text="Activo" children={<span>{ ColunmHeader['enable'] }</span>}/>,
    cell: (props:any) => <EnableExecutive status={props.getValue()} executiveID={props.row.original.id} />
  }
]; 

const LastLogin = ({last_login}:{ last_login:Date | null })=>{
  if (last_login === null) {
    return <span><FaTimesCircle color="red" style={{ fontSize:24 }}/></span>;
  }
  const days = moment().diff(moment(last_login), 'days');
  return (
    <span>
      <Badge bg={(days < 7 ? 'success' : 'warning')} style={{ fontSize:12 }}>{`${days} DIAS` }</Badge>
    </span>
  );
}






export default UserTableColumDef;