import { FaEye, FaBars } from 'react-icons/fa';
import 'reactjs-popup/dist/index.css';
import '@szhsin/react-menu/dist/index.css';
import { trackingType } from 'types/trackingType';
import './PopupExecutiveActions.css';
import { Dropdown } from 'react-bootstrap';
import PopupUpdateUserRuts from 'components/PopupUpdateUser/PopupUpdateUserRuts';
import PopupUpdateUserPassword from 'components/PopupUpdateUser/PopupUpdateUserPassword';
import PopupPermissions from 'components/PopupPermissions/PopupPermissions';
import { user } from 'services/getUser';
import PopupAudit from 'components/PopupAudit/PopupAudit';
import { executive } from 'services/getExecutiveUser';
import PopupUpdateExecutive from 'components/PopupUpdateExecutive/PopupUpdateExecutive';
import PopupUpdateExecutivePassword from 'components/PopupUpdateExecutive/PopupUpdateExecutivePassword';

export type PopupExecutiveActionsProps = {
	user: executive;
};

export const PopupExecutiveActions = ({ user }: PopupExecutiveActionsProps) => {
	return (
		<Dropdown className="position-static">
			<Dropdown.Toggle variant="primary">
				<FaBars />
			</Dropdown.Toggle>

			<Dropdown.Menu>
				<Dropdown.Item as="span">
					<PopupAudit userID={user.id} />
				</Dropdown.Item>
				<Dropdown.Item as="span">
					<PopupUpdateExecutive executive={user} />
				</Dropdown.Item>
				<Dropdown.Item as="span">
					<PopupUpdateExecutivePassword email={user.email} />
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
};
