import instance from "helpers/interceptor";

export type userTokenResponse = {
    token: string;
}

const execute = (userID:string) : Promise<userTokenResponse> => {

    return instance.get(`users/${userID}/token`)
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;