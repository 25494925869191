import instance from "helpers/interceptor";

export type clients_by_executive = {
    creados: number, created_by: number, created_by_name: string
}

export type clients_by_country = {
    creados: number, country_id: number, country_name: string
}

export type executiveDashboardRespone = {
    clients_by_executive:Array<clients_by_executive>
    clients_by_country:Array<clients_by_country>
}
export type executiveDashboardRequest = {
    since?:string;
    until?:string;
}
const execute = (params:executiveDashboardRequest) : Promise<executiveDashboardRespone> => {
    return instance.get('/executives/dashboard', { params:params })
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;