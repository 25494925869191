import instance from "helpers/interceptor";
import IIndexable from "types/IIndexable";
import { nationalParamsQuery } from "types/nationalParamsQuery";
import { nationalTrackingResponse } from "types/nationalTrackingResponse";
import TokenService from "./tokenService";

const execute = (params:nationalParamsQuery) : Promise<nationalTrackingResponse> => {

    let paramsWitoutNulls = Object
    .entries(params)
    .reduce((acc, [key, value]) =>{
        if(value !== null && value !== ''){
            (acc as IIndexable)[key] = value; 
        }
        return acc;
    }, {});

    return instance.get('/national-orders', { params:paramsWitoutNulls })
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;