import { FC, useEffect, useState } from "react";
import {FaPlus} from "react-icons/fa";

import "./PopupCreateExecutive.css";
import { Button, Form, Modal, FloatingLabel, Alert, Row, Col, Container, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import validateUser, { UserValidated } from "services/validateUser";
import Select from 'react-select';
import { createUserRequest } from "types/createUserRequest";
import createUser from "services/createUser";
import createExecutive, { createExecutiveRequest } from "services/createExecutive";
import { CountrySelect } from "./components/CountrySelect/CountrySelect";

var Spinner = require('react-spinkit');

export type PopupCreateExecutiveProps = {
}

const PopupCreateExecutive: FC<PopupCreateExecutiveProps> = ({ })=>{
    const queryClient = useQueryClient();
    const [show, setShow] = useState(false);
    const defaultValues:PopupCreateExecutiveProps = {
        email:'',
        password:'',
        country_id: '',
        name:''
    }

    const { getValues, reset, watch, register, trigger, control, handleSubmit, formState: { errors } } = useForm<createExecutiveRequest>({
        defaultValues: defaultValues
    });

    const { 
        error: createError,
        isError: createIsError,
        mutate: insertUser, 
        reset: resetCreation,
        isLoading: creating,
    } = useMutation<void, Error, createExecutiveRequest>((body:createExecutiveRequest) => createExecutive(body), {
        onSuccess: () => {
            toast.success('Creacion de ejecutivo exitosa');
            setShow(false);
        },
        onError: () => {
            toast.error('Ocurrio durante la creacion del usuario');
        },
        onSettled: (data, error, variables, context) => {
            queryClient.invalidateQueries(['executive-data'])
        },
    });

    useEffect(()=>{
        if(!show) {
            reset(defaultValues);
            resetCreation();
        }
    }, [show])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const onSubmit = (data:any) => insertUser(data);

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                <FaPlus /> Crear ejecutivo
            </Button>
    
            <Modal show={show} onHide={handleClose} dialogClassName="modal-xl" centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Creacion de ejecutivo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off" >
                        <Row>
                            <Col sm={4}>
                                <Form.Label>Username</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Username" 
                                    {...register('email', 
                                        { 
                                            required:'Username requerido',
                                            // pattern: {
                                            //     value: /\s/,
                                            //     message: 'No puede poseer espacios en blanco'
                                            // }
                                            // validate: (val:string) => 
                                        }
                                    )}
                                />
                                { errors.email && <Form.Text className="text-danger">{ errors.email.message }</Form.Text> }
                            </Col>
                            <Col sm={4}>
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="Nombre" 
                                    {...register('name', { required:'Nombre requerido' })}
                                />
                                { errors.name && <Form.Text className="text-danger">{ errors.name.message }</Form.Text> }
                            </Col>
                            <Col sm={4}>
                                <Form.Label>Pais</Form.Label>
                                <Controller
                                        control={control}
                                        name="country_id"
                                        rules={{
                                            required: "Pais requerido",
                                        }}
                                        render={
                                            ({ field: { onChange, value, ref, name } }) => (
                                            <CountrySelect 
                                                name={name}
                                                placeholder="Pais"
                                                ref={ref}
                                                value={ value }
                                                onChange={ (val:{ value: string | undefined }) => {
                                                    onChange(val.value) 
                                                }}
                                            />
                                            )
                                        }
                                    />
                                { errors.country_id && <Form.Text className="text-danger">{ errors.country_id.message }</Form.Text> }
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Form.Group>
                                    <Form.Label>Contraseña</Form.Label>
                                    <Form.Control 
                                        className="form-control"
                                        id="password"
                                        type="password" 
                                        {...register('password', { required: 'Contraseña requerida' })} 
                                    />
                                    { errors.password && <Form.Text className="text-danger">{ errors.password.message }</Form.Text> }
                                </Form.Group>
                            </Col>
                        </Row>
                        {(createIsError) &&
                            (<Alert variant='danger'>
                                { createError.message }
                            </Alert>)
                        }
                        <Row className="justify-content-md-end">
                            <Col sm={1}>
                                <Button 
                                    type="submit"
                                    variant="primary" 
                                >
                                    Crear
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>);
}

export default PopupCreateExecutive;