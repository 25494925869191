import instance from "helpers/interceptor";
import IIndexable from "types/IIndexable";
import { Params } from "types/Params";
import { trackingResponse } from "types/trackingResponse";
import TokenService from "./tokenService";

const execute = (params:Params) : Promise<trackingResponse> => {
    let paramsWitoutNulls = Object
    .entries(params)
    .reduce((acc, [key, value]) =>{
        if(value !== null && value !== ''){
            (acc as IIndexable)[key] = value; 
        }
        return acc;
    }, {});

    return instance.get('/international-orders', { params:paramsWitoutNulls })
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;