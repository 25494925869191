import { FC, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { useQuery } from '@tanstack/react-query';
// import './PopupLastMile.css';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import validateUser from 'services/validateUser';

var Spinner = require('react-spinkit');

export type PopupCompaniesOfUserProps = {
	email: string;
	buttonText?: string;
};

const PopupCompaniesOfUser: FC<PopupCompaniesOfUserProps> = ({
	email,
	buttonText,
}) => {
	const [show, setShow] = useState(false);
	const { status, data, error, isFetching, isLoading } = useQuery(
		['companies-user', email],
		() => validateUser({ email: email }),
		{ refetchOnWindowFocus: false, enabled: show }
	);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<Button
				variant="primary"
				onClick={handleShow}
				style={{ width: '100%' }}
			>
				<FaSearch /> {buttonText}
			</Button>

			<Modal
				show={show}
				onHide={handleClose}
				centered={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>Lista de empresas</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{isLoading ? (
						<span className="d-flex justify-content-center">
							<Spinner name="wandering-cubes" />
						</span>
					) : (
						<div
							className="content"
							id="content"
						>
							<ul>
								{(data ?? []).map((x, i) => (
									<li key={i}>
										{x.rut_cliente} - {x.nombre_fantasia}
									</li>
								))}
							</ul>
						</div>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default PopupCompaniesOfUser;
