import instance from 'helpers/interceptor';

export type question = {
	id: number;
	question: string;
	transport_way: 'international' | 'national';
	is_active: boolean;
};

const execute = (): Promise<question[]> => {
	return instance
		.get('/questions')
		.then((response) => response.data)
		.catch((response) => {
			throw new Error(response.response.data.error);
		});
};

export default execute;
