import instance from 'helpers/interceptor';

export type notificationSettingResponse = {
	// id: number;
	// executor: number;
	// executor_name: string;
	// target: number;
	// target_name: string;
	// message: string;
	// timestamp: Date;
};

export type notificationSettingRequest = {
	order_type: 'international' | 'national';
	order: 'general' | string;
	order_way: 'a' | 'm' | 't' | null;
};

const execute = (params: notificationSettingRequest): Promise<any> => {
	return instance
		.get(`notifications/config`, { params })
		.then((response) => response.data)
		.catch((response) => {
			throw new Error(response.response.data.error);
		});
};

export default execute;
