import './EmbeddedPage.css'
import { Container, Row, Col } from 'react-bootstrap';

const EmbeddedPage = () => {
	return (
		<Container>
			<Row>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'center'}}
				>
					<img
						src={require('../../assets/img/embebido.png')}
						className="w-75"
						alt="embebido"
					/>
					<p className='my-3 text-3xl'>INTEGRACIÓN DE EMBEBIDOS</p>
				</Col>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'justify'}}
				>
					<p className='text-xl'>Esta herramienta en nuestra plataforma te permitirá integrar en tu propio sitio web o e-commerce un módulo de tracking, con el que tus clientes podrán realizar el seguimiento completo de sus envíos directamente desde tu propio sitio. Sea cual sea la tecnología de tu sitio web, podrás integrar a través de embebidos nuestra plataforma, para tener toda la información en un solo lugar, mucho más cómodo y rápido. Nueva funcionalidad en plataforma: integra módulo de seguimiento de envíos en tu sitio web/e-commerce.</p>
				</Col>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'center'}}
				>
					<p className='text-lg font-bold'>BENEFICIOS PARA NUESTROS CLIENTES</p>
				</Col>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'justify'}}
				>
					<ul className='list-disc ml-5 text-lg lg:text-xl flex flex-col gap-2'><li>Configurable en cualquier tipo de tecnología de sitio: Diseño propio, Tienda Nube, Wordpress, etc.</li><li>Entrega información más actualizada y completa a tus clientes sobre sus envíos.</li><li>Potencia la experiencia de tus entregas con un sistema de tracking y logística, de forma sencilla y utilizada por grandes empresas.</li></ul>
				</Col>
			</Row>
		</Container>
	);
};

export default EmbeddedPage;
