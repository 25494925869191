import { Row } from "react-bootstrap"
import { FaExclamationTriangle } from "react-icons/fa"

export const NoData = ()=>{
    return (<>
        <Row className="justify-content-center align-items-center">
            <FaExclamationTriangle fontSize='3rem'/>
            <p className="h4">Sin data que mostrar</p>
        </Row>
    </>)
}