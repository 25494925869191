import React, { useMemo, useState } from 'react';
import './Header.css';
import { FC } from 'react';
import { useRecoilValue } from 'recoil';
import headerCartAtom from 'atoms/headerCartAtom';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Select from 'react-select';
import IIndexable from 'types/IIndexable';
import { ProductSelect } from 'components/ProductSelect/ProductSelect';
import { NationalServiceSelect } from 'components/NationalServiceSelect/NationalServiceSelect';
import { NationalShipmentStatusSelect } from 'components/NationalShipmentStatusSelect/NationalShipmentStatusSelect';
import { NationalTipoEmbalajeSelect } from 'components/NationalTipoEmbalajeSelect/NationalTipoEmbalajeSelect';
import { NationalTypeLoadSelect } from 'components/NationalTypeLoadSelect/NationalTypeLoadSelect';
import { DatePicker } from 'components/DatePicker/DatePicker';
import { DateRangePickerProps } from 'types/DateRangePickerProps';

export type HeaderProps = {
	otChange: (event: string) => void;
	productsChange: (event: string) => void;
	serviceChange: (event: string) => void;
	typeLoadChange: (event: string) => void;
	shipmentStatusLoadChange: (event: string) => void;
	tipoEmbalaChange: (event: string) => void;

	refDestinatarioChange: (event: string) => void;

	dateEntregaDateRange: DateRangePickerProps;
	dateRecogidaDateRange: DateRangePickerProps;
	dateEntregateRange: DateRangePickerProps;

	masterSelectChange: (e: string) => void;

	defaultValues?: Array<string>;
};

export const Header: FC<HeaderProps> = ({
	otChange,
	productsChange,
	serviceChange,
	typeLoadChange,
	shipmentStatusLoadChange,
	tipoEmbalaChange,

	refDestinatarioChange,

	dateEntregaDateRange,
	dateRecogidaDateRange,
	dateEntregateRange,

	masterSelectChange,

	defaultValues,
}) => {
	const [drops, setDrops] = useState(defaultValues ?? []);

	const options = [
		{
			label: 'Ot delpa',
			value: 'ot',
		},
		{
			label: 'Ref. destinatario',
			value: 'referencia_destinatario',
		},
		{
			label: 'Productos',
			value: 'products',
		},
		{
			label: 'Servicios',
			value: 'servicios',
		},
		{
			label: 'Tipo de carga',
			value: 'tipoCarga',
		},
		{
			label: 'Tipo de embarque',
			value: 'estadoEmbarque',
		},
		{
			label: 'Tipo de embalaje',
			value: 'tipoEmbalaje',
		},
		{
			label: 'Fecha de emision',
			value: 'dateEmision',
		},
		{
			label: 'Fecha de recogida',
			value: 'dateRecogida',
		},
		{
			label: 'Fecha de entrega',
			value: 'dateEntrega',
		},
	];

	const filters: IIndexable = useMemo(
		() => ({
			ot: (
				<span key="ot">
					<Form.Control
						type="text"
						placeholder="Ot delpa"
						onChange={(e) => {
							setTimeout(() => {
								if (e.target.value.length > 3) {
									otChange(e.target.value);
								}

								if (e.target.value.length === 0) {
									otChange('');
								}
							}, 1500);
						}}
					/>
				</span>
			),
			referencia_destinatario: (
				<span key="referencia_destinatario">
					<Form.Control
						type="text"
						placeholder="Ref. destinatario"
						onChange={(e) => {
							setTimeout(() => {
								if (e.target.value.length > 3) {
									refDestinatarioChange(e.target.value);
								}

								if (e.target.value.length === 0) {
									refDestinatarioChange('');
								}
							}, 1500);
						}}
					/>
				</span>
			),
			products: (
				<ProductSelect
					key="products"
					placeholder={'Productos'}
					onChange={(e) => {
						if (e.value == undefined) return;

						productsChange(e.value);
					}}
				/>
			),
			servicios: (
				<NationalServiceSelect
					key="servicios"
					placeholder={'Servicios'}
					onChange={(e) => {
						if (e.value == undefined) return;

						serviceChange(e.value);
					}}
				/>
			),
			tipoCarga: (
				<NationalTypeLoadSelect
					key="tipoCarga"
					placeholder={'Tipo de carga'}
					onChange={(e: any) => {
						if (e.value == undefined) return;

						typeLoadChange(e.value);
					}}
				/>
			),
			estadoEmbarque: (
				<NationalShipmentStatusSelect
					key="estadoEmbarque"
					placeholder={'Estado de embarque'}
					onChange={(e) => {
						if (e.value == undefined) return;

						shipmentStatusLoadChange(e.value);
					}}
				/>
			),
			tipoEmbalaje: (
				<NationalTipoEmbalajeSelect
					key="tipoEmbalaje"
					placeholder={'Tipo de envalaje'}
					onChange={(e) => {
						if (e.value == undefined) return;

						tipoEmbalaChange(e.value);
					}}
				/>
			),
			dateEmision: (
				<span key="dateEmision">
					<DatePicker
						startDate={dateEntregaDateRange.startDate}
						endDate={dateEntregaDateRange.endDate}
						placeholder="Fecha emision inicio - Fecha emision final"
						onChange={dateEntregaDateRange.onChange}
					/>
				</span>
			),
			dateRecogida: (
				<span key="dateRecogida">
					<DatePicker
						placeholder="Fecha recogida inicio - Fecha recogida final"
						onChange={dateRecogidaDateRange.onChange}
						initialEmpty={true}
					/>
				</span>
			),
			dateEntrega: (
				<span key="dateEntrega">
					<DatePicker
						placeholder="Fecha entrega inicio - Fecha entrega final"
						onChange={dateEntregateRange.onChange}
						initialEmpty={true}
					/>
				</span>
			),
		}),
		[]
	);

	return (
		<header className="App-header">
			<Container fluid>
				<Select
					classNamePrefix="react-select"
					placeholder="Filtro maestro"
					defaultValue={(defaultValues ?? []).map(
						(x) =>
							options.find((y) => y.value == x) ?? {
								label: 'undefined',
								value: '',
							}
					)}
					options={options}
					isMulti
					onChange={(newValue) => {
						let list = newValue.map(({ value }) => value);
						setDrops(list);
						masterSelectChange(list.join(','));
					}}
				/>
			</Container>
			<Container
				fluid
				className="mt-3"
			>
				<Row className="row-container">
					{drops.map((key) => filters[key])}
				</Row>
			</Container>
		</header>
	);
};
