import { FC, useEffect, useState } from "react";
import Popup from "reactjs-popup";
import {FaCloudDownloadAlt, FaClone, FaTools} from "react-icons/fa";

import "./PopupInternationalCloneOrder.css";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import getInternationalClone from "services/getInternationalClone";
import { Button, Form, Modal, FloatingLabel, Alert, Row, Col } from "react-bootstrap";
import insertCloneInternational, { insertCloneOrderBody } from "services/insertCloneInternational";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

var Spinner = require('react-spinkit');

export type PopupInternationalCloneOrderProps = {
    orden:number
}

const ValidationErrorAlert = ({ message }:{ message:string })=> {
    // return (<Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
    return (<Alert variant="danger" dismissible>
        <Alert.Heading>Error en el formulario</Alert.Heading>
        <p>
            { message }
        </p>
    </Alert>);
}

const PopupInternationalCloneOrder: FC<PopupInternationalCloneOrderProps> = ({ orden })=>{
    const queryClient = useQueryClient();
    const [show, setShow] = useState(false);
    const { status, data, error, isFetching, isPreviousData, isLoading } = useQuery(
        [ 'internaltional-clone', orden ],
        () => getInternationalClone(orden.toString()) ,
        { refetchOnWindowFocus:false, enabled:show },
    )

    const { register, handleSubmit, watch, formState: { errors } } = useForm<insertCloneOrderBody>({});

    const { mutate, isLoading:isLoadingInsert } = useMutation((body:insertCloneOrderBody) => insertCloneInternational(body, orden.toString()), {
        onSuccess: data => {
            toast.success(`Clonacion exitosa, order nro. ${data}`);
            setShow(false);
        },
        onError: () => {
            toast.error('Ocurrio un error');
        },
        onSettled: () => {
            // queryClient.invalidateQueries(['internaltional-clone', { orden }])
            queryClient.invalidateQueries(['tracking-table-data'])
        }
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const onSubmit = (data:any) => mutate(data);

    return (
    <>
        <Button variant="primary" onClick={handleShow} style={{width:"100%"}}>
            <FaClone/> Clonacion de orden
        </Button>

        <Modal show={show} onHide={handleClose} dialogClassName="modal-xl" centered={true}>
            <Modal.Header closeButton>
                <Modal.Title>Clonacion de orden</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { isLoading || isLoadingInsert ?
                    <span className='d-flex justify-content-center'><Spinner name='wandering-cubes' /></span> :
                    <div className='content'>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Orden de flete">
                                            <Form.Control type="text" placeholder="Orden de flete" disabled readOnly value={orden}/>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Via transporte">
                                            {/* <Form.Control type="text" placeholder="Via transporte" disabled value={data?.via_transporte}/> */}
                                            <Form.Control type="text" placeholder="Via transporte" disabled defaultValue={data?.via_transporte}/>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Pais Origen">
                                            <Form.Control type="text" placeholder="Pais Origen" disabled value={data?.pais_origen}/>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Pais Destino">
                                            <Form.Control type="text" placeholder="Pais Destino" disabled value={data?.pais_destino}/>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Shipper">
                                            <Form.Control type="text" placeholder="Shipper" value={data?.exportador}/>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Consignatario">
                                            <Form.Control type="text" placeholder="Consignatario" value={data?.importador}/>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Referencia Shipper">
                                            <Form.Control type="text" placeholder="Referencia Shipper" value={data?.referencia_exportador}/>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Referencia Consignatario">
                                            <Form.Control type="text" placeholder="Referencia Consignatario" 
                                                {...register("ref_consignatario", { required: "Referencia Consignatario requerida" })} 
                                                defaultValue={data?.referencia_exportador}
                                            />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Peso">
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Peso" 
                                                {...register("peso", { required: "Peso requerido" })} 
                                                defaultValue={data?.peso_bruto}
                                            />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Volumen">
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Volumen" 
                                                {...register("volumen", { required: "Volumen requerido" })} 
                                                defaultValue={data?.volumen}
                                            />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Numero de bultos">
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Numero de bultos" 
                                                {...register("bultos", { required: "Numero de bultos requerido" })} 
                                                defaultValue={data?.numero_bultos}
                                            />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Fecha de prevision de entrega">
                                            <Form.Control 
                                                type="date" 
                                                placeholder="Fecha de prevision de entrega" 
                                                {...register("prevision_entrega", { required: "Fecha de prevision de entrega requerida" })}
                                            />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Factura de exportacion">
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Factura de exportacion" 
                                                {...register("factura_ex", { required: "Factura de exportacion requerida" })} 
                                                defaultValue={data?.factura_exportacion}
                                            />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Detalles del valor de flete">
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Detalles del valor de flete" 
                                                {...register("detalle_flete", { required: "Detalles del valor de flete requerido" })} 
                                                defaultValue={data?.valor_flete}
                                            />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                { Object.values(errors).filter((pop:any) => pop != undefined).map((pop:any) => <ValidationErrorAlert message={ pop.message } />) }
                                {/* { errors?.ref_consignatario?.message && <ValidationErrorAlert message={ errors?.ref_consignatario?.message } /> } */}
                            </Row>
                            <Row className="justify-content-md-end">
                                <Col sm={1}>
                                    <Button 
                                        type="submit"
                                        variant="primary" 
                                    >
                                    Clonar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                }
            </Modal.Body>
        </Modal>
    </>);
}

export default PopupInternationalCloneOrder;