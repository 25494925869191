import './WebhookPage.css'
import { Container, Row, Col } from 'react-bootstrap';

const WEbhookPage = () => {
	return (
		<Container>
			<Row>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'center'}}
				>
					<img
						src={require('../../assets/img/webhooks.png')}
						className="w-75"
						alt="apis"
					/>
					<p className='my-3 text-3xl'>WEBHOOKS</p>
				</Col>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'justify'}}
				>
					<p className='text-xl'>Representa la manera más sencilla de transmitir y recibir un aviso importante en tiempo real. Esta herramienta es fundamental para interconectar los sistemas informáticos y permite generar una acción en la plataforma, por ejemplo, cuando se inicia el tránsito de un envío. Gracias a los WebHook se pueden ir gatillando notificaciones a través de correo, Whatsapp o SMS que permiten que tus clientes reciban alertas cada vez que sucede un hito en la cadena logística.</p>
				</Col>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'center'}}
				>
					<p className='text-lg font-bold'>BENEFICIOS PARA NUESTROS CLIENTES</p>
				</Col>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'justify'}}
				>
					<ul className="list-disc ml-5 text-lg lg:text-xl flex flex-col gap-2"><li>Permiten que puedas recibir información inmediata, tener un mejor control de tu envío y poder detectar alguna falla o error en el proceso.</li><li>La automatización es un ahorro enorme de tiempo. Además, puedes mantener actualizado tu CRM en tiempo real para que no pierdas ninguna oportunidad.</li><li>Puedes sincronizar automáticamente todos los datos que necesites con todas las aplicaciones web que utilices.</li></ul>
				</Col>
			</Row>
		</Container>
	);
};

export default WEbhookPage;
