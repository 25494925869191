import instance from "helpers/interceptor";
import { fileResponse } from "types/fileResponse";

const execute = (orderID : string) : Promise<fileResponse[]> => {
    return instance.get(`/international-orders/${orderID}/fileList`)
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;