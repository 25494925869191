import './NotificationSettings.css';
import { Container, Row, Col } from 'react-bootstrap';

const EmailInfoPage = () => {
	return (
		<Container>
			<Row>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{ textAlign: 'center' }}
				>
					<img
						src="https://via.placeholder.com/877x381?text=Email+Img"
						className="w-75"
						alt="apis"
					/>
					<p className="my-3 text-3xl">Notificaciones vía e-mail</p>
				</Col>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{ textAlign: 'justify' }}
				>
					<p className="text-xl">
						Los clientes reciben correos electrónicos
						automáticamente al momento de cambiar las fechas de
						cambio de estatus estimados o al confirmarse un cambio
						de estatus, estas notificaciones podrán ser
						parametrizadas desde el portal web de tracking de Delpa
						mediante un panel amigable.
					</p>
				</Col>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{ textAlign: 'center' }}
				>
					<p className="text-lg font-bold">
						BENEFICIOS PARA NUESTROS CLIENTES
					</p>
				</Col>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{ textAlign: 'justify' }}
				>
					<ul className="list-disc ml-5 text-lg lg:text-xl flex flex-col gap-2">
						<li>Creación de usuario.</li>
						<li>Cambio de contraseña.</li>
						<li>Vencimiento de contraseña.</li>
						<li>Rastreo de embarque.</li>
						<li>
							Reportes mensuales de actividades (creación y cierre
							de orden de flete, entre otros).
						</li>
					</ul>
				</Col>
			</Row>
		</Container>
	);
};

export default EmailInfoPage;
