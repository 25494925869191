import { FC, useEffect, useState } from "react";
import {FaPlus} from "react-icons/fa";

import "./PopupCreateUserByAdmin.css";
import { Button, Form, Modal, FloatingLabel, Alert, Row, Col, Container, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import validateUser, { UserValidated } from "services/validateUser";
import Select from 'react-select';
import { createUserRequest } from "types/createUserRequest";
import createUser from "services/createUser";
import { ExecutiveSelect } from "./components/ExecutiveSelect/ExecutiveSelect";

var Spinner = require('react-spinkit');

export type PopupCreateUserByAdminProps = {

}

const PopupCreateUserByAdmin: FC<PopupCreateUserByAdminProps> = ({ })=>{
    const queryClient = useQueryClient();
    const [show, setShow] = useState(false);
    const defaultValues:PopupCreateUserByAdminProps = {
        email:'',
        password:'',
        role:'client',
        ruts:[],
        created_by:''
    }

    const { getValues, reset, watch, register, trigger, control, handleSubmit, formState: { errors } } = useForm<createUserRequest>({
        defaultValues: defaultValues
    });

    const { 
        data,
        error,
        isError,
        mutate, 
        reset: resetValidation,
        isLoading:isLoadingValidation 
    } = useMutation<UserValidated[], Error, createUserRequest>({
        mutationFn:(body:createUserRequest) => validateUser({ email:body.email }),
    });

    const { 
        error: createError,
        isError: createIsError,
        mutate: insertUser, 
        reset: resetCreation,
        isLoading: creating,
    } = useMutation<void, Error, createUserRequest>((body:createUserRequest) => createUser(body), {
        onSuccess: () => {
            toast.success('Creacion de usuario exitosa');
            setShow(false);
        },
        onError: () => {
            toast.error('Ocurrio durante la creacion del usuario');
        },
        onSettled: (data, error, variables, context) => {
            queryClient.invalidateQueries(['user-data'])
        },
    });

    useEffect(()=>{
        if(!show) {
            reset(defaultValues);
            resetValidation();
            resetCreation();
        }
    }, [show])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const onSubmit = (data:any) => insertUser(data);
    const onSubmitValidate = async (data:any) => {
        let isValid = await trigger('email');
        if(isValid) mutate(data);
    };

    const UserOpions = (data ?? []).map(x => ({ label: `${x.nombre_fantasia} - ${x.rut_usuario}`, value: x.rut_cliente }));

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                <FaPlus /> Crear usuario
            </Button>
    
            <Modal show={show} onHide={handleClose} dialogClassName="modal-xl" centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Creacion de usuario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
                        <Row>
                            <Col sm={6}>
                                <Form.Label>Correo Electronico</Form.Label>
                                <InputGroup className="mb-3">
                                    <Button 
                                        type="button"
                                        variant="primary" 
                                        disabled={ isLoadingValidation }
                                        onClick={ ()=> onSubmitValidate({ email:getValues('email') }) }
                                    >
                                       { isLoadingValidation ? 'Verificando...' : 'Verificar correo' }
                                    </Button>
                                    <Form.Control type="text" placeholder="Correo Electronico" {...register('email', { required:'Correo electronico requerido' })}/>
                                </InputGroup>
                                { errors.email && <Form.Text className="text-danger">{ errors.email.message }</Form.Text> }
                            </Col>
                            <Col sm={6}>
                                <Form.Label>Ejecutivo</Form.Label>
                                <Controller
                                    control={control}
                                    name="created_by"
                                    rules={{
                                        required: "Ejecutivo requerido",
                                    }}
                                    render={
                                        ({ field: { onChange, value, ref, name } }) => (
                                        <ExecutiveSelect 
                                            name={name}
                                            placeholder="Ejecutivos"
                                            ref={ref}
                                            value={ value }
                                            onChange={ (val:{ value: string | undefined }) => {
                                                onChange(val.value) 
                                            }}
                                        />
                                        )
                                    }
                                />
                                { errors.created_by && <Form.Text className="text-danger">{ errors.created_by.message }</Form.Text> }
                            </Col>
                        </Row>
                        {data !== undefined && data.length > 0 && (<Row>
                            <Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Usuarios</Form.Label>
                                    <Controller
                                        control={control}
                                        name="ruts"
                                        rules={{
                                            required: "Usuario requerido",
                                        }}
                                        render={
                                            ({ field: { onChange, value, ref, name } }) => (<Select
                                                placeholder='Usuarios'
                                                isMulti
                                                name={name}
                                                options={ UserOpions }
                                                ref={ref}
                                                value={ UserOpions.filter(c => value.includes(c.value)) }
                                                onChange={ val => onChange(val?.map(c => c.value)) }
                                            />)
                                        }
                                        />
                                    { errors.ruts && <Form.Text className="text-danger">{ errors.ruts.message }</Form.Text> }
                                </Form.Group>
                            </Col>
                            { watch('ruts').length > 0 && (<Col sm={6}>
                                <Form.Group>
                                    <Form.Label>Contraseña</Form.Label>
                                    <Form.Control 
                                        className="form-control"
                                        id="password"
                                        type="password" 
                                        {...register('password', { required: 'Contraseña requerida' })} 
                                    />
                                    { errors.password && <Form.Text className="text-danger">{ errors.password.message }</Form.Text> }
                                </Form.Group>
                            </Col>)}
                        </Row>)}
                        {(isError) &&
                            (<Alert variant='danger'>
                                { error.message }
                            </Alert>)
                        }
                        {(createIsError) &&
                            (<Alert variant='danger'>
                                { createError.message }
                            </Alert>)
                        }

                        { watch('ruts').length > 0 && (<Row className="justify-content-md-end">
                            <Col sm={1}>
                                <Button 
                                    type="submit"
                                    variant="primary" 
                                >
                                    Crear
                                </Button>
                            </Col>
                        </Row>)}
                    </Form>
                </Modal.Body>
            </Modal>
        </>);
}

export default PopupCreateUserByAdmin;