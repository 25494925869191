import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import './Login.css';
import { useForm } from 'react-hook-form';

const SignIn = () => {
	const navigate = useNavigate();
	const { login, isLogged, isLoginLoading, hasLoginError, error } = useAuth();
	const [showAlert, setShowAlert] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<{ email: string; password: string }>({
		defaultValues: {
			email: '',
			password: '',
		},
	});

	const onSubmit = async (data: any) => {
		setShowAlert(true);
		login(data.email, data.password).catch();
	};

	useEffect(() => {
		if (isLogged) navigate('/');
	}, [isLogged, navigate]);

	return (
		<div>
			<div className="page-login">
				<img src={require('../../assets/img/backgrounds/rigth.png')} className='position-absolute logo-delpa-login' />
				<Row></Row>
				<div className="page-single">
					<div className="container">
						<Row>
							<Col
								xl={5}
								lg={6}
								md={8}
								sm={8}
								xs={10}
								className="card-sigin-main mx-auto my-auto py-4 justify-content-center"
							>
								<div className="card-sigin">
									{/* <!-- Demo content--> */}
									<div className="main-card-signin d-md-flex">
										<div className="wd-100p">
											<div className="d-flex mb-4">
												<Link
													to={`${process.env.PUBLIC_URL}/login`}
												>
													<img
														src={require('../../assets/img/logo.png')}
														className="sign-favicon ht-40"
														alt="logo"
													/>
												</Link>
											</div>
											<div className="">
												<div className="main-signup-header">
													<h2>Bienvenidos</h2>
													<h6 className="font-weight-semibold">
														Ingresa para continuar.
													</h6>
													<div className="panel panel-primary">
														<div className="tab-menu-heading mb-2 border-bottom-0">
															<div className="tabs-menu1">
																{showAlert ===
																	true &&
																	hasLoginError && (
																		<Alert
																			variant="danger"
																			onClose={() =>
																				setShowAlert(
																					false
																				)
																			}
																			dismissible
																		>
																			<Alert.Heading>
																				Tenemos
																				un
																				problema!
																			</Alert.Heading>
																			<p>
																				{
																					error
																				}
																			</p>
																		</Alert>
																	)}
																<div
																	className="panel-body tabs-menu-body border-0 p-3"
																	id="tab5"
																>
																	<Form
																		onSubmit={handleSubmit(
																			onSubmit
																		)}
																	>
																		<Form.Group className="form-group">
																			<Form.Label>
																				Correo
																				Electronico
																			</Form.Label>{' '}
																			<Form.Control
																				className="form-control"
																				placeholder="Ingrese su Usuario/Correo electronico"
																				type="text"
																				{...register(
																					'email',
																					{
																						required:
																							'Usuario/Correo electronico requerido',
																					}
																				)}
																			/>
																			{errors.email && (
																				<Form.Text className="text-danger">
																					{
																						errors
																							.email
																							.message
																					}
																				</Form.Text>
																			)}
																		</Form.Group>
																		<Form.Group className="form-group">
																			<Form.Label>
																				Contraseña
																			</Form.Label>{' '}
																			<Form.Control
																				className="form-control"
																				placeholder="Ingrese su contraseña"
																				type="password"
																				{...register(
																					'password',
																					{
																						required:
																							'Contraseña requerida',
																					}
																				)}
																			/>
																			{errors.password && (
																				<Form.Text className="text-danger">
																					{
																						errors
																							.password
																							.message
																					}
																				</Form.Text>
																			)}
																		</Form.Group>
																		<Button
																			type="submit"
																			variant=""
																			className="btn btn-primaryLogin btn-block"
																			disabled={
																				isLoginLoading
																			}
																		>
																			{isLoginLoading
																				? 'Cargando…'
																				: 'Entrar'}
																		</Button>
																		<div className="text-center">
																			<Link
																				className="text-danger text-decoration-none"
																				to={`/forgot-password`}
																			>
																				¿Olvidó
																				su
																				contraseña?
																			</Link>
																		</div>
																	</Form>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</div>
	);
};

SignIn.propTypes = {};

SignIn.defaultProps = {};

export default SignIn;
