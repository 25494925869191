import { Table } from '@tanstack/react-table';
import { FC, useState } from 'react';
import Popup from 'reactjs-popup';
import { FaEye } from 'react-icons/fa';
import { EyeCheck } from 'components/EyeCheckBox/EyeCheck';
import { Button, Modal, Container, Row, Col } from 'react-bootstrap';
import './PopupVisibilityColums.scss';
import { ColunmHeader as internationalHeader } from 'pages/home/components/TrackingTableColumDef';
import { ColunmHeader as nationalHeader } from 'pages/national/components/NationalTableColumDef';
import { useLocation } from 'react-router-dom';

// import "./PopupVisibilityColums.css";

export type PopupVisibilityColumsProps = {
	table: Table<any>;
};

const modalRoot = document.getElementById('modal-root');

const PopupVisibilityColums: FC<PopupVisibilityColumsProps> = ({ table }) => {
	const location = useLocation();
	const ColunmHeader =
		location.pathname === '/international/'
			? internationalHeader
			: nationalHeader;
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<Button
				variant="primary"
				onClick={handleShow}
				className="icon-gear-column ms-2"
			>
				<FaEye />
				Configurar campos
			</Button>

			<Modal
				show={show}
				onHide={handleClose}
				centered={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>Personalizacion de los campos</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<Row>
							<Col md={12}>
								<div className="eyes-content">
									<input
										className="switch"
										id="allSwitch"
										{...{
											type: 'checkbox',
											checked:
												table.getIsAllColumnsVisible(),
											onChange: (e: any) => {
												table.getToggleAllColumnsVisibilityHandler()(
													e
												);
												setTimeout(() => {
													localStorage.setItem(
														location.pathname +
															'col-visibility',
														JSON.stringify(
															table.getState()
																.columnVisibility
														)
													);
												}, 50);
											},
										}}
									/>{' '}
									<label
										htmlFor="allSwitch"
										className="switchLabel"
									>
										Toggle
									</label>
									<span>Activar Todos</span>
								</div>
							</Col>
							{table
								.getAllLeafColumns()
								.filter((colum) => colum.id !== 'action')
								.map((column) => {
									return (
										<Col
											md={12}
											key={column.id}
										>
											<div className="eyes-content">
												<input
													id={column.id}
													className="switch"
													{...{
														type: 'checkbox',
														checked:
															column.getIsVisible(),
														// onChange: column.getToggleVisibilityHandler(),
														onChange: (e: any) => {
															column.getToggleVisibilityHandler()(
																e
															);
															setTimeout(() => {
																localStorage.setItem(
																	location.pathname +
																		'col-visibility',
																	JSON.stringify(
																		table.getState()
																			.columnVisibility
																	)
																);
															}, 50);
														},
													}}
												/>{' '}
												<label
													htmlFor={column.id}
													className="switchLabel"
												>
													Toggle
												</label>
												<span>
													{ColunmHeader[column.id]}
												</span>
												<EyeCheck
													status={column.getIsVisible()}
												/>{' '}
											</div>
										</Col>
									);
								})}
						</Row>
					</Container>
				</Modal.Body>
			</Modal>
		</>
	);
};

// const PopupVisibilityColums: FC<PopupVisibilityColumsProps> = ({ table })=>{
//     return(<div>
//         <Popup
//             trigger={<span className='icon-gear-column'><FaCog/></span>}
//             // trigger={<button className='icon-gear-column'><FaCog/></button>}
//             modal
//         >
//             <div className='modal'>
//                 <div className="text">
//                     <h3>Personalizacion de los campos</h3>
//                     <h5>Aqui podra seleccionar los campos que desee ver en los resultados</h5>
//                 </div>
//                 <div className='content'>
//                     <div className='eyes-content'>
//                         <input className='switch'
//                             {...{
//                             type: 'checkbox',
//                             checked: table.getIsAllColumnsVisible(),
//                             onChange: table.getToggleAllColumnsVisibilityHandler(),
//                             }}
//                         />{' '}<label className='toggle'></label>
//                         Activar Todos
//                     </div>
//                     {table.getAllLeafColumns().filter(colum => colum.id !== 'action').map(column => {
//                     return (
//                         <div className='eyes-content' key={column.id}>
//                         <input
//                             {...{
//                             type: 'checkbox',
//                             checked: column.getIsVisible(),
//                             // onChange: column.getToggleVisibilityHandler(),
//                             onChange: (e:any)=> {
//                                 column.getToggleVisibilityHandler()(e);
//                                 setTimeout(()=> {
//                                     localStorage.setItem('col-visibility', JSON.stringify(table.getState().columnVisibility))
//                                 }, 50)
//                             }
//                             }}
//                         />
//                         {' '}
//                         {column.id}
//                         <EyeCheck status={column.getIsVisible()}/>
//                         {' '}
//                         </div>
//                     )
//                     })}
//                 </div>
//             </div>
//         </Popup>
//     </div>);
// }

export default PopupVisibilityColums;
