import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { resetData } from 'data/Switcherdata/Switcherdata';
import { useAuth } from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import {
	Alert,
	Button,
	Col,
	Form,
	InputGroup,
	Modal,
	Row,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { FaPen } from 'react-icons/fa';
import Select from 'react-select';
import { user } from 'services/getUser';
import updateUser from 'services/updateUser';
import validateUser from 'services/validateUser';
import { updateUserRequest } from 'types/createUserRequest';

var Spinner = require('react-spinkit');

export type popupUpdateUserRutsProps = {
	user: user;
};

const PopupUpdateUserRuts = ({ user }: popupUpdateUserRutsProps) => {
	const queryClient = useQueryClient();
	const [show, setShow] = useState(false);
	const defaultValues: updateUserRequest = {
		email: user.email,
		ruts: [],
		name: user.name,
	};

	const {
		reset: resetForm,
		setValue,
		register,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<updateUserRequest>({
		defaultValues: defaultValues,
	});

	const {
		status,
		data: clientsData,
		error,
		isFetching,
		isPreviousData,
		isLoading,
	} = useQuery(['getClients'], () => validateUser({ email: user.email }), {
		refetchOnWindowFocus: false,
		enabled: show,
		onSuccess: (r) =>
			setValue(
				'ruts',
				(r ?? [])
					.filter((x) => x.selected)
					.map((x) => `${x.rut_cliente}`)
			),
	});
	const UserOpions = (clientsData ?? []).map((x) => ({
		label: `${x.nombre_fantasia} - ${x.rut_cliente}`,
		value: `${x.rut_cliente}`,
	}));

	const {
		reset: mutationReset,
		error: updateError,
		isError: updateIsError,
		mutate,
		isLoading: creating,
	} = useMutation<void, Error, updateUserRequest>(
		(body: updateUserRequest) => updateUser(body),
		{
			onSuccess: () => {
				toast.success('Actualizacion de usuario exitosa');
				setShow(false);
			},
			onError: () => {
				toast.error('Ocurrio durante la Actualizacion del usuario');
			},
			onSettled: (data, error, variables, context) => {
				queryClient.invalidateQueries(['user-data']);
			},
		}
	);

	useEffect(() => {
		if (!show) {
			resetForm(defaultValues);
			mutationReset();
		}
	}, [show]);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const onSubmit = (submitData: updateUserRequest) => mutate(submitData);

	return (
		<>
			<Button
				variant="primary"
				onClick={handleShow}
				style={{ width: '100%' }}
			>
				<FaPen /> Edicion de clientes
			</Button>
			<Modal
				show={show}
				onHide={handleClose}
				dialogClassName="modal-xl"
				centered={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>Edicion de clientes</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{isLoading ? (
						<span className="d-flex justify-content-center">
							<Spinner name="wandering-cubes" />
						</span>
					) : (
						<Form onSubmit={handleSubmit(onSubmit)}>
							<Row>
								<Col sm={6}>
									<Form.Group>
										<Form.Label>Clientes</Form.Label>
										<Controller
											control={control}
											name="ruts"
											rules={{
												required: 'Cliente requerido',
											}}
											render={({
												field: {
													onChange,
													value,
													ref,
													name,
												},
											}) => {
												return (
													<Select
														placeholder="Clientes"
														isMulti
														name={name}
														options={UserOpions}
														ref={ref}
														value={UserOpions.filter(
															(c) =>
																value?.includes(
																	c.value
																)
														)}
														onChange={(val) =>
															onChange(
																val?.map(
																	(c) =>
																		c.value
																)
															)
														}
													/>
												);
											}}
										/>
										{errors.ruts && (
											<Form.Text className="text-danger">
												{errors.ruts.message}
											</Form.Text>
										)}
									</Form.Group>
								</Col>
								<Col sm={6}>
									<Form.Label>Nombre</Form.Label>
									<Form.Control
										type="text"
										placeholder="Nombre del cliente"
										{...register('name', {
											required:
												'Correo electronico requerido',
										})}
									/>
									{errors.name && (
										<Form.Text className="text-danger">
											{errors.name.message}
										</Form.Text>
									)}
								</Col>
								<Col sm={6}>
									<Form.Label>Correo Electronico</Form.Label>
									<Form.Control
										readOnly
										disabled
										type="text"
										placeholder="Correo Electronico"
										{...register('email', {
											required:
												'Correo electronico requerido',
										})}
									/>
									{errors.email && (
										<Form.Text className="text-danger">
											{errors.email.message}
										</Form.Text>
									)}
								</Col>
							</Row>
							{updateIsError && (
								<Alert variant="danger">
									{updateError.message}
								</Alert>
							)}
							<Row className="justify-content-md-end">
								<Col sm={1}>
									<Button
										type="submit"
										variant="primary"
									>
										Actualizar
									</Button>
								</Col>
							</Row>
						</Form>
					)}

					{/* {JSON.stringify(UserOpions)} */}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default PopupUpdateUserRuts;
