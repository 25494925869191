// import * as BTable from 'react-bootstrap/Table';
import {
	ColumnDef,
	ColumnOrderState,
	flexRender,
	getCoreRowModel,
	Header,
	useReactTable,
	// Table,
	Column,
	OnChangeFn,
	SortingState,
	getSortedRowModel,
	getPaginationRowModel,
} from '@tanstack/react-table';
import { FC, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, Form, Pagination, Row, Table } from 'react-bootstrap';

import { DndProvider, useDrag, useDrop } from 'react-dnd';

import { FaAngleUp, FaAngleDown } from 'react-icons/fa';

import './BasicTable.css';
import { range } from 'underscore';
import { useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

var Spinner = require('react-spinkit');

export type Pagination = {
	pageIndex: number;
	pageSize: number;
};

export type TableProps<T> = {
	data: Array<T>;
	columns: Array<ColumnDef<T>>;

	loading?: boolean;
	pagination: Pagination;
	pageCount: number;

	registerCount: number;

	header: JSX.Element | JSX.Element[];
};

// const BasicTable = forwardRef<HTMLTableElement, TableProps<any>>(({
const TableV2 = ({
	data,
	columns,
	loading,
	pagination,
	pageCount,
	registerCount,
	header,
}: TableProps<any>) => {
	const { userLogged } = useAuth();
	const defaultOrderColum = useMemo(
		() => columns.map((column) => column.id as string),
		[]
	);
	const location = useLocation();
	const [sorting, setSorting] = useState<SortingState>([]);

	const table = useReactTable({
		data,
		columns,
		state: {
			sorting,
		},
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
	});

	const current = table.getState().pagination.pageIndex;
	let range_array: number[] = [];
	if (current === 0 && table.getPageCount() < 2) {
	} else if (current === 0 || current === 1) {
		range_array = range(
			0,
			table.getPageCount() > 4 ? 5 : table.getPageCount()
		);
	} else if (current > 1 && current < table.getPageCount() - 3) {
		range_array = range(current - 2, current + 3);
	} else {
		let init = table.getPageCount() - 5;
		range_array = range(init < 0 ? 0 : init, table.getPageCount());
	}

	return (
		<Container fluid>
			<Row>
				<Col sm={2}>
					<Form.Select
						value={table.getState().pagination.pageSize}
						onChange={(e) => {
							table.setPageSize(Number(e.target.value));
						}}
					>
						{[10, 20, 30, 40, 50].map((pageSize) => (
							<option
								key={pageSize}
								value={pageSize}
							>
								{pageSize}
							</option>
						))}
					</Form.Select>
				</Col>
				{header}
				{/* {(location.pathname === '/users/' ||
					location.pathname === '/users') && (
					<Col>
						{userLogged?.role == 'admin' ? (
							<PopupCreateUserByAdmin />
						) : (
							<PopupCreateUser />
						)}
					</Col>
				)} */}
				{/* {(location.pathname === '/executive/' ||
					location.pathname === '/executive') && (
					<Col>
						<PopupCreateExecutive />
					</Col>
				)} */}
			</Row>
			<div
				className="my-2"
				style={{ overflowX: 'auto' }}
			>
				<Table className="table table-bordered table-striped mg-b-0 text-md-nowrap">
					<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => (
									<th
										key={header.id}
										colSpan={header.colSpan}
									>
										{header.isPlaceholder ? null : (
											<div
												style={{ alignItems: 'center' }}
												{...{
													className:
														header.column.getCanSort()
															? 'cursor-pointer select-none d-flex'
															: '',
													onClick:
														header.column.getToggleSortingHandler(),
												}}
											>
												{flexRender(
													header.column.columnDef
														.header,
													header.getContext()
												)}
												{{
													// asc: ' 🔼',
													// desc: ' 🔽',
													asc: (
														<span className="d-flex flex-column">
															<FaAngleUp />
															<FaAngleDown color="lightgray" />
														</span>
													),
													desc: (
														<span className="d-flex flex-column">
															<FaAngleUp color="lightgray" />
															<FaAngleDown />
														</span>
													),
												}[
													header.column.getIsSorted() as string
												] ?? (
													<span className="d-flex flex-column">
														<FaAngleUp color="lightgray" />
														<FaAngleDown color="lightgray" />
													</span>
												)}
											</div>
										)}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody>
						{table.getRowModel().rows.map((row) => (
							<tr key={row.id}>
								{row.getVisibleCells().map((cell) => (
									<td
										key={cell.id}
										className={
											cell.column.id == 'action'
												? 'action_col'
												: ''
										}
									>
										{flexRender(
											cell.column.columnDef.cell,
											cell.getContext()
										)}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</Table>
			</div>
			<div className="d-flex justify-content-between">
				<div>
					<span>
						<span>
							Mostrar {table.getState().pagination.pageIndex + 1}{' '}
							de {table.getPageCount()}{' '}
						</span>
					</span>
					{/* <span>
                Ir a la página:
                <input
                  type="number"
                  defaultValue={table.getState().pagination.pageIndex + 1}
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    table.setPageIndex(page)
                  }}
                  className="border p-1 rounded w-16 paginationSelect"
                />
              </span> */}
					{' - '}
					<span>Total registros: {registerCount} </span>
				</div>
				<Pagination>
					<Pagination.First
						onClick={() => table.setPageIndex(0)}
						disabled={!table.getCanPreviousPage()}
					/>
					<Pagination.Prev
						onClick={() => table.previousPage()}
						disabled={!table.getCanPreviousPage()}
					/>

					{range_array.map((item) => {
						if (item === table.getState().pagination.pageIndex) {
							return (
								<Pagination.Item
									key={item}
									active
									onClick={() => table.setPageIndex(item)}
								>
									{item + 1}
								</Pagination.Item>
							);
						}
						return (
							<Pagination.Item
								key={item}
								onClick={() => table.setPageIndex(item)}
							>
								{item + 1}
							</Pagination.Item>
						);
					})}

					<Pagination.Next
						onClick={() => table.nextPage()}
						disabled={!table.getCanNextPage()}
					/>
					<Pagination.Last
						onClick={() =>
							table.setPageIndex(table.getPageCount() - 1)
						}
						disabled={!table.getCanNextPage()}
					/>
				</Pagination>
			</div>
		</Container>
	);
};

export default TableV2;
