import { FC } from 'react';
import { FaPaperPlane } from 'react-icons/fa';

import { Button } from 'react-bootstrap';
import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import executiveResetUserPass from 'services/executiveResetUserPass';

export type ButtonExecutiveResetUserPassProps = { email: string };
type request = { email: string };

const ButtonExecutiveResetUserPass: FC<ButtonExecutiveResetUserPassProps> = ({
	email,
}) => {
	// const queryClient = useQueryClient();

	const { mutate, isLoading } = useMutation<void, Error, request>(
		(r: request) => executiveResetUserPass(r.email),
		{
			onMutate: () => {
				const id = toast.loading('Enviando...');
				return { id };
			},
			onSuccess: (data, variables, context: any) => {
				toast.success('Correo para reestablecer contraseña enviado', {
					id: context.id,
				});
			},
			onError: (error, variables, context: any) => {
				toast.error(error.message, {
					id: context.id,
				});
			},
		}
	);

	const onSubmit = (data: any) => mutate({ email: email });

	return (
		<>
			<Button
				className="w-100"
				disabled={isLoading}
				variant="primary"
				onClick={onSubmit}
			>
				<FaPaperPlane /> Reestablecer contraseña
			</Button>
		</>
	);
};

export default ButtonExecutiveResetUserPass;
