import './ApisPage.css'
import { Container, Row, Col } from 'react-bootstrap';

const ApisPage = () => {
	return (
		<Container>
			<Row>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'center'}}
				>
					<img
						src={require('../../assets/img/apis.png')}
						className="w-75"
						alt="apis"
					/>
					<p className='my-3 text-3xl'>INTEGRACIONES API</p>
				</Col>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'justify'}}
				>
					<p className='text-xl'>Las API (Interfaz de Programación de Aplicaciones) permiten desarrollar una interfaz inteligente y conectan dos sistemas. Por ejemplo, la API de Google Maps permite rastrear la ubicación de los envíos de nuestros clientes. Son herramientas clave para cualquier empresa, ya que permiten automatizar y controlar diversos procesos, como el seguimiento y monitoreo en tiempo real de envíos y cargas. Nuestra plataforma cuenta con múltiples APIs que se adaptan a las necesidades de nuestros clientes.</p>
				</Col>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'center'}}
				>
					<p className='text-lg font-bold'>BENEFICIOS PARA NUESTROS CLIENTES</p>
				</Col>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'justify'}}
				>
					<ul className="list-disc ml-5 text-lg lg:text-xl flex flex-col gap-2"><li>Automatización de los procesos logísticos.</li><li>Reducción de costos y tiempos, mejorando la eficiencia.</li><li>Evita errores humanos y previene posibles contratiempos.</li></ul>
				</Col>
			</Row>
		</Container>
	);
};

export default ApisPage;
