import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { resetData } from 'data/Switcherdata/Switcherdata';
import { useAuth } from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { FaKey, FaPen } from 'react-icons/fa';
import Select from 'react-select';
import updateUser from 'services/updateUser';
import validateUser from 'services/validateUser';
import { updateUserRequest } from 'types/createUserRequest';

var Spinner = require('react-spinkit');

export type PopupUpdateUserPasswordProps = {
	clientEmail: string;
};

const PopupUpdateUserPassword = ({
	clientEmail,
}: PopupUpdateUserPasswordProps) => {
	const queryClient = useQueryClient();
	const [show, setShow] = useState(false);
	const defaultValues: updateUserRequest = {
		email: clientEmail,
		password: '',
		ruts: null,
	};

	const {
		getValues,
		reset: resetForm,
		setValue,
		watch,
		register,
		trigger,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<updateUserRequest>({
		defaultValues: defaultValues,
	});

	const {
		error: createError,
		isError: createIsError,
		mutate,
		isLoading: creating,
	} = useMutation((body: updateUserRequest) => updateUser(body), {
		onSuccess: () => {
			toast.success('Actualizacion de usuario exitosa');
			setShow(false);
		},
		onError: () => {
			toast.error('Ocurrio durante la Actualizacion del usuario');
		},
		onSettled: (data, error, variables, context) => {
			queryClient.invalidateQueries(['user-data']);
		},
	});

	// useEffect(()=>{
	//     if(!show) {
	//         reset(defaultValues);
	//         mutationReset();
	//     }
	// }, [show])

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const onSubmit = (submitData: updateUserRequest) => mutate(submitData);

	return (
		<>
			<Button
				className="w-100"
				variant="primary"
				onClick={handleShow}
			>
				<FaKey /> Cambio de contraseña
			</Button>

			<Modal
				show={show}
				onHide={handleClose}
				dialogClassName="modal-xl"
				centered={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>Cambio de contraseña</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Col sm={6}>
								<Form.Label>Correo Electronico</Form.Label>
								<Form.Control
									readOnly
									disabled
									type="text"
									placeholder="Correo Electronico"
									{...register('email', {
										required:
											'Correo electronico requerido',
									})}
								/>
								{errors.email && (
									<Form.Text className="text-danger">
										{errors.email.message}
									</Form.Text>
								)}
							</Col>
							<Col sm={6}>
								<Form.Label>Contraseña</Form.Label>
								<Form.Control
									type="password"
									placeholder="Contraseña"
									{...register('password', {
										required: 'Contraseña requerida',
									})}
								/>
								{errors.password && (
									<Form.Text className="text-danger">
										{errors.password.message}
									</Form.Text>
								)}
							</Col>
						</Row>
						{/* {isError &&
                            (<Alert variant='danger'>
                                { JSON.stringify(error) }
                            </Alert>)
                        } */}
						<Row className="justify-content-md-end">
							<Col sm={1}>
								<Button
									className="w-100"
									type="submit"
									variant="primary"
								>
									Actualizar
								</Button>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default PopupUpdateUserPassword;
