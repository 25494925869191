import instance from 'helpers/interceptor';

export interface updateNottificationSettingsRequest {
	order_way: 'a' | 't' | 'm' | null;
	order_type: 'international' | 'national';
	order: string | null;
	notificate_on: any;
	contact_list: any[];
}

const execute = (
	request: updateNottificationSettingsRequest
): Promise<void> => {
	return instance
		.put('notifications/config', request)
		.then((response) => response.data)
		.catch((response) => {
			throw new Error(response.response.data.error);
		});
};

export default execute;
