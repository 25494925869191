import { CellContext, ColumnDef } from '@tanstack/react-table';
import { Datum } from 'types/trackingResponse';
import { PopupActions } from 'components/PopupActions/PopupActions';
import Tooltip from 'components/Tooltip/Tooltip';
import IIndexable from 'types/IIndexable';
import { Badge, Button } from 'react-bootstrap';
import { PopupUserActions } from 'components/PopupUserActions/PopupUserActions';
import moment from 'moment';
import { FaTimesCircle } from 'react-icons/fa';
import PopupCompaniesOfUser from 'components/PopupCompaisOfUser/PopupCompaniesOfUser';
import { user } from 'services/getUser';

export const ColunmHeader: IIndexable = {
	id: 'ID',
	name: 'Nombre',
	email: 'Correo electronico',
	created_at: 'Fecha de creacion',
	last_login: 'Ultimo acceso',
	empresas: 'Empresas',
	country_name: 'Pais',
	created_by_name: 'Creado por',
};

const UserTableColumDef: ColumnDef<user>[] = [
	{
		enableSorting: false,
		enableHiding: false,
		header: '',
		id: 'action',
		accessorFn: (val: user) => val,
		cell: (props) => <PopupUserActions user={props.getValue<user>()} />,
	},
	// {
	//   accessorKey: 'id',
	//   id: 'id',
	//   header: () => <Tooltip text="id" children={<span>{ ColunmHeader['id'] }</span>}/>
	// },
	{
		accessorKey: 'name',
		id: 'name',
		header: () => (
			<Tooltip
				text="Nombre"
				children={<span>{ColunmHeader['name']}</span>}
			/>
		),
	},
	{
		accessorKey: 'email',
		id: 'email',
		header: () => (
			<Tooltip
				text="Correo"
				children={<span>{ColunmHeader['email']}</span>}
			/>
		),
	},
	{
		accessorKey: 'created_at',
		id: 'created_at',
		header: () => (
			<Tooltip
				text="Fecha de creacion"
				children={<span>{ColunmHeader['created_at']}</span>}
			/>
		),
		cell: (props: any) => moment(props.getValue()).format('DD/MM/YYYY'),
	},
	{
		accessorKey: 'last_login',
		id: 'last_login',
		header: () => (
			<Tooltip
				text="Ultimo acceso"
				children={<span>{ColunmHeader['last_login']}</span>}
			/>
		),
		cell: (props) => <LastLogin last_login={props.getValue<Date>()} />,
	},
	{
		accessorKey: 'country_name',
		id: 'country_name',
		header: () => (
			<Tooltip
				text="Pais"
				children={<span>{ColunmHeader['country_name']}</span>}
			/>
		),
	},
	{
		accessorKey: 'created_by_name',
		id: 'created_by_name',
		header: () => (
			<Tooltip
				text="Usuario creador"
				children={<span>{ColunmHeader['created_by_name']}</span>}
			/>
		),
	},
	{
		accessorKey: 'empresas',
		id: 'empresas',
		header: () => (
			<Tooltip
				text="Empresas"
				children={<span>{ColunmHeader['empresas']}</span>}
			/>
		),
		cell: (props) => (
			<PopupCompaniesOfUser
				email={props.row.original.email}
				buttonText={props.getValue<string>()}
			/>
		),
	},
];

const LastLogin = ({ last_login }: { last_login: Date | null }) => {
	if (last_login === null) {
		return (
			<span>
				<FaTimesCircle
					color="red"
					style={{ fontSize: 24 }}
				/>
			</span>
		);
	}
	const days = moment().diff(moment(last_login), 'days');
	return (
		<span>
			<Badge
				bg={days < 7 ? 'success' : 'warning'}
				style={{ fontSize: 12 }}
			>{`${days} DIAS`}</Badge>
			{/* <span style={{ backgroundColor: (days < 7 ? 'green' : 'orange'), color:"white", padding:5 }}>{`${days} DIAS` }</span> */}
		</span>
	);
};

export default UserTableColumDef;
