import { Header } from './components/Header/Header';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useDownloadExcel } from 'react-export-table-to-excel';
import getDataTraking from 'services/getDataTraking';
import './HomePage.css';
import { useQuery } from '@tanstack/react-query';
import { BasicTable } from 'components/Table/BasicTable';
import TrackingTableColumDef from './components/TrackingTableColumDef';
import { Filters, PaginationState, SortingState } from '@tanstack/react-table';
import moment from 'moment';
import { Params } from 'types/Params';
import { useRecoilState } from 'recoil';
import headerCartAtom from 'atoms/headerCartAtom';
import { Datum, HeaderValues } from 'types/trackingResponse';
import IIndexable from 'types/IIndexable';
import excelInternationalDataAtom from 'atoms/excelInternationalDataAtom';
import { difference } from 'lodash';
import toast from 'react-hot-toast';

var Spinner = require('react-spinkit');

function HomePage() {
	const [filter, setFilters] = useState<any>({
		origin: '',
		destination: '',
		mode: '',
		service: '',
		status: '',
		receiverRefs: '',
		senderRefs: '',
		ot: '',
		orderBy: '',

		// startDate: moment().subtract(1, 'y'),
		// endDate: moment(),
		startDate: '',
		endDate: '',

		etdStartDate: '',
		etdEndDate: '',
		etaStartDate: '',
		etaEndDate: '',

		sinceMercaderiaListaParaEmbarque: '',
		untilMercaderiaListaParaEmbarque: '',
		sinceRecogidaEnOrigen: '',
		untilRecogidaEnOrigen: '',
		sinceArriboAduanaOrigen: '',
		untilArriboAduanaOrigen: '',
		sinceLiberacionAduanaOrigen: '',
		untilLiberacionAduanaOrigen: '',
		sinceIngresoAduanaDestino: '',
		untilIngresoAduanaDestino: '',
		sinceLiberacionAduanaDestino: '',
		untilLiberacionAduanaDestino: '',
		sinceEntregaFinal: '',
		untilEntregaFinal: '',
		ultimoEstatus: '',

		rawSenderRefs: '',
		rawReceiverRefs: '',
	});

	const [, setExcelData] = useRecoilState<Array<Datum>>(
		excelInternationalDataAtom
	);
	const defaultRange = useMemo(
		() => ({ start: moment().subtract(6, 'months'), end: moment() }),
		[]
	);

	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});

	const [sorting, setSorting] = useState<SortingState>([]);
	useEffect(() => {
		let sort = sorting
			.map((item) => `${item.id} ${item.desc ? 'DESC' : 'ASC'}`)
			.join(',');
		setFilters((oldFilter: any) => {
			return { ...oldFilter, orderBy: sort };
		});
	}, [sorting]);

	const dateFormat = (date: string) =>
		date !== null && date !== '' ? moment(date).format('YYYY/MM/DD') : date;

	const fetchDataOptions: Params = {
		page: pageIndex.toString(),
		size: pageSize.toString(),
		origin: filter.origin,
		destination: filter.destination,
		mode: filter.mode,
		services: filter.service,
		status: filter.status,
		receiverRefs: filter.receiverRefs,
		senderRefs: filter.senderRefs,
		ot: filter.ot,
		ultimoEstatus: filter.ultimoEstatus,

		// since: dateFormat(filter.startDate),
		// until: dateFormat(filter.endDate),
		since: dateFormat(
			filter.startDate === '' ? defaultRange.start : filter.startDate
		),
		until: dateFormat(
			filter.endDate === '' ? defaultRange.end : filter.endDate
		),

		sinceETD: dateFormat(filter.etdStartDate),
		untilETD: dateFormat(filter.etdEndDate),

		sinceETA: dateFormat(filter.etaStartDate),
		untilETA: dateFormat(filter.etaEndDate),

		sinceMercaderiaListaParaEmbarque: dateFormat(
			filter.sinceMercaderiaListaParaEmbarque
		),
		untilMercaderiaListaParaEmbarque: dateFormat(
			filter.untilMercaderiaListaParaEmbarque
		),
		sinceRecogidaEnOrigen: dateFormat(filter.sinceRecogidaEnOrigen),
		untilRecogidaEnOrigen: dateFormat(filter.untilRecogidaEnOrigen),
		sinceArriboAduanaOrigen: dateFormat(filter.sinceArriboAduanaOrigen),
		untilArriboAduanaOrigen: dateFormat(filter.untilArriboAduanaOrigen),
		sinceLiberacionAduanaOrigen: dateFormat(
			filter.sinceLiberacionAduanaOrigen
		),
		untilLiberacionAduanaOrigen: dateFormat(
			filter.untilLiberacionAduanaOrigen
		),
		sinceIngresoAduanaDestino: dateFormat(filter.sinceIngresoAduanaDestino),
		untilIngresoAduanaDestino: dateFormat(filter.untilIngresoAduanaDestino),
		sinceLiberacionAduanaDestino: dateFormat(
			filter.sinceLiberacionAduanaDestino
		),
		untilLiberacionAduanaDestino: dateFormat(
			filter.untilLiberacionAduanaDestino
		),
		sinceEntregaFinal: dateFormat(filter.sinceEntregaFinal),
		untilEntregaFinal: dateFormat(filter.untilEntregaFinal),

		orderBy: filter.orderBy,
		rawSenderRefs: filter.rawSenderRefs,
		rawReceiverRefs: filter.rawReceiverRefs,
	};

	const columns = useMemo(() => TrackingTableColumDef, []);
	const { data, error, isFetching, isPreviousData, isInitialLoading } =
		useQuery({
			queryKey: ['tracking-international-table-data', fetchDataOptions],
			queryFn: async () => {
				if (!isInitialLoading) {
					toast.dismiss();
					toast.loading('Cargando...');
				}
				const data = await getDataTraking(fetchDataOptions);
				return data;
			},
			keepPreviousData: true,
			staleTime: 5000,
			refetchOnWindowFocus: false,
			onSuccess: (data) => {
				if (!isInitialLoading) {
					toast.dismiss();
					toast.success('Carga Exitosa');
				}
			},
			onError: () => {
				toast.dismiss();
				toast.error('Ocurrio un error');
			},
		});

	const param: Params = { ...fetchDataOptions, page: '0', size: '1000' };
	useQuery(
		['tracking-international-excel-data', param],
		() => getDataTraking(param),
		{
			staleTime: 5000,
			refetchOnWindowFocus: false,
			onSuccess(data) {
				setExcelData(data.data);
			},
		}
	);

	const [headerValues, setHeaderValues] = useRecoilState(headerCartAtom);

	useEffect(() => {
		let headerValues: HeaderValues = data?.header ?? {
			terrestre: 0,
			aereo: 0,
			maritimo: 0,
		};
		setHeaderValues({ ...headerValues });
	}, [data]);

	const defaultData = useMemo(() => [], []);

	if (isInitialLoading) {
		return <Spinner className="main-loading" name="wandering-cubes" />;
	}

	const clean = (selected: string) => {
		const dic: IIndexable = {
			etd: 'etdStartDate-etdEndDate',
			status: 'status',
			origin: 'origin',
			destiny: 'destination',
			receptor: 'receiverRefs',
			otDate: 'startDate-endDate',
			sender: 'senderRefs',
			mode: 'mode',
			service: 'service',
			ot: 'ot',
			eta: 'etaStartDate-etaEndDate',
			lastStatus: 'ultimoEstatus',
			refSender: 'rawSenderRefs',
			refReceiver: 'rawReceiverRefs',
		};

		const filtrableKeys: string[] = Object.values(dic).flatMap((val) =>
			val.split('-')
		);
		let filterToShow = selected
			.split(',')
			.filter((item) => item !== '')
			.flatMap((element) => dic[element].split('-'));
		let filterStateCleaned = difference(filtrableKeys, filterToShow)
			.map((item) => ({ [item]: '' }))
			.reduce((acc, item) => ({ ...acc, ...item }), {});
		setFilters((old: any) => ({ ...old, ...filterStateCleaned }));
	};

	return (
		<Fragment>
			<Header
				defaultValues={['otDate']}
				originChange={(e) =>
					setFilters((oldFilter: any) => {
						return { ...oldFilter, origin: e };
					})
				}
				destinationChange={(e) =>
					setFilters((oldFilter: any) => {
						return { ...oldFilter, destination: e };
					})
				}
				modeChange={(e) =>
					setFilters((oldFilter: any) => {
						return { ...oldFilter, mode: e };
					})
				}
				serviceChange={(e) =>
					setFilters((oldFilter: any) => {
						return { ...oldFilter, service: e };
					})
				}
				statusChange={(e) =>
					setFilters((oldFilter: any) => {
						return { ...oldFilter, status: e };
					})
				}
				receptorChange={(e) =>
					setFilters((oldFilter: any) => {
						return { ...oldFilter, receiverRefs: e };
					})
				}
				senderChange={(e) =>
					setFilters((oldFilter: any) => {
						return { ...oldFilter, senderRefs: e };
					})
				}
				otChange={(e) =>
					setFilters((oldFilter: any) => {
						return { ...oldFilter, ot: e };
					})
				}
				refReceiverChange={(e) =>
					setFilters((oldFilter: any) => {
						return { ...oldFilter, rawReceiverRefs: e };
					})
				}
				refSenderChange={(e) =>
					setFilters((oldFilter: any) => {
						return { ...oldFilter, rawSenderRefs: e };
					})
				}
				lastStateChange={(e) =>
					setFilters((oldFilter: any) => {
						return { ...oldFilter, ultimoEstatus: e };
					})
				}
				dateRange={{
					startDate:
						filter.startDate !== ''
							? filter.startDate
							: defaultRange.start,
					endDate:
						filter.endDate !== ''
							? filter.endDate
							: defaultRange.end,
					onChange: (e) =>
						setFilters((oldFilter: any) => {
							return {
								...oldFilter,
								startDate: e[0] == null ? '' : e[0],
								endDate: e[1] == null ? '' : e[1],
							};
						}),
				}}
				etdDateRange={{
					startDate: filter.etdStartDate,
					endDate: filter.etdEndDate,
					onChange: (e) =>
						setFilters((oldFilter: any) => {
							return {
								...oldFilter,
								etdStartDate: e[0] == null ? '' : e[0],
								etdEndDate: e[1] == null ? '' : e[1],
							};
						}),
				}}
				etaDateRange={{
					startDate: filter.etaStartDate,
					endDate: filter.etaEndDate,
					onChange: (e) =>
						setFilters((oldFilter: any) => {
							return {
								...oldFilter,
								etaStartDate: e[0] == null ? '' : e[0],
								etaEndDate: e[1] == null ? '' : e[1],
							};
						}),
				}}
				mercaderiaListaParaEmbarqueDateRange={{
					startDate: filter.sinceMercaderiaListaParaEmbarque,
					endDate: filter.untilMercaderiaListaParaEmbarque,
					onChange: (e) =>
						setFilters((oldFilter: any) => {
							return {
								...oldFilter,
								sinceMercaderiaListaParaEmbarque:
									e[0] == null ? '' : e[0],
								untilMercaderiaListaParaEmbarque:
									e[1] == null ? '' : e[1],
							};
						}),
				}}
				recogidaEnOrigenDateRange={{
					startDate: filter.sinceRecogidaEnOrigen,
					endDate: filter.untilRecogidaEnOrigen,
					onChange: (e) =>
						setFilters((oldFilter: any) => {
							return {
								...oldFilter,
								sinceRecogidaEnOrigen: e[0] == null ? '' : e[0],
								untilRecogidaEnOrigen: e[1] == null ? '' : e[1],
							};
						}),
				}}
				arriboAduanaOrigenDateRange={{
					startDate: filter.sinceArriboAduanaOrigen,
					endDate: filter.untilArriboAduanaOrigen,
					onChange: (e) =>
						setFilters((oldFilter: any) => {
							return {
								...oldFilter,
								sinceArriboAduanaOrigen:
									e[0] == null ? '' : e[0],
								untilArriboAduanaOrigen:
									e[1] == null ? '' : e[1],
							};
						}),
				}}
				liberacionAduanaOrigenDateRange={{
					startDate: filter.sinceLiberacionAduanaOrigen,
					endDate: filter.untilLiberacionAduanaDestino,
					onChange: (e) =>
						setFilters((oldFilter: any) => {
							return {
								...oldFilter,
								sinceLiberacionAduanaOrigen:
									e[0] == null ? '' : e[0],
								untilLiberacionAduanaDestino:
									e[1] == null ? '' : e[1],
							};
						}),
				}}
				ingresoAduanaDestinoDateRange={{
					startDate: filter.sinceIngresoAduanaDestino,
					endDate: filter.untilIngresoAduanaDestino,
					onChange: (e) =>
						setFilters((oldFilter: any) => {
							return {
								...oldFilter,
								sinceIngresoAduanaDestino:
									e[0] == null ? '' : e[0],
								untilIngresoAduanaDestino:
									e[1] == null ? '' : e[1],
							};
						}),
				}}
				liberacionAduanaDestinoDateRange={{
					startDate: filter.sinceLiberacionAduanaDestino,
					endDate: filter.untilLiberacionAduanaDestino,
					onChange: (e) =>
						setFilters((oldFilter: any) => {
							return {
								...oldFilter,
								sinceLiberacionAduanaDestino:
									e[0] == null ? '' : e[0],
								untilLiberacionAduanaDestino:
									e[1] == null ? '' : e[1],
							};
						}),
				}}
				entregaFinalDateRange={{
					startDate: filter.sinceEntregaFinal,
					endDate: filter.untilEntregaFinal,
					onChange: (e) =>
						setFilters((oldFilter: any) => {
							return {
								...oldFilter,
								sinceEntregaFinal: e[0] == null ? '' : e[0],
								untilEntregaFinal: e[1] == null ? '' : e[1],
							};
						}),
				}}
				masterSelectChange={clean}
			/>
			<BasicTable
				registerCount={
					data != undefined ? data.pagination.registerCount : 0
				}
				// data={data !=  undefined ? data.data.slice(0, 1) : defaultData }
				data={data != undefined ? data.data : defaultData}
				columns={columns}
				loading={isFetching}
				pagination={{
					pageIndex,
					pageSize,
				}}
				setPagination={setPagination}
				pageCount={
					data != undefined ? data.pagination.numberOfPages : 0
				}
				sorting={sorting}
				setSorting={setSorting}
			/>
		</Fragment>
	);
}

export default HomePage;
