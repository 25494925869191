import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
	CellContext,
	PaginationState,
	SortingState,
} from '@tanstack/react-table';
import { DatePicker } from 'components/DatePicker/DatePicker';
import { ExecutiveSelect } from 'components/ExecutiveSelect/ExecutiveSelect';
import { CountrySelect } from 'components/PopupCreateExecutive/components/CountrySelect/CountrySelect';
import { BasicTable } from 'components/Table/BasicTable';
import TableV2 from 'components/TableV2/TableV2';
import { useAuth } from 'hooks/useAuth';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import getUser, { user } from 'services/getUser';
import UserTableColumDef from './components/UserTableColumDef';
import Tooltip from 'components/Tooltip/Tooltip';
import EnableExecutive from 'components/PopupUpdateExecutive/EnableExecutive';
import PopupCreateUserByAdmin from 'components/PopupCreateUserByExecutive/PopupCreateUserByAdmin';
import PopupCreateUser from 'components/PopupCreateUser/PopupCreateUser';
interface filter {
	email: string;
	startDate: string;
	endDate: string;
	country: string;
	executives: string[];
}

const UserPage = () => {
	const { userLogged } = useAuth();
	const [filter, setFilter] = useState<filter>({
		email: '',
		startDate: '',
		endDate: '',
		executives: [],
		// country:
		// 	userLogged?.country_id !== undefined
		// 		? userLogged?.country_id.toString()
		// 		: '',
		country:
			userLogged?.role === 'admin' || userLogged?.country_id === undefined
				? ''
				: userLogged?.country_id.toString(),
	});

	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});

	const [sorting, setSorting] = useState<SortingState>([]);
	const defaultData = useMemo(() => [], []);

	const columns = useMemo(
		() =>
			userLogged?.role === 'admin'
				? [
						...UserTableColumDef,
						{
							accessorKey: 'enable',
							id: 'enable',
							header: () => (
								<Tooltip
									text="Activo"
									children={<span>Activo</span>}
								/>
							),
							cell: (props: CellContext<user, unknown>) => (
								<EnableExecutive
									status={!!props.getValue<number>()}
									executiveID={props.row.original.id}
								/>
							),
						},
				  ]
				: UserTableColumDef,
		[]
	);
	const { data, error, isFetching, isPreviousData, isLoading } = useQuery(
		['user-data'],
		() => getUser(),
		{ keepPreviousData: true, staleTime: 5000, refetchOnWindowFocus: false }
	);
	const dateChange = ([init, end]: [Date | null, Date | null]) => {
		setFilter((prev: any) => ({
			...prev,
			startDate: init == null ? '' : init,
			endDate: end == null ? '' : end,
		}));
	};

	const executiveChange = (executives: string) => {
		setFilter((prev: any) => ({
			...prev,
			executives: executives.split(',').filter((x) => x !== ''),
		}));
	};

	const countryChange = (country: string) => {
		setFilter((prev: any) => ({ ...prev, country: country }));
	};

	const filterData = (data ?? [])
		.filter((item) =>
			filter.email == ''
				? true
				: item.email.toLowerCase().indexOf(filter.email) > -1
		)
		.filter((item) =>
			filter.startDate == ''
				? true
				: moment(item.created_at).isBetween(
						filter.startDate,
						filter.endDate,
						undefined,
						'[]'
				  )
		)
		.filter((item) =>
			filter.country == ''
				? true
				: item.country_id.toString() == filter.country
		)
		.filter((x) =>
			filter.executives.length === 0
				? true
				: filter.executives.includes(x.created_by.toString())
		);

	return (
		<>
			<Container fluid>
				<Row>
					<Col sm={4}>
						<Form.Control
							type="text"
							placeholder="Correo electronico"
							onChange={(e) =>
								setFilter((prev: any) => ({
									...prev,
									email: e.target.value.toLowerCase(),
								}))
							}
						/>
					</Col>
					<Col sm={4}>
						<DatePicker
							placeholder="Fecha creación inicio - Fecha creación fin"
							onChange={dateChange}
							initialEmpty={true}
						/>
					</Col>
					{userLogged?.role !== 'client' && (
						<Col sm={4}>
							<CountrySelect
								disabled={userLogged?.role === 'executive'}
								placeholder="Paises"
								onChange={(e) => countryChange(e.value ?? '')}
								value={filter.country}
								allCountryOption={true}
							/>
						</Col>
					)}
					{userLogged?.role === 'admin' && (
						<Col sm={4}>
							<ExecutiveSelect
								placeholder="Ejecutivos"
								onChange={(e) => executiveChange(e.value ?? '')}
							/>
						</Col>
					)}
				</Row>
			</Container>
			<TableV2
				header={
					<Col>
						{userLogged?.role == 'admin' ? (
							<PopupCreateUserByAdmin />
						) : (
							<PopupCreateUser />
						)}
					</Col>
				}
				registerCount={filterData.length}
				// data={ data ?? defaultData }
				data={filterData}
				columns={columns}
				loading={false}
				pagination={{
					pageIndex,
					pageSize,
				}}
				pageCount={0}
			/>
		</>
	);
};

export default UserPage;
