import React, {
	createRef,
	FormEvent,
	FormEventHandler,
	HtmlHTMLAttributes,
	useEffect,
	useState,
} from 'react';
import { Button, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import './Login.css';
import { useMutation } from '@tanstack/react-query';
import forgotPassword from 'services/forgotPassword';
import { toast } from 'react-hot-toast';

interface request {
	email: string;
}

const ForgotPasswordPage = () => {
	const navigate = useNavigate();
	const [showAlert, setShowAlert] = useState(false);
	const { error, isError, mutate, isLoading } = useMutation<
		void,
		Error,
		request
	>({
		mutationFn: (r: request) => forgotPassword(r.email),
		onSuccess: () => {
			toast.success(
				'Se envio un correo con los pasos a seguir para recuperar su cuenta',
				{
					duration: 5000,
				}
			);
			navigate('/');
		},
	});

	var ref = createRef<HTMLInputElement>();
	const onSubmit = (e: any) => {
		e.preventDefault();

		const email = ref.current?.value ?? '';
		if (email === '') return;
		console.log(email);
		mutate({ email: email });
	};

	return (
		<div>
			<div className="page-login">
				<Row></Row>
				<div className="page-single">
					<div className="container">
						<Row>
							<Col
								xl={5}
								lg={6}
								md={8}
								sm={8}
								xs={10}
								className="card-sigin-main mx-auto my-auto py-4 justify-content-center"
							>
								<div className="card-sigin">
									{/* <!-- Demo content--> */}
									<div className="main-card-signin d-md-flex">
										<div className="wd-100p">
											<div className="d-flex mb-4">
												<Link
													to={`${process.env.PUBLIC_URL}/login`}
												>
													<img
														src={require('../../assets/img/logo.png')}
														className="sign-favicon ht-40"
														alt="logo"
													/>
												</Link>
											</div>
											<div className="">
												<div className="main-signup-header">
													<h2>Bienvenidos</h2>
													<h6 className="font-weight-semibold">
														Ingresa tu correo para
														recuperar tu contraseña
													</h6>
													<div className="panel panel-primary">
														<div className="tab-menu-heading mb-2 border-bottom-0">
															<div className="tabs-menu1">
																{isError && (
																	<Alert
																		variant="danger"
																		// onClose={() =>
																		// 	setShowAlert(
																		// 		false
																		// 	)
																		// }
																		// dismissible
																	>
																		<Alert.Heading>
																			Tenemos
																			un
																			problema!
																		</Alert.Heading>
																		<p>
																			{
																				error.message
																			}
																		</p>
																	</Alert>
																)}
																<div
																	className="panel-body tabs-menu-body border-0 p-3"
																	id="tab5"
																>
																	<Form
																		onSubmit={
																			onSubmit
																		}
																	>
																		<Form.Group className="form-group">
																			<Form.Label>
																				Correo
																				Electronico
																			</Form.Label>{' '}
																			<Form.Control
																				ref={
																					ref
																				}
																				className="form-control"
																				placeholder="Ingrese su correo electronico"
																				type="text"
																				required
																				id="email"
																			/>
																		</Form.Group>
																		<Button
																			variant=""
																			className="btn btn-primaryLogin btn-block"
																			onClick={
																				onSubmit
																			}
																			// onClick={
																			// 	!isLoading
																			// 		? onSubmit
																			// 		: () =>
																			// 				null
																			// }
																		>
																			{isLoading
																				? 'Cargando…'
																				: 'Enviar'}
																		</Button>
																	</Form>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPasswordPage;
