import PopupClientChangePassword from 'components/PopupClientChangePassword/PopupClientChangePassword';
import React, { Fragment, useEffect, useState } from 'react';
import {
	Navbar,
	Dropdown,
	Button,
	Form,
	Col,
	Row,
	Modal,
} from 'react-bootstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { FaWhatsapp } from 'react-icons/fa';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

export default function Header() {
	const { userLogged } = useAuth();
	let [searchParams] = useSearchParams();

	const [Lang, setLang] = React.useState(false);
	const [fullscreens, setFullscreen] = React.useState(true);
	const Darkmode = () => {
		document.querySelector('.app')?.classList.toggle('dark-theme');
	};
	var elem: any = document.documentElement;
	const Fullscreen: any = (vale: any) => {
		switch (vale) {
			case true:
				elem.requestFullscreen();
				setFullscreen(false);
				break;
			case false:
				document.exitFullscreen();
				setFullscreen(true);
				break;
		}
	};

	//leftsidemenu
	const openCloseSidebar = () => {
		document.querySelector('.app')?.classList.toggle('sidenav-toggled');
	};
	//rightsidebar
	const Rightsidebar = () => {
		document.querySelector('.sidebar-right')?.classList.add('sidebar-open');
	};

	const getdata: any = [];

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
		console.log(open);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const ondelete = (id: any) => {};

	let navigate = useNavigate();
	const routeChange = () => {
		let path = `${process.env.PUBLIC_URL}/`;
		navigate(path);
	};

	const { isLogged, logout } = useAuth();

	return (
		<React.Fragment>
			<Navbar className="main-header side-header sticky nav nav-item">
				<div className="main-container container-fluid">
					<div className="main-header-left ">
						<div className="responsive-logo">
							<Link
								to={`${process.env.PUBLIC_URL}/dashboard/dashboard-1`}
								className="header-logo"
							>
								<img
									src={require('../../assets/img/logo.png')}
									className="mobile-logo logo-1"
									alt="logo"
								/>
								<img
									src={require('../../assets/img/logo.png')}
									className="mobile-logo dark-logo-1"
									alt="logo"
								/>
							</Link>
						</div>
						<div
							className="app-sidebar__toggle"
							data-bs-toggle="sidebar"
							onClick={() => openCloseSidebar()}
						>
							<Link
								className="open-toggle"
								to="#"
							>
								<i className="header-icon fe fe-align-left"></i>
							</Link>
							<Link
								className="close-toggle"
								to="#"
							>
								<i className="header-icon fe fe-x"></i>
							</Link>
						</div>
						<div className="logo-horizontal">
							<Link
								to={`${process.env.PUBLIC_URL}/dashboard/dashboard-1`}
								className="header-logo"
							>
								<img
									src={require('../../assets/img/brand/logo.png')}
									className="mobile-logo logo-1"
									alt="logo"
								/>
								<img
									src={require('../../assets/img/brand/logo-white.png')}
									className="mobile-logo dark-logo-1"
									alt="logo"
								/>
							</Link>
						</div>
					</div>
					<div className="main-header-right">
						<Navbar.Toggle
							className="navresponsive-toggler d-lg-none ms-auto"
							type="button"
						>
							<span className="navbar-toggler-icon fe fe-more-vertical"></span>
						</Navbar.Toggle>
						<div className="mb-0 navbar navbar-expand-lg   navbar-nav-right responsive-navbar navbar-dark p-0">
							<Navbar.Collapse
								className="collapse"
								id="navbarSupportedContent-4"
							>
								<ul className="nav nav-item header-icons navbar-nav-right ms-auto">
									<li className="dropdown nav-item"></li>
									<li className="dropdown nav-item">
										<Link
											to={
												searchParams.get('token') ===
												null
													? '#'
													: `?token=${searchParams.get(
															'token'
													  )}`
											}
											className="new nav-link theme-layout nav-link-bg layout-setting"
											onClick={() => Darkmode()}
										>
											<span className="dark-layout">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className="header-icon-svgs"
													width="24"
													height="24"
													viewBox="0 0 24 24"
												>
													<path d="M20.742 13.045a8.088 8.088 0 0 1-2.077.271c-2.135 0-4.14-.83-5.646-2.336a8.025 8.025 0 0 1-2.064-7.723A1 1 0 0 0 9.73 2.034a10.014 10.014 0 0 0-4.489 2.582c-3.898 3.898-3.898 10.243 0 14.143a9.937 9.937 0 0 0 7.072 2.93 9.93 9.93 0 0 0 7.07-2.929 10.007 10.007 0 0 0 2.583-4.491 1.001 1.001 0 0 0-1.224-1.224zm-2.772 4.301a7.947 7.947 0 0 1-5.656 2.343 7.953 7.953 0 0 1-5.658-2.344c-3.118-3.119-3.118-8.195 0-11.314a7.923 7.923 0 0 1 2.06-1.483 10.027 10.027 0 0 0 2.89 7.848 9.972 9.972 0 0 0 7.848 2.891 8.036 8.036 0 0 1-1.484 2.059z" />
												</svg>
											</span>
											<span className="light-layout">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													className="header-icon-svgs"
													width="24"
													height="24"
													viewBox="0 0 24 24"
												>
													<path d="M6.993 12c0 2.761 2.246 5.007 5.007 5.007s5.007-2.246 5.007-5.007S14.761 6.993 12 6.993 6.993 9.239 6.993 12zM12 8.993c1.658 0 3.007 1.349 3.007 3.007S13.658 15.007 12 15.007 8.993 13.658 8.993 12 10.342 8.993 12 8.993zM10.998 19h2v3h-2zm0-17h2v3h-2zm-9 9h3v2h-3zm17 0h3v2h-3zM4.219 18.363l2.12-2.122 1.415 1.414-2.12 2.122zM16.24 6.344l2.122-2.122 1.414 1.414-2.122 2.122zM6.342 7.759 4.22 5.637l1.415-1.414 2.12 2.122zm13.434 10.605-1.414 1.414-2.122-2.122 1.414-1.414z" />
												</svg>
											</span>
										</Link>
									</li>
									<li className="nav-item">
										<a
											className="new nav-link"
											target="_blank"
											href={new URL(
												'https://web.whatsapp.com/send?phone=+56937408192&text=Hola, me gustaría iniciar una conversación con un agente de Tracking Personalizado Delpa'
											).toString()}
										>
											<FaWhatsapp
												style={{
													fill: 'rgb(69 193 85)',
												}}
												className="header-icon-svgs"
											/>
										</a>
									</li>
									{/* <Dropdown className="dropdown nav-item  main-header-message ">
                  <Dropdown.Toggle className="new nav-link" href="#" variant="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="header-icon-svgs"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm0 2v.511l-8 6.223-8-6.222V6h16zM4 18V9.044l7.386 5.745a.994.994 0 0 0 1.228 0L20 9.044 20.002 18H4z" />
                    </svg>
                    <span className="badge bg-secondary header-badge">5</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="menu-header-content text-start border-bottom">
                      <div className="d-flex">
                        <h6 className="dropdown-title mb-1 tx-15 font-weight-semibold">
                          Messages
                        </h6>
                        <span className="badge badge-pill badge-warning ms-auto my-auto float-end">
                          Mark All Read
                        </span>
                      </div>
                      <p className="dropdown-title-text subtext mb-0 op-6 pb-0 tx-12 ">
                        You have 4 unread messages
                      </p>
                    </div>
                    <Scrollbars style={{ height: 280 }}>
                      <div className="main-message-list chat-scroll">
                        <Dropdown.Item
                          href={`${process.env.PUBLIC_URL}/pages/mail/chat`}
                          className="dropdown-item d-flex border-bottom"
                        >
                          <img
                            className="  drop-img  cover-image  "
                            alt=""
                            src={require("../../assets/img/faces/3.jpg")}
                          />
                          <span className="avatar-status bg-teal"></span>

                          <div className="wd-90p">
                            <div className="d-flex">
                              <h5 className="mb-0 name">Teri Dactyl</h5>
                            </div>
                            <p className="mb-0 desc">
                              I'm sorry but i'm not sure how to help you with
                              that......
                            </p>
                            <p className="time mb-0 text-start float-start ms-2 mt-2">
                              Mar 15 3:55 PM
                            </p>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href={`${process.env.PUBLIC_URL}/pages/mail/chat`}
                          className="dropdown-item d-flex border-bottom"
                        >
                          <img
                            className="drop-img cover-image"
                            alt=""
                            src={require("../../assets/img/faces/2.jpg")}
                          />
                          <span className="avatar-status bg-teal"></span>

                          <div className="wd-90p">
                            <div className="d-flex">
                              <h5 className="mb-0 name">Allie Grater</h5>
                            </div>
                            <p className="mb-0 desc">
                              All set ! Now, time to get to you now......
                            </p>
                            <p className="time mb-0 text-start float-start ms-2 mt-2">
                              Mar 06 01:12 AM
                            </p>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href={`${process.env.PUBLIC_URL}/pages/mail/chat`}
                          className="dropdown-item d-flex border-bottom"
                        >
                          <img
                            className="drop-img cover-image"
                            alt=""
                            src={require("../../assets/img/faces/9.jpg")}
                          />
                          <span className="avatar-status bg-teal"></span>

                          <div className="wd-90p">
                            <div className="d-flex">
                              <h5 className="mb-0 name">Aida Bugg</h5>
                            </div>
                            <p className="mb-0 desc">
                              Are you ready to pickup your Delivery...
                            </p>
                            <p className="time mb-0 text-start float-start ms-2 mt-2">
                              Feb 25 10:35 AM
                            </p>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href={`${process.env.PUBLIC_URL}/pages/mail/chat`}
                          className="dropdown-item d-flex border-bottom"
                        >
                          <img
                            className="drop-img cover-image"
                            alt=""
                            src={require("../../assets/img/faces/12.jpg")}
                          />
                          <span className="avatar-status bg-teal"></span>
                          <div className="wd-90p">
                            <div className="d-flex">
                              <h5 className="mb-0 name">John Quil</h5>
                            </div>
                            <p className="mb-0 desc">
                              Here are some products ...
                            </p>
                            <p className="time mb-0 text-start float-start ms-2 mt-2">
                              Feb 12 05:12 PM
                            </p>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href={`${process.env.PUBLIC_URL}/pages/mail/chat`}
                          className="dropdown-item d-flex border-bottom"
                        >
                          <img
                            className="drop-img cover-image"
                            alt=""
                            src={require("../../assets/img/faces/5.jpg")}
                          />
                          <span className="avatar-status bg-teal"></span>

                          <div className="wd-90p">
                            <div className="d-flex">
                              <h5 className="mb-0 name">Liz Erd</h5>
                            </div>
                            <p className="mb-0 desc">
                              I'm sorry but i'm not sure how...
                            </p>
                            <p className="time mb-0 text-start float-start ms-2 mt-2">
                              Jan 29 03:16 PM
                            </p>
                          </div>
                        </Dropdown.Item>
                      </div>
                    </Scrollbars>
                    <div className="text-center dropdown-footer">
                      <Link
                        className="btn btn-primary btn-sm btn-block text-center"
                        to={`${process.env.PUBLIC_URL}/pages/mail/chat`}
                      >
                        VIEW ALL
                      </Link>
                    </div>
                  </Dropdown.Menu>
                </Dropdown> */}
									{/* <Dropdown className=" nav-item main-header-notification d-flex">
                  <Dropdown.Toggle className="new nav-link" href="#" variant="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="header-icon-svgs"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M19 13.586V10c0-3.217-2.185-5.927-5.145-6.742C13.562 2.52 12.846 2 12 2s-1.562.52-1.855 1.258C7.185 4.074 5 6.783 5 10v3.586l-1.707 1.707A.996.996 0 0 0 3 16v2a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1v-2a.996.996 0 0 0-.293-.707L19 13.586zM19 17H5v-.586l1.707-1.707A.996.996 0 0 0 7 14v-4c0-2.757 2.243-5 5-5s5 2.243 5 5v4c0 .266.105.52.293.707L19 16.414V17zm-7 5a2.98 2.98 0 0 0 2.818-2H9.182A2.98 2.98 0 0 0 12 22z" />
                    </svg>
                    <span className=" pulse"></span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <div className="menu-header-content text-start border-bottom">
                      <div className="d-flex">
                        <h6 className="dropdown-title mb-1 tx-15 font-weight-semibold">
                          Notifications
                        </h6>
                        <span className="badge badge-pill badge-warning ms-auto my-auto float-end">
                          Mark All Read
                        </span>
                      </div>
                      <p className="dropdown-title-text subtext mb-0 op-6 pb-0 tx-12 ">
                        You have 4 unread Notifications
                      </p>
                    </div>
                    <Scrollbars style={{ height: 280 }}>
                      <div className="main-notification-list Notification-scroll">
                        <Dropdown.Item
                          className="d-flex p-3 border-bottom"
                          href={`${process.env.PUBLIC_URL}/pages/mail/mail`}
                        >
                          <div className="notifyimg bg-pink">
                            <i className="far fa-folder-open text-white"></i>
                          </div>
                          <div className="ms-3">
                            <h5 className="notification-label mb-1">
                              New files available
                            </h5>
                            <div className="notification-subtext">
                              10 hour ago
                            </div>
                          </div>
                          <div className="ms-auto">
                            <i className="las la-angle-right text-end text-muted"></i>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="d-flex p-3  border-bottom"
                          href={`${process.env.PUBLIC_URL}/pages/mail/mail`}
                        >
                          <div className="notifyimg bg-purple">
                            <i className="fab fa-delicious text-white"></i>
                          </div>
                          <div className="ms-3">
                            <h5 className="notification-label mb-1">
                              Updates Available
                            </h5>
                            <div className="notification-subtext">
                              2 days ago
                            </div>
                          </div>
                          <div className="ms-auto">
                            <i className="las la-angle-right text-end text-muted"></i>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="d-flex p-3 border-bottom"
                          href={`${process.env.PUBLIC_URL}/pages/mail/mail`}
                        >
                          <div className="notifyimg bg-success">
                            <i className="fa fa-cart-plus text-white"></i>
                          </div>
                          <div className="ms-3">
                            <h5 className="notification-label mb-1">
                              New Order Received
                            </h5>
                            <div className="notification-subtext">
                              1 hour ago
                            </div>
                          </div>
                          <div className="ms-auto">
                            <i className="las la-angle-right text-end text-muted"></i>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="d-flex p-3 border-bottom"
                          to={`${process.env.PUBLIC_URL}/pages/mail/mail`}
                        >
                          <div className="notifyimg bg-warning">
                            <i className="far fa-envelope-open text-white"></i>
                          </div>
                          <div className="ms-3">
                            <h5 className="notification-label mb-1">
                              New review received
                            </h5>
                            <div className="notification-subtext">
                              1 day ago
                            </div>
                          </div>
                          <div className="ms-auto">
                            <i className="las la-angle-right text-end text-muted"></i>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="d-flex p-3 border-bottom"
                          to={`${process.env.PUBLIC_URL}/pages/mail/mail`}
                        >
                          <div className="notifyimg bg-danger">
                            <i className="fab fa-wpforms text-white"></i>
                          </div>
                          <div className="ms-3">
                            <h5 className="notification-label mb-1">
                              22 verified registrations
                            </h5>
                            <div className="notification-subtext">
                              2 hour ago
                            </div>
                          </div>
                          <div className="ms-auto">
                            <i className="las la-angle-right text-end text-muted"></i>
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                          className="d-flex p-3 border-bottom"
                          to={`${process.env.PUBLIC_URL}/pages/mail/mail`}
                        >
                          <div className="">
                            <i className="far fa-check-square text-white notifyimg bg-success"></i>
                          </div>
                          <div className="ms-3">
                            <h5 className="notification-label mb-1">
                              Project has been approved
                            </h5>
                            <span className="notification-subtext">
                              4 hour ago
                            </span>
                          </div>
                          <div className="ms-auto">
                            <i className="las la-angle-right text-end text-muted"></i>
                          </div>
                        </Dropdown.Item>
                      </div>
                    </Scrollbars>
                    <div className="dropdown-footer">
                      <Link
                        className="btn btn-primary btn-sm btn-block"
                        to={`${process.env.PUBLIC_URL}/pages/mail/mail`}
                      >
                        VIEW ALL
                      </Link>
                    </div>
                  </Dropdown.Menu>
                </Dropdown> */}
									<li
										className="nav-item full-screen fullscreen-button"
										onClick={() => Fullscreen(fullscreens)}
									>
										<Link
											className="new nav-link full-screen-link"
											to="#"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className="header-icon-svgs"
												width="24"
												height="24"
												viewBox="0 0 24 24"
											>
												<path d="M5 5h5V3H3v7h2zm5 14H5v-5H3v7h7zm11-5h-2v5h-5v2h7zm-2-4h2V3h-7v2h5z" />
											</svg>
										</Link>
									</li>
									{/* <li
                  className="dropdown main-header-message right-toggle"
                  onClick={() => Rightsidebar()}
                >
                  <Link
                    to="#"
                    className="new nav-link nav-link pe-0"
                    data-bs-toggle="sidebar-right"
                    data-bs-target=".sidebar-right"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="header-icon-svgs"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M4 6h16v2H4zm4 5h12v2H8zm5 5h7v2h-7z" />
                    </svg>
                  </Link>
                </li> */}
									{searchParams.get('token') === null && (
										<Dropdown className=" main-profile-menu nav nav-item nav-link ps-lg-2">
											<Dropdown.Toggle
												className="new nav-link profile-user d-flex"
												variant=""
											>
												<img
													alt=""
													src={require('../../assets/img/faces/2.jpg')}
													className=""
												/>
											</Dropdown.Toggle>
											<Dropdown.Menu>
												<div className="menu-header-content p-3 border-bottom">
													<div className="d-flex wd-100p">
														<div className="main-img-user">
															<img
																alt=""
																src={require('../../assets/img/faces/2.jpg')}
																className=""
															/>
														</div>
														<div className="ms-3 my-auto">
															<h6 className="tx-15 font-weight-semibold mb-0">
																{
																	userLogged?.email
																}
															</h6>
															<span className="dropdown-title-text subtext op-6  tx-12">
																{userLogged?.role ==
																'admin'
																	? 'Administrador'
																	: userLogged?.role ==
																	  'executive'
																	? 'Ejecutivo'
																	: 'Cliente'}
																{/* Usuario */}
															</span>
														</div>
													</div>
												</div>
												{/* <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/pages/profile`}>
                      <i className="far fa-user-circle"></i>Profile
                    </Dropdown.Item>
                    <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/pages/mail/chat`}>
                      <i className="far fa-smile"></i> chat
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown-item"
                      to={`${process.env.PUBLIC_URL}/pages/mail/readmail`}
                    >
                      <i className="far fa-envelope "></i>Inbox
                    </Dropdown.Item>
                    <Dropdown.Item className="dropdown-item" href={`${process.env.PUBLIC_URL}/pages/mail/mail`}>
                      <i className="far fa-comment-dots"></i>Messages
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown-item"
                      to={`${process.env.PUBLIC_URL}/pages/mail/mailsettings`}
                    >
                      <i className="far fa-sun"></i> Settings
                    </Dropdown.Item> */}
												<PopupClientChangePassword />
												<Dropdown.Item
													className="dropdown-item"
													onClick={() => {
														logout();
													}}
												>
													<i className="far fa-arrow-alt-circle-left"></i>{' '}
													Cerrar sesion
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									)}
								</ul>
							</Navbar.Collapse>
						</div>
					</div>
				</div>
			</Navbar>
		</React.Fragment>
	);
}

Header.propTypes = {};

Header.defaultProps = {};
