import { FC, useEffect, useState } from "react";
import {FaCloudDownloadAlt, FaClone, FaTools} from "react-icons/fa";

import "./PopupInternationalCloneOrder.css";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Form, Modal, FloatingLabel, Toast, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import getNationalClone from "services/getNationalClone";
import insertCloneNational, { insertCloneInternationalOrderBody } from "services/insertCloneNational";

var Spinner = require('react-spinkit');

export type PopupNationalCloneOrderProps = {
    orden:number
}

const PopupNationalCloneOrder: FC<PopupNationalCloneOrderProps> = ({ orden })=>{
    const queryClient = useQueryClient();
    const [show, setShow] = useState(false);
    const { status, data, error, isFetching, isPreviousData, isLoading } = useQuery(
        [ 'naltional-clone', orden ],
        () => getNationalClone(orden.toString()) ,
        { refetchOnWindowFocus:false, enabled:show },
    )

    const { register, handleSubmit, watch, formState: { errors } } = useForm<insertCloneInternationalOrderBody>({});

    const { mutate, isLoading:isLoadingInsert } = useMutation((body:insertCloneInternationalOrderBody) => insertCloneNational(body, orden.toString()), {
        onSuccess: data => {
            toast.success(`Clonacion exitosa, order nro. ${data}`);
            setShow(false);
        },
        onError: () => {
            toast.error('Ocurrio un error');
        },
        onSettled: () => {
            // queryClient.invalidateQueries(['tracking-table-data'])
        }
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const onSubmit = (data:any) => mutate(data);

    return (
    <>
        <Button variant="primary" onClick={handleShow} style={{width:"100%"}}>
            <FaClone/> Clonacion de orden
        </Button>

        <Modal show={show} onHide={handleClose} dialogClassName="modal-xl" centered={true}>
            <Modal.Header closeButton>
                <Modal.Title>Clonacion de orden</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { isLoading || isLoadingInsert ?
                    <span className='d-flex justify-content-center'><Spinner name='wandering-cubes' /></span> :
                    <div className='content'>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Orden de flete">
                                            <Form.Control type="text" placeholder="Orden de flete" readOnly disabled value={orden}/>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Via transporte">
                                            <Form.Control type="text" placeholder="Via transporte" readOnly disabled defaultValue={'Nacional'}/>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Pais Origen">
                                            <Form.Control type="text" placeholder="Pais Origen" readOnly disabled value={data?.pais_origen}/>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Servicio">
                                            <Form.Control type="text" placeholder="Servicio" readOnly disabled value={data?.nombre_tiposervicio}/>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Cliente">
                                            <Form.Control type="text" placeholder="Cliente" readOnly disabled value={ data?.razon_social }/>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Referencia cliente">
                                            <Form.Control type="text" placeholder="Referencia cliente" {...register("referencia_cliente")} readOnly disabled value={ data?.ref_clie }/>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Direccion de retiro">
                                            <Form.Control type="text" placeholder="Direccion de retiro" readOnly disabled value={ data?.direccion1 }/>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Direccion de Entrega">
                                            <Form.Control type="text" placeholder="Direccion de Entrega" readOnly disabled value={ data?.direccion2 }/>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Peso">
                                            <Form.Control type="text" placeholder="Peso" {...register("peso", { required: 'Peso requerido' })} defaultValue={ data?.peso_carga }/>
                                        </FloatingLabel>
                                        { errors.peso && <Form.Text className="text-danger">{ errors.peso.message }</Form.Text> }
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Volumen">
                                            <Form.Control type="text" placeholder="Volumen" {...register("volumen", { required: 'Volumen requerido' })} defaultValue={ data?.volumen_carga }/>
                                        </FloatingLabel>
                                        { errors.volumen && <Form.Text className="text-danger">{ errors.volumen.message }</Form.Text> }
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Numero de bultos">
                                            <Form.Control type="text" placeholder="Numero de bultos" {...register("bultos", { required: 'Numero de bultos requerido' })} defaultValue={ data?.numero_bultos }/>
                                        </FloatingLabel>
                                        { errors.bultos && <Form.Text className="text-danger">{ errors.bultos.message }</Form.Text> }
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Fecha de prevision de Recogida">
                                            <Form.Control type="date" placeholder="Fecha de prevision de Recogida" {...register("prevision_recogida", { required: 'Fecha de prevision de recogida requerido' })}/>
                                        </FloatingLabel>
                                        { errors.prevision_recogida && <Form.Text className="text-danger">{ errors.prevision_recogida.message }</Form.Text> }
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Fecha de prevision de entrega al cliente">
                                            <Form.Control type="date" placeholder="Fecha de prevision de entrega al cliente" {...register("prevision_entrega", { required: '"Fecha de prevision de entrega al cliente requerido' })}/>
                                        </FloatingLabel>
                                        { errors.prevision_entrega && <Form.Text className="text-danger">{ errors.prevision_entrega.message }</Form.Text> }
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Motonave">
                                            <Form.Control type="text" placeholder="Motonave" {...register("motonave")} defaultValue={ data?.motonave  } />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Descripcion carga">
                                            <Form.Control type="text" placeholder="Descripcion_carga" {...register("descripcion_carga", { required: 'Descripcion carga requerido' })} defaultValue={ data?.descripcion_carga }/>
                                        </FloatingLabel>
                                        { errors.descripcion_carga && <Form.Text className="text-danger">{ errors.descripcion_carga.message }</Form.Text> }
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={4}>
                                    <Form.Group>
                                        <FloatingLabel label="Detalles del valor de flete">
                                            <Form.Control type="text" placeholder="Detalles del valor de flete" {...register("detalle_flete")} defaultValue={ data?.valor_flete }/>
                                        </FloatingLabel>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="justify-content-md-end">
                                <Col sm={1}>
                                    <Button 
                                        type="submit"
                                        variant="primary" 
                                    >
                                    Clonar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                }
            </Modal.Body>
        </Modal>
    </>);
}

export default PopupNationalCloneOrder;