import { useMutation } from '@tanstack/react-query';
import { useMemo } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
	Routes,
	Route,
	useParams,
	Link,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import updateUserPassword, {
	updateUserPasswordRequest,
} from 'services/updateUserPassword';
import { decode } from 'js-base64';

type ChangePasswordForm = {
	password: string;
	conf_password: string;
};

function useQuery() {
	const { search } = useLocation();
	return useMemo(() => new URLSearchParams(search), [search]);
}

const ChangePassPage = () => {
	const query = useQuery();
	const navigate = useNavigate();

	const {
		watch,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ChangePasswordForm>({
		defaultValues: {
			password: '',
			conf_password: '',
		},
	});

	const { error, isError, mutate, isLoading } = useMutation<
		void,
		Error,
		updateUserPasswordRequest
	>(
		(body: updateUserPasswordRequest) =>
			updateUserPassword(body, decode(query.get('token') ?? '')),
		{
			onSuccess: () => {
				toast.success('Cambio de contraseña exitoso');
				navigate('login');
			},
		}
	);
	const onSubmit = (data: ChangePasswordForm) =>
		mutate({
			password: data.password,
		});
	return (
		<div>
			<div className="page-login">
				<div className="page-single">
					<div className="container">
						<Row>
							<Col
								xl={5}
								lg={6}
								md={8}
								sm={8}
								xs={10}
								className="card-sigin-main mx-auto my-auto py-4 justify-content-center"
							>
								<div className="card-sigin">
									<div className="main-card-signin d-md-flex">
										<div className="wd-100p">
											<div className="d-flex mb-4">
												<Link
													to={`${process.env.PUBLIC_URL}/login`}
												>
													<img
														src={require('../../assets/img/logo.png')}
														className="sign-favicon ht-40"
														alt="logo"
													/>
												</Link>
											</div>
											<div className="">
												<div className="main-signup-header">
													<h2>Bienvenidos</h2>
													<h6 className="font-weight-semibold">
														Ingresa para continuar.
													</h6>
													<div className="panel panel-primary">
														<div className="tab-menu-heading mb-2 border-bottom-0">
															<div className="tabs-menu1">
																{isError && (
																	//   <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>
																	<Alert variant="danger">
																		<Alert.Heading>
																			Tenemos
																			un
																			problema!
																		</Alert.Heading>
																		<p>
																			{
																				error.message
																			}
																		</p>
																	</Alert>
																)}
																<div
																	className="panel-body tabs-menu-body border-0 p-3"
																	id="tab5"
																>
																	<Form
																		onSubmit={handleSubmit(
																			onSubmit
																		)}
																	>
																		<Form.Group className="form-group">
																			<Form.Label>
																				Contraseña
																			</Form.Label>{' '}
																			<Form.Control
																				{...register(
																					'password',
																					{
																						required:
																							'Contraseña requerida',
																						minLength:
																							{
																								value: 6,
																								message:
																									'La longitud minima son 6 caracteres',
																							},
																						maxLength:
																							{
																								value: 10,
																								message:
																									'La longitud maxima son 10 caracteres',
																							},
																					}
																				)}
																				className="form-control"
																				placeholder="Contraseña"
																				type="password"
																			/>
																			{errors.password && (
																				<Form.Text className="text-danger">
																					{
																						errors
																							.password
																							.message
																					}
																				</Form.Text>
																			)}
																		</Form.Group>
																		<Form.Group className="form-group">
																			<Form.Label>
																				Confirmar
																				contraseña
																			</Form.Label>{' '}
																			<Form.Control
																				{...register(
																					'conf_password',
																					{
																						required:
																							'Confirmar contraseña requerida',
																						validate:
																							(
																								val: string
																							) => {
																								if (
																									watch(
																										'password'
																									) !=
																									val
																								) {
																									return 'Las contraseñas no coinciden';
																								}
																							},
																					}
																				)}
																				className="form-control"
																				placeholder="Confirmar contraseña"
																				type="password"
																			/>
																			{errors.conf_password && (
																				<Form.Text className="text-danger">
																					{
																						errors
																							.conf_password
																							.message
																					}
																				</Form.Text>
																			)}
																		</Form.Group>
																		<Button
																			disabled={
																				isLoading
																			}
																			type="submit"
																			className="btn btn-primaryLogin btn-block"
																		>
																			{isLoading
																				? 'Cargando…'
																				: 'Entrar'}
																		</Button>
																	</Form>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChangePassPage;
