import { FC, useState } from 'react';
import { FaAngleRight, FaSearch, FaSistrix } from 'react-icons/fa';

// import "./PopupAudit.css";
import { Button, Modal, Row, Container } from 'react-bootstrap';
import { useQuery } from '@tanstack/react-query';
import getAuditByUser, { audit } from 'services/getAuditByUser';
import moment from 'moment';
import { NoData } from 'components/NoData/NoData';

var Spinner = require('react-spinkit');

export type PopupAuditProps = {
	userID: string;
};

type ModalBodyProps = {
	data: audit;
};
const ModalBody = ({ data }: ModalBodyProps) => {
	return (
		<>
			<Container fluid>
				<Row>
					<FaAngleRight style={{ alignSelf: 'center' }} />
					<div>
						<div className="font-weight-bold">
							{/* {moment(data.timestamp).format('DD-MM-YYYY HH:mm:ss')} */}
							{moment
								.utc(data.timestamp)
								.local()
								.format('DD-MM-YYYY HH:mm:ss')}
						</div>
						<div>
							{`${
								data.executor != data.target
									? data.target_name
									: ''
							} ${data.message} ${data.executor_name}`}
						</div>
					</div>
				</Row>
				<hr />
			</Container>
		</>
	);
};

const PopupAudit: FC<PopupAuditProps> = ({ userID }) => {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const { status, data, error, isFetching, isPreviousData, isLoading } =
		useQuery(['audit', userID], () => getAuditByUser(userID), {
			refetchOnWindowFocus: false,
			enabled: show,
		});

	const dataOrder = (data ?? []).sort(
		(a, b) => Number(new Date(b.timestamp)) - Number(new Date(a.timestamp))
	);
	// console.log((data ?? []).map((x) => Number(new Date(x.timestamp))));
	// console.log(
	// 	(data ?? []).sort(
	// 		(a, b) =>
	// 			Number(new Date(b.timestamp)) - Number(new Date(a.timestamp))
	// 	)
	// );
	return (
		<>
			<Button
				variant="primary"
				onClick={handleShow}
				style={{ width: '100%' }}
			>
				<FaSearch />
				Auditoria
			</Button>

			<Modal
				show={show}
				onHide={handleClose}
				centered={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						Lista de eventos realizados por el usuario
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{isLoading ? (
						<span className="d-flex justify-content-center">
							<Spinner name="wandering-cubes" />
						</span>
					) : dataOrder.length < 1 ? (
						<NoData />
					) : (
						dataOrder.map((item) => (
							<ModalBody
								key={item.id}
								data={item}
							/>
						))
					)}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default PopupAudit;
