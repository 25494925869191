import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
	CellContext,
	PaginationState,
	SortingState,
} from '@tanstack/react-table';
import { DatePicker } from 'components/DatePicker/DatePicker';
import { ExecutiveSelect } from 'components/ExecutiveSelect/ExecutiveSelect';
import { CountrySelect } from 'components/PopupCreateExecutive/components/CountrySelect/CountrySelect';
import { BasicTable } from 'components/Table/BasicTable';
import TableV2 from 'components/TableV2/TableV2';
import { useAuth } from 'hooks/useAuth';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import getUser, { user } from 'services/getUser';
import Tooltip from 'components/Tooltip/Tooltip';
import EnableExecutive from 'components/PopupUpdateExecutive/EnableExecutive';
import getReviewQuestions from 'services/getReviewQuestions';
import questionsTableColumDef from './questionsTableColumDef';
import PopupCreateQuestion from './PopupCreateQuestion';
interface filter {
	email: string;
	startDate: string;
	endDate: string;
	country: string;
	executives: string[];
}

const QuestionsPage = () => {
	const { userLogged } = useAuth();
	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});

	const [sorting, setSorting] = useState<SortingState>([]);
	const defaultData = useMemo(() => [], []);

	const { status, data, error, isFetching, isLoading } = useQuery(
		['review-questions'],
		() => getReviewQuestions(),
		{ keepPreviousData: true, staleTime: 5000, refetchOnWindowFocus: false }
	);

	const columns = useMemo(() => questionsTableColumDef, []);
	const filterData = data ?? [];

	return (
		<>
			<Container fluid></Container>
			<TableV2
				header={
					<Col>
						<PopupCreateQuestion />
					</Col>
				}
				registerCount={filterData.length}
				data={filterData}
				columns={columns}
				loading={false}
				pagination={{
					pageIndex,
					pageSize,
				}}
				pageCount={0}
			/>
		</>
	);
};

export default QuestionsPage;
