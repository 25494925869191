import instance from "helpers/interceptor";

export type audit = {
    id: number;
    executor: number;
    executor_name: string;
    target: number;
    target_name: string;
    message: string;
    timestamp: Date;
}

const execute = (userID:string) : Promise<audit[]> => {

    return instance.get(`users/${userID}/events`)
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;