import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CountrySelect } from "components/PopupCreateExecutive/components/CountrySelect/CountrySelect";
import { useState } from "react";
import { Alert, Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { FaPen } from "react-icons/fa";
import { executive } from "services/getExecutiveUser";
import updateExecutive, { updateExecutiveRequest } from "services/updateExecutive";


export type popupUpdateExecutiveProps = {
    executive:executive
}

const PopupUpdateExecutive = ({ executive }:popupUpdateExecutiveProps)=>{
    const queryClient = useQueryClient();
    const [show, setShow] = useState(false);
    const defaultValues:updateExecutiveRequest = {
        email:executive.email,
        name:executive.name,
        country_id:executive.country_id,
    }

    const { reset:resetForm, control, register, handleSubmit, formState: { errors } } = useForm<updateExecutiveRequest>({
        defaultValues: defaultValues
    });

    const { 
        error: updateError,
        isError: updateIsError,
        mutate, 
        isLoading: creating,
    } = useMutation<void, Error, updateExecutiveRequest>((body:updateExecutiveRequest) => updateExecutive(body), {
        onSuccess: () => {
            toast.success('Actualizacion de usuario exitosa');
            setShow(false);
        },
        onError: () => {
            toast.error('Ocurrio durante la Actualizacion del usuario');
        },
        onSettled: (data, error, variables, context) => {
            queryClient.invalidateQueries(['executive-data'])
        },
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const onSubmit = (submitData:updateExecutiveRequest) => mutate(submitData);

    return (
        <>
            <Button variant="primary" onClick={handleShow} style={{width:"100%"}}>
                <FaPen /> Edicion de ejecutivos
            </Button>
            <Modal show={show} onHide={handleClose} dialogClassName="modal-xl" centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Edicion de ejecutivos</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col sm={6}>
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control type="text" placeholder="Nombre" {...register('name', { required:'Nombre requerido' })}/>
                                { errors.name && <Form.Text className="text-danger">{ errors.name.message }</Form.Text> }
                            </Col>
                            <Col sm={6}>
                                <Form.Label>Pais</Form.Label>
                                <Controller
                                        control={control}
                                        name="country_id"
                                        rules={{
                                            required: "Pais requerido",
                                        }}
                                        render={
                                            ({ field: { onChange, value, ref, name } }) => (
                                            <CountrySelect 
                                                name={name}
                                                placeholder="Pais"
                                                ref={ref}
                                                value={ value }
                                                onChange={ (val:{ value: string | undefined }) => {
                                                    onChange(val.value) 
                                                }}
                                            />
                                            )
                                        }
                                    />
                                { errors.country_id && <Form.Text className="text-danger">{ errors.country_id.message }</Form.Text> }
                            </Col>
                        </Row>
                        {(updateIsError) &&
                            (<Alert variant='danger'>
                                { updateError.message }
                            </Alert>)
                        }
                        <Row className="justify-content-md-end">
                            <Col sm={1}>
                                <Button 
                                    type="submit"
                                    variant="primary" 
                                >
                                    Actualizar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>);
}

export default PopupUpdateExecutive;