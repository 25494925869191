import HomePage from "pages/home/HomePage";
import NationalPage from "pages/national/NationalPage";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import 'react-tabs/style/react-tabs.css';
import {useAuth} from "../../hooks/useAuth";
import Switcherheader from "../../shade/Header/Switcherheader"
import { Outlet, useOutletContext } from "react-router-dom"
import Sidebar from "../layout/Sidebar/Sidebar";
import Footer from "../layout/Footer/Footer";
import Switcher from "../layout/Switcher/Switcher";
import RightSidebar from "../layout/RightSidebar/RightSidebar";
import TabToTop from "../layout/TabToTop/TabToTop";
import Loader from "shade/Loaders/Loaders";

function LayoutPage(){

	document.querySelector("body")?.classList.add("ltr", "main-body", "app", "sidebar-mini");
	document.querySelector("body")?.classList.remove("error-page1", "bg-primary");

	const responsiveSidebarclose = () => {
	  //leftsidemenu
	  if (window.innerWidth < 992){
	  document.querySelector(".app")?.classList.remove("sidenav-toggled");
	}
	  //rightsidebar
	  document.querySelector(".sidebar-right")?.classList.remove("sidebar-open");
	  //swichermainright
	  document.querySelector(".demo_changer")?.classList.remove("active");
	  let Rightside: any = document.querySelector(".demo_changer")
	  Rightside.style.right = "-270px";
	};
	function Sidebargone(gone:any) {
	  if (gone.matches) { // If media query matches
		document.querySelector("body")?.classList.add("sidebar-gone");
	  } else {
		document.querySelector("body")?.classList.remove("sidebar-gone");
		document.querySelector("body")?.classList.remove("sidenav-toggled");
	  }
	}

	var gone = window.matchMedia("(max-width: 1024px)")
	Sidebargone(gone) // Call listener function at run time
	gone.addListener(Sidebargone)

	// return <Loader/>;

  return (
    <div className="horizontalMenucontainer">
			<TabToTop />
			<div className="page">
				<div className="open">
					<Switcherheader />
					<Sidebar />
				</div>
				<div className="main-content app-content">
					<div className="side-app">
						<div className="main-container container-fluid" onClick={()=> {
							responsiveSidebarclose();
							}}
							>
							<Outlet />
						</div>
					</div>
				</div>
				<RightSidebar />
				<Switcher />
				<Footer />
			</div>
		</div>
  );
}

export default LayoutPage;