import instance from 'helpers/interceptor';
import TokenService from './tokenService';

export type user = {
	email: string;
	id: string;
	country_id: number;
	created_by: number;
	empresas: number;
	last_login: Date | null;
	created_at: Date;
	name: string;
	country_name: string;
	created_by_name: string;
	enable: number;
};

export type getUserResponse = Array<user>;

const execute = (): Promise<getUserResponse> => {
	return instance
		.get('/users')
		.then((response) => response.data)
		.catch((response) => {
			throw new Error(response.response.data.error);
		});
};

export default execute;
