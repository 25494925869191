import instance from "helpers/interceptor";

export type permission = {
    id: number;
    name: string;
    description: string;
    active: boolean;
}

const execute = (userID:string) : Promise<permission[]> => {

    return instance.get(`users/${userID}/permissions`)
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;