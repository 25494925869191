import { ReactElement } from "react";
import { OverlayTrigger, Tooltip as Toolt } from "react-bootstrap";

export type Tooltipprops = {
    children:ReactElement;
    text:string
    placement?: 'top'| 'right'| 'bottom'| 'left'
}

function Tooltip({ children, text, placement }:Tooltipprops) {
    const renderTooltip = (props:any) => (
      <Toolt {...props}>
        { text }
      </Toolt>
    );
  
    return (
      <OverlayTrigger
        placement={ placement !== undefined ? placement : 'top' }
        delay={{ show: 250, hide: 250 }}
        overlay={renderTooltip}
      >
        { children }
        {/* <Button variant="success">Hover me to see</Button> */}
      </OverlayTrigger>
    );
  }
  
export default Tooltip;