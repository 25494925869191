import { useQuery } from '@tanstack/react-query';
import Select from 'react-select';
import getOriginCountries from 'services/getOriginCountries';
import getDestineCountries from 'services/getDestineCountries';

export type CountrySelectProps = {
	onChange?: (e: { value: string | undefined }) => void;
	placeholder: string;
};

export const OriginCountrySelect = ({
	onChange,
	placeholder,
}: CountrySelectProps) => {
	const { status, data, error, isFetching, isPreviousData, isLoading } =
		useQuery(['origin-countries-data'], () => getOriginCountries(), {
			refetchOnWindowFocus: false,
		});

	const options =
		data === undefined
			? []
			: data.map((item) => ({
					label: item.country_name,
					value: item.id_country.toString(),
			  }));

	return (
		<Select
			classNamePrefix="react-select"
			placeholder={placeholder}
			options={options}
			isMulti
			onChange={(newValue) => {
				if (onChange != undefined) {
					onChange({ value: newValue.map((i) => i.value).join(',') });
				}
			}}
		/>
	);
};

export const DestineCountrySelect = ({
	onChange,
	placeholder,
}: CountrySelectProps) => {
	const { status, data, error, isFetching, isPreviousData, isLoading } =
		useQuery(['destine-countries-data'], () => getDestineCountries(), {
			refetchOnWindowFocus: false,
		});

	const options =
		data === undefined
			? []
			: data.map((item) => ({
					label: item.country_name,
					value: item.id_country.toString(),
			  }));

	return (
		<Select
			classNamePrefix="react-select"
			placeholder={placeholder}
			options={options}
			isMulti
			onChange={(newValue) => {
				if (onChange != undefined) {
					onChange({ value: newValue.map((i) => i.value).join(',') });
				}
			}}
		/>
	);
};
