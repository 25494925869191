import { FC, useEffect, useState } from 'react';
import { FaPlus } from 'react-icons/fa';

import {
	Button,
	Form,
	Modal,
	FloatingLabel,
	Alert,
	Row,
	Col,
	Container,
	InputGroup,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import validateUser, { UserValidated } from 'services/validateUser';
import Select from 'react-select';
import { json } from 'stream/consumers';
import { createUserRequest } from 'types/createUserRequest';
import createUser from 'services/createUser';
import createQuestion, { createQuestionRequest } from 'services/createQuestion';

var Spinner = require('react-spinkit');

export type PopupCreateQuestionProps = {};

const PopupCreateQuestion: FC<PopupCreateQuestionProps> = ({}) => {
	const queryClient = useQueryClient();
	const [show, setShow] = useState(false);
	const defaultValues: createQuestionRequest = {
		transport_way: '',
		question: '',
	};

	const {
		reset,
		register,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<createQuestionRequest>({
		defaultValues: defaultValues,
	});

	const {
		error: createError,
		isError: createIsError,
		mutate,
		reset: resetCreation,
		isLoading: creating,
	} = useMutation<void, Error, createQuestionRequest>(
		(request: createQuestionRequest) => createQuestion(request),
		{
			onSuccess: () => {
				toast.success('Creación exitosa');
				setShow(false);
			},
			onError: () => {
				toast.error('Ocurrio durante la creacion');
			},
			onSettled: (data, error, variables, context) => {
				queryClient.invalidateQueries(['review-questions']);
			},
		}
	);

	useEffect(() => {
		if (!show) {
			reset(defaultValues);
			resetCreation();
		}
	}, [show]);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const onSubmit = (data: any) => mutate(data);

	const options = [
		{ value: 'international', label: 'Internacional' },
		{ value: 'national', label: 'Nacional' },
	];
	return (
		<>
			<Button
				variant="primary"
				onClick={handleShow}
			>
				<FaPlus /> Crear pregunta
			</Button>

			<Modal
				show={show}
				onHide={handleClose}
				centered={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>Creacion de preguntas</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form
						onSubmit={handleSubmit(onSubmit)}
						autoComplete="off"
					>
						<Row>
							<Form.Label>Pregunta</Form.Label>
							<InputGroup className="mb-3">
								<Form.Control
									type="text"
									placeholder="Pregunta"
									{...register('question', {
										required: 'Pregunta requerida',
									})}
								/>
							</InputGroup>
							{errors.question && (
								<Form.Text className="text-danger">
									{errors.question.message}
								</Form.Text>
							)}
						</Row>
						<Row>
							<Form.Group>
								<Form.Label>Tipo de flete</Form.Label>
								<Controller
									control={control}
									name="transport_way"
									rules={{
										required: 'Tipo de flete requerido',
									}}
									render={({
										field: { onChange, value, ref, name },
									}) => (
										<Select
											placeholder="Tipo de flete"
											name={name}
											options={options}
											ref={ref}
											value={options.filter(
												(c) => value == c.value
											)}
											onChange={(val) =>
												onChange(val?.value)
											}
										/>
									)}
								/>
							</Form.Group>
							{errors.transport_way && (
								<Form.Text className="text-danger">
									{errors.transport_way.message}
								</Form.Text>
							)}
						</Row>

						{createIsError && (
							<Row>
								<Alert variant="danger">
									{createError.message}
								</Alert>
							</Row>
						)}

						<Row className="justify-content-md-end">
							<Button
								type="submit"
								variant="primary"
							>
								Crear
							</Button>
						</Row>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default PopupCreateQuestion;
