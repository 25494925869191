import { Rating } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { DatePicker } from 'components/DatePicker/DatePicker';
import { ModeSelect } from 'components/ModeSelect/ModeSelect';
import { CountrySelect } from 'components/PopupCreateExecutive/components/CountrySelect/CountrySelect';
import { difference, range } from 'lodash';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import getReviewDashboard, {
	revewDashboardParams,
} from 'services/getReviewDashboard';
import IIndexable from 'types/IIndexable';
import './ReviewStats.css';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import PopupPreviewReview from './PopupPreviewReview';
ChartJS.register(ArcElement, Tooltip, Legend);

var Spinner = require('react-spinkit');

const modeDic: IIndexable = {
	Aereo: 'aerial',
	Maritimo: 'maritime',
	Terrestre: 'terrestrial',
	aerial: 'Aereo',
	terrestrial: 'Terrestre',
	maritime: 'Maritimo',
};

const ReviewStatsPage = () => {
	const [filter, setFilters] = useState<any>({
		from: '',
		until: '',
		countriesOrigin: '',
		type: '',
		countriesDestination: '',
		// review?: 1 | 2 | 3 | 4 | 5;
	});

	const dateFormat = (date: string) =>
		date !== null && date !== '' ? moment(date).format('YYYY-MM-DD') : date;
	const defaultRange = useMemo(
		() => ({ start: moment().subtract(1, 'y'), end: moment() }),
		[]
	);

	const params: revewDashboardParams = {
		page: 0,
		size: 1000,
		...filter,
		from: dateFormat(filter.from === '' ? defaultRange.start : filter.from),
		until: dateFormat(
			filter.until === '' ? defaultRange.end : filter.until
		),
	};
	const { data, isInitialLoading } = useQuery(
		['review', 'dashboard', params],
		() => getReviewDashboard(params),
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true,
		}
	);
	const statsDefault = useMemo(
		() => [
			{
				review: 5,
				total: 0,
			},
			{
				review: 4,
				total: 0,
			},
			{
				review: 3,
				total: 0,
			},
			{
				review: 2,
				total: 0,
			},
			{
				review: 1,
				total: 0,
			},
		],
		[]
	);

	const details = data === undefined || data.data.length < 1 ? [] : data.data;

	let stats =
		data === undefined || data.stats.length < 1 ? statsDefault : data.stats;
	stats = difference(
		range(1, 6),
		stats.map((x) => x.review)
	).reduce((acc, x) => [...acc, { review: x, total: 0 }], stats);

	const totalReviews = stats.reduce((acc, x) => Number(x.total) + acc, 0);

	if (isInitialLoading) {
		return (
			<Spinner
				className="main-loading"
				name="wandering-cubes"
			/>
		);
	}

	return (
		<Container>
			<Row>
				<Col sm={3}>
					<DatePicker
						startDate={
							filter.from !== ''
								? filter.from
								: defaultRange.start
						}
						endDate={
							filter.until !== ''
								? filter.until
								: defaultRange.end
						}
						placeholder="Fecha inicio - Fecha final"
						onChange={(e) => {
							setFilters((oldFilter: any) => {
								return {
									...oldFilter,
									from: e[0] == null ? '' : e[0],
									until: e[1] == null ? '' : e[1],
								};
							});
						}}
					/>
				</Col>
				<Col sm={3}>
					<CountrySelect
						allCountryOption
						placeholder="Pais de origen"
						value={
							filter.countriesOrigin === ''
								? '-1'
								: filter.countriesOrigin
						}
						onChange={(val: { value: string | undefined }) => {
							setFilters((prev: any) => ({
								...prev,
								countriesOrigin: val.value,
							}));
						}}
					/>
				</Col>
				<Col sm={3}>
					<CountrySelect
						allCountryOption
						placeholder="Pais de destino"
						value={
							filter.countriesDestination === ''
								? '-1'
								: filter.countriesDestination
						}
						onChange={(val: { value: string | undefined }) => {
							setFilters((prev: any) => ({
								...prev,
								countriesDestination: val.value,
							}));
						}}
					/>
				</Col>
				<Col sm={3}>
					<ModeSelect
						placeholder="Via de transporte"
						value={
							filter.type === undefined
								? []
								: filter.type
										.split(',')
										.filter((x: string) => x !== '')
										.map((x: string) => modeDic[x])
						}
						onChange={(val: { value: string | undefined }) => {
							setFilters((prev: any) => ({
								...prev,
								type:
									val.value !== undefined
										? val.value
												.split(',')
												.filter((x: string) => x !== '')
												.map((x: string) => modeDic[x])
												.join(',')
										: undefined,
							}));
						}}
					/>
				</Col>
			</Row>
			<Row>
				<Col sm={2}>
					<Card
						style={{ height: '100%', backgroundColor: 'lightgray' }}
					>
						<Button
							style={{
								background: 'inherit',
								color: 'inherit',
								height: '100%',
							}}
							onClick={() => {
								setFilters((prev: any) => ({
									...prev,
									review_points: undefined,
								}));
							}}
						>
							<Card.Body className="d-flex justify-content-center align-items-center">
								<Card.Title style={{ fontSize: '50px' }}>
									{totalReviews}
								</Card.Title>
							</Card.Body>
						</Button>
					</Card>
				</Col>
				<Col className="d-flex">
					{stats
						.sort((a, b) => Number(a.review) - Number(b.review))
						.map((x, i) => (
							<Button
								key={i}
								className="ms-1"
								style={{
									background: 'inherit',
									color: 'inherit',
								}}
								onClick={() => {
									setFilters((prev: any) => ({
										...prev,
										review_points: x.review,
									}));
								}}
							>
								<span className="review-card-col">
									<Rating
										readOnly
										size="medium"
										value={x.review}
										max={5}
									/>
									<Card className="review-card-height">
										<Card.Body className="d-flex justify-content-center align-items-center">
											<Card.Title
												style={{ fontSize: '50px' }}
											>
												{x.total}
											</Card.Title>
										</Card.Body>
									</Card>
								</span>
							</Button>
						))}
				</Col>
				<Col sm={2}>
					{stats
						.sort((a, b) => Number(b.review) - Number(a.review))
						.map((x, i) => (
							<span
								className="d-flex justify-content-between"
								key={i}
							>
								<h4>
									{Math.round(
										(x.total * 100) /
											(totalReviews > 0
												? totalReviews
												: 1)
									)}
									%
								</h4>
								<Rating
									readOnly
									size="medium"
									value={x.review}
									max={5}
								/>
							</span>
						))}
				</Col>
			</Row>
			<Row className="mt-2">
				<Col sm={3}>
					<Gauge
						value={data === undefined ? 0 : data.bars.csat}
						indicator_name="CSAT"
					/>
				</Col>
				<Col sm={3}>
					<Gauge
						value={data === undefined ? 0 : data.bars.ces}
						indicator_name="CES"
					/>
				</Col>
				{/* <Col sm={3}>
					<Card
						className="review-card-height"
						style={{
							width: 'fit-content',
							height: 'fit-content',
						}}
					>
						<Container>
							<Card.Title className="text-center">NPS</Card.Title>
						</Container>
						<Card.Body className="d-flex justify-content-center align-items-center"></Card.Body>
					</Card>
				</Col> */}
			</Row>
			<div>
				{details
					.sort(
						(a, b) =>
							Number(new Date(b.created_at)) -
							Number(new Date(a.created_at))
					)
					.map((x, i) => (
						<Card
							className={
								'mt-2 review-big-card-' +
								(x.review > 3 ? 'green' : 'red')
							}
							key={i}
						>
							<Card.Body>
								<Row className="justify-content-between">
									<Rating
										readOnly
										size="medium"
										value={x.review}
										max={5}
									/>
									<span>
										<span className="fw-bold">
											Orden de flete:{' '}
										</span>
										{x.ot}
									</span>
									{/* <span>Nro de tracking: 000245</span> */}
									{x.transport_way === 'national' && (
										<span>
											<span className="fw-bold">
												Referencia:{' '}
											</span>
											{x.reference}
										</span>
									)}
									{x.transport_way === 'international' && (
										<div>
											<div>
												<span className="fw-bold">
													Referencia importador:{' '}
												</span>
												{x.reference.split('_')[1]}
											</div>
											<div>
												<span className="fw-bold">
													Referencia exportador:{' '}
												</span>
												{x.reference.split('_')[0]}
											</div>
										</div>
									)}

									<span>
										<span className="fw-bold">
											Via de transporte:{' '}
										</span>
										{x.transport_type === 'aerial'
											? 'Aereo'
											: x.transport_type === 'terrestrial'
											? 'Terrestre'
											: 'Maritimo'}
									</span>
									<span>
										<span className="fw-bold">
											Fecha de evaluacion:{' '}
										</span>
										{moment(x.created_at).format(
											'DD/MM/YYYY'
										)}
									</span>
								</Row>
								<Row className="justify-content-between">
									<div>
										<span className="fw-bold">
											Remitente{' '}
										</span>
										<span>{' ' + x.sender}</span>
									</div>
									<div>
										<span>
											<span className="fw-bold">
												Pais origen:{' '}
											</span>
											{x.origin_country_name}
										</span>
									</div>
									<div>
										<span>
											<span className="fw-bold">
												Pais destino:{' '}
											</span>
											{x.destiny_country_name}
										</span>
									</div>
								</Row>
								<Row>
									<div>
										<span>
											<span className="fw-bold">
												Destinatario:{' '}
											</span>
											{x.addressee}
										</span>
									</div>
								</Row>
								<Row className="justify-content-between">
									<div>
										<span>
											<span className="fw-bold">
												Evaluado por:{' '}
											</span>
											{x.user_name + ' ' + x.user_email}
										</span>
									</div>
									<div>
										<PopupPreviewReview
											orden={x.ot.toString()}
											transportWay={x.transport_way}
											id_user={x.id_user}
										/>
									</div>
								</Row>
							</Card.Body>
						</Card>
					))}
			</div>
		</Container>
	);
};

const Gauge = ({
	value,
	indicator_name,
}: {
	value: number;
	indicator_name: string;
}) => {
	const data = {
		labels: ['Red', 'Orange', 'Green'],
		datasets: [
			{
				label: '# of Votes',
				data: [30, 40, 30],
				backgroundColor: [
					'rgba(231, 76, 60, 1)',
					'rgba(255, 164, 46, 1)',
					'rgba(46, 204, 113, 1)',
				],
				borderColor: [
					'rgba(255, 255, 255 ,1)',
					'rgba(255, 255, 255 ,1)',
					'rgba(255, 255, 255 ,1)',
				],
				borderWidth: 5,
			},
		],
	};
	return (
		<Card
			className="review-card-height"
			style={{
				width: 'fit-content',
				height: 'fit-content',
			}}
		>
			<Container>
				<Card.Title className="text-center">
					{indicator_name}
				</Card.Title>
			</Container>
			<Card.Body className="d-flex justify-content-center align-items-center">
				<div className="outer">
					<Doughnut
						width={'inherit'}
						height={'inherit'}
						className="canvas"
						options={{
							rotation: -90,
							circumference: 180,
							plugins: {
								legend: {
									display: false,
								},
								tooltip: {
									enabled: false,
								},
							},
							cutout: '80%',
							maintainAspectRatio: false,
						}}
						data={data}
					/>
					<Doughnut
						width={'inherit'}
						height={'inherit'}
						className="canvas"
						options={{
							rotation: -90,
							circumference: 180,
							plugins: {
								legend: {
									display: false,
								},
								tooltip: {
									enabled: false,
								},
							},
							cutout: '80%',
							maintainAspectRatio: false,
						}}
						data={{
							labels: ['', 'Purple', ''],
							datasets: [
								{
									label: '# of Votes',
									data: [value, 2, 98 - value],
									backgroundColor: [
										'rgba(0,0,0,0)',
										'rgba(255,255,255,1)',
										'rgba(0,0,0,0)',
									],
									borderColor: [
										'rgba(0, 0, 0 ,0)',
										'rgba(46, 204, 113, 1)',
										'rgba(0, 0, 0 ,0)',
									],
									borderWidth: 3,
								},
							],
						}}
					/>
					<p className="percent">{Math.round(value)}</p>
				</div>
			</Card.Body>
		</Card>
	);
};

export default ReviewStatsPage;
