import instance from 'helpers/interceptor';
import IIndexable from 'types/IIndexable';

export interface revewDashboardParams {
	page: number;
	size: number;
	ot?: string;
	from?: string;
	until?: string;
	type?: 'aerial' | 'terrestrial' | 'maritime';
	way?: 'international' | 'national';
	countriesOrigin?: number;
	countriesDestination?: number;
	sender?: string;
	reference?: string;
	addressee?: string;
	orderBy?: 'ot DESC' | 'ot ASC';
	review_points?: 1 | 2 | 3 | 4 | 5;
}

export interface ReviewDetail {
	ot: string;
	id_user: number;
	review: number;
	transport_way: 'international' | 'national';
	reference: string;
	sender: string;
	addressee: string;
	transport_type: string;
	origin_country_id: number;
	destiny_country_id: number;
	created_at: string;
	user_name: string;
	user_email: string;
	destiny_country_name: string;
	origin_country_name: string;
}

export interface Stat {
	review: number;
	total: number;
}

export interface Bars {
	csat: number;
	ces: number;
	nps: number;
}

export interface Pagination {
	registerCount: number;
	numberOfPages: number;
	hasMore: boolean;
}

export interface ReviewDashboardResponse {
	data: ReviewDetail[];
	stats: Stat[];
	bars: Bars;
	pagination: Pagination;
}

const execute = (
	params: revewDashboardParams
): Promise<ReviewDashboardResponse> => {
	let paramsWitoutNulls = Object.entries(params).reduce(
		(acc, [key, value]) => {
			if (value !== null && value !== '' && value !== undefined) {
				(acc as IIndexable)[key] = value;
			}
			return acc;
		},
		{}
	);

	return instance
		.get(`/questions_dashboard`, { params: paramsWitoutNulls })
		.then((response) => response.data)
		.catch((response) => {
			throw new Error(response.response.data.error);
		});
};

export default execute;
