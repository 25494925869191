import instance from "helpers/interceptor";
import { clientListItem } from "types/clientListItem";
import TokenService from "./tokenService";

const execute = () : Promise<clientListItem[]> => {
    return instance.get('/international-orders/clientReceivers')
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;

