import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import Select from 'react-select';
import getServices from 'services/getServices';

export type LastStatusSelectProps = {
	onChange?: (e: { value: string | undefined }) => void;
	placeholder: string;
};

export const LastStatusSelect = ({
	onChange,
	placeholder,
}: LastStatusSelectProps) => {
	let options = useMemo(
		() =>
			[
				'Entregado',
				'Liberado Aduana Destino',
				'Ingreso Aduana Destino',
				'Arribo Aduana Destino',
				'Salida Aduana Origen',
				'Liberacion Aduana Origen',
				'Arribo Aduana Origen',
				'Recogida en Origen',
				'Mercaderia Lista para el Embarque',
				'Orden Creada',
			].map((item) => ({
				label: item,
				value: item,
			})),
		[]
	);

	return (
		<Select
			classNamePrefix="react-select"
			placeholder={placeholder}
			options={options}
			isMulti
			onChange={(newValue) => {
				if (onChange != undefined) {
					onChange({ value: newValue.map((i) => i.value).join(',') });
				}
			}}
		/>
	);
};
