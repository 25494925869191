import { useQuery } from '@tanstack/react-query';
import Select from 'react-select';
import { forwardRef } from 'react';
import getExecutiveUser from 'services/getExecutiveUser';

export const ExecutiveSelect = forwardRef<any, any>(({ onChange, placeholder, value }, ref) => {
    const { status, data, error, isFetching, isPreviousData, isLoading } = useQuery(
        ['executive-data'],
        () => getExecutiveUser(),
        { refetchOnWindowFocus:false },
    )

    const options = data === undefined ? [] : data.map(item => ({
            label: item.email,
            value: item.id.toString()
    }));
      
    return (
        <Select 
            ref={ref}
            placeholder={placeholder}
            options={options} 
            value={ options.find(c => value == c.value) }
            onChange={(newValue) => {
                if(onChange != undefined){
                    onChange({ value:newValue?.value })
                }  
            }}
        />
    );
});