import instance from 'helpers/interceptor';
import TokenService from './tokenService';

interface Datum {
	ot: number;
	otDate: string;
	mode: string;
	sender: string;
	receiver: string;
	originCountry: string;
	destinationCountry: string;
	status: string;
	ref: string;
	event: string;
	customerName: string;
	service: string;
	etd: string;
	eta: string;

	refReceiver: string;
	refSender: string;

	mercaderiaListaParaEmbarque: string;
	recogida_en_origen: string;
	fecha_arribo_aduana_origen: string;
	fecha_liberacion_aduana_origen: string;
	fecha_ingreso_aduana_destino: string;
	fecha_liberacion_aduana_destino: string;
	fecha_entrega_final_cliente: string;
	ultimo_status: string;
	actions: string;
}

export type dashboardResponse = {
	register_count: {
		terrestre: number;
		aereo: number;
		maritimo: number;
		nacional: number;
	};
	recents_register: Datum[];
	enviados_desde: { pais_origen: string; cuenta: number }[];
	enviados_hasta: { pais_destino: string; cuenta: number }[];
};

export interface getDashashboardInfoRequest {
	since: string;
	until: string;
}

const execute = (
	request: getDashashboardInfoRequest
): Promise<dashboardResponse> => {
	return instance
		.get('/dashboard', { params: request })
		.then((response) => response.data)
		.catch((response) => {
			throw new Error(response.response.data.error);
		});
};

export default execute;
