import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './DatePicker.css';

export type DatePickerProps = {
    onChange:(e:[ Date|null, Date|null ]) => void
    placeholder:string,

    startDate?:Date,
    endDate?:Date,
    initialEmpty?:boolean
}

export const DatePicker = ({ onChange, placeholder, startDate, endDate, initialEmpty }:DatePickerProps) => {
    return (
    <DateRangePicker
        initialSettings={{
            // onEvent:handleEvent,
            // startDate: state.start ?? moment(),
            // endDate: state.end ?? moment(),
            startDate: startDate ?? moment(),
            endDate: endDate ?? moment(),

            alwaysShowCalendars: true,
            showCustomRangeLabel: false,
            autoUpdateInput: !initialEmpty,
            // autoUpdateInput: false,
            locale: {
                "format": "DD/MM/YYYY",
                "separator": " - ",
                "applyLabel": "Aplicar",
                "cancelLabel": "Limpiar",
                "fromLabel": "Desde",
                "toLabel": "Hasta",
                "customRangeLabel": "Personalizado",
                "weekLabel": "S",
                "daysOfWeek": [
                    "Do",
                    "Lu",
                    "Ma",
                    "Mi",
                    "Ju",
                    "Vi",
                    "Sa"
                ],
                "monthNames": [
                    "Enero",
                    "Febrero",
                    "Marzo",
                    "Abril",
                    "Mayo",
                    "Junio",
                    "Julio",
                    "Agosto",
                    "Septiembre",
                    "Octubre",
                    "Noviembre",
                    "Diciembre"
                ],
                "firstDay": 1
            },
            ranges: {
                'Hoy': [moment(), moment()],
                'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Ultimos 7 dias': [moment().subtract(6, 'days'), moment()],
                'Ultimos 30 dias': [moment().subtract(29, 'days'), moment()],
                'Este mes': [moment().startOf('month'), moment().endOf('month')],
                'Mes pasado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
            }}
            onApply={(event, picker) => {
                picker.element
                    .val(`${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format('DD/MM/YYYY')}`);

                onChange([picker.startDate, picker.endDate]);
            }}
            onCallback={(start, end, label) => {
                // onChange([start, end]);
            }}
            onCancel={(event, picker) => {
                picker.element.val('');
                onChange([null, null]);
            }}
        >
            <input type="text" className="form-control" placeholder={placeholder} />
    </DateRangePicker>);
}