import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// import { PaginationState, SortingState } from '@tanstack/react-table';
// import { BasicTable } from 'components/Table/BasicTable';
// import TableV2 from 'components/TableV2/TableV2';
import { useMemo, useState } from 'react';
import {
	Col,
	Container,
	Form,
	FormCheck,
	FormGroup,
	Row,
} from 'react-bootstrap';
import getCompanies, { companies } from 'services/getCompanies';
import updateCompanies, {
	updateCompanyRequest,
} from 'services/updateCompanies';
import toast from 'react-hot-toast';
import { useAuth } from 'hooks/useAuth';

var Spinner = require('react-spinkit');

const CompanieSwitch = ({
	data,
	onChange,
}: {
	data: companies;
	onChange: (value: string, status: boolean) => void;
}) => {
	const [state, setState] = useState(data.active);
	const { userLogged } = useAuth();

	return (
		<div className="eyes-content">
			<input
				className="switch"
				{...{
					disabled: userLogged?.role !== 'client',
					id: data.nombre_fantasia,
					type: 'checkbox',
					checked: state,
					onChange: (e) => {
						setState((prev) => !prev);
						onChange(data.rut_cliente, e.target.checked);
					},
				}}
			/>{' '}
			<label
				htmlFor={data.nombre_fantasia}
				className="switchLabel"
			>
				Toggle
			</label>
			<span>{data.nombre_fantasia}</span>
		</div>
	);

	// return (
	// 	<FormGroup className="form-group">
	// 		<Form.Label className="custom-switch ps-0">
	// 			<span className="custom-switch-description me-2">
	// 				{data.nombre_fantasia}
	// 			</span>
	// 			<Form.Control
	// 				readOnly={userLogged?.role !== 'client'}
	// 				id={data.nombre_fantasia}
	// 				type={'checkbox'}
	// 				checked={state}
	// 				onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
	// 					setState((prev) => !prev);
	// 					onChange(data.rut_cliente, e.target.checked);
	// 				}}
	// 			/>
	// 			<span className="custom-switch-indicator"></span>
	// 		</Form.Label>
	// 	</FormGroup>
	// );
};

const CompaniesPage = () => {
	const queryClient = useQueryClient();

	const { data, error, isFetching, isLoading } = useQuery(
		['companies-data'],
		() => getCompanies(),
		{ staleTime: 5000, refetchOnWindowFocus: false }
	);

	const { mutate } = useMutation({
		mutationFn: (body: {
			request: updateCompanyRequest;
			rut_client: string;
		}) => updateCompanies(body.request, body.rut_client),
		onSuccess: (data, variables) => {
			toast.success(
				`Compañia ${
					variables.request.status ? 'activada' : 'desactivada'
				} con exito`
			);
		},
		onError: (asd) => {
			toast.error('Ocurrio un error');
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['companies-data'] });
			queryClient.invalidateQueries({
				queryKey: ['tracking-international-table-data'],
			});
			queryClient.invalidateQueries({
				queryKey: ['tracking-national-table-data'],
			});
		},
	});

	const onChange = (rut: string, status: boolean) =>
		mutate({ rut_client: rut, request: { status: status } });

	if (isLoading) {
		return (
			<Spinner
				className="main-loading"
				name="wandering-cubes"
			/>
		);
	}

	return (
		<>
			<Container fluid>
				<div className="breadcrumb-header justify-content-between">
					<div className="left-content">
						<span className="main-content-title mg-b-0 mg-b-lg-1">
							Configuración de compañias
						</span>
					</div>
				</div>
				<Row className="row-container">
					{(data ?? []).map((item) => (
						<CompanieSwitch
							key={item.nombre_fantasia}
							data={item}
							onChange={onChange}
						/>
					))}
				</Row>
			</Container>
		</>
	);
};

export default CompaniesPage;
