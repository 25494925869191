import { Table } from "@tanstack/react-table";
import { FC, useState } from "react";
import { FaCalendar, FaArrowCircleRight, FaRegHandPointRight, FaMapMarkerAlt, FaCloudDownloadAlt } from "react-icons/fa";
import { useQuery } from "@tanstack/react-query";
import getEvents from "../../services/getEvents";
import { trackingType } from "types/trackingType";
import getNationalEvents from "services/getNationalEvents";
import './PopupEvent.css';
import { Button, Modal } from "react-bootstrap";
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet, Font, Svg, Path, Image, Rect, Line } from '@react-pdf/renderer';
import { eventsResponse } from "types/eventsResponse";
import Html from 'react-pdf-html';
import delpaPdfHeader from 'assets/img/delpa_pdf_header.png';
import orangeLine from 'assets/img/orange_line.png';
import delpaSeparador from 'assets/img/delpa_separador.png';

var Spinner = require('react-spinkit');

export type PopupEventProps = {
    orden:number
    type: trackingType
}


const ExportPdf = ({ data }: { data:eventsResponse[] }) => {
    const MySvg: any = Svg

    // Create styles
    Font.register({
        family: 'Oswald',
        src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
    });

    const styles = StyleSheet.create({
        body: {
            backgroundColor: '#eeeff0',
            paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: 35,
        },
        image: {
            marginTop: -35,
            marginBottom: 15,
            marginHorizontal: -35
        },
        // section: {
        //     margin: 10,
        //     padding: 10,
        //     flexGrow: 1
        // },
        title:{
            fontFamily: 'Oswald',
            fontSize: 12
        },
        text:{
            fontSize: 11,
            textAlign: 'justify',
            fontFamily: 'Times-Roman'
        }
    });

    
    return (
        <Document>
            <Page style={styles.body}>
            <Image
                style={styles.image}
                src={delpaPdfHeader}
            />
            {data?.map(event  => {
                return (
                    // <View style={styles.section}>
                    <View>
                        <View style={{ flexDirection: 'row', alignItems:'flex-start', height:'auto' }}> 
                            <View style={{ flexDirection: 'column', display:"flex", alignItems:"center" }}>
                                <MySvg width="20" height="20" viewBox="0 0 512 512">
                                    <Path
                                        d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"
                                        fill="#ef9019"
                                        strokeWidth={3}
                                    />
                                </MySvg>
                                {/* <Image
                                    style={{ width: '5px', height:'auto', marginTop:0 }}
                                    src={orangeLine}
                                /> */}
                            </View> 
                            <View style={{ marginHorizontal: 15 }}>
                                <Text style={styles.title}>{event.tipo} {event.fecha}</Text>
                                <Html style={styles.text}>{event.contenido}</Html>
                            </View> 
                        </View>
                        <Image
                            style={{ width: '100%', height:'auto', marginTop:15 }}
                            src={delpaSeparador}
                        />
                    </View>
                )
            })}
            </Page>
            </Document>
        )
};

const PopupEvent: FC<PopupEventProps> = ({ orden, type })=>{
    const [show, setShow] = useState(false);
    const { status, data, error, isFetching, isPreviousData, isLoading } = useQuery(
        [type == 'international' ? 'international-event' : 'national-event', orden],
        () => type == 'international' ? getEvents(orden.toString()) : getNationalEvents(orden.toString()),
        { refetchOnWindowFocus:false, enabled:show },
    )
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
    <>
        <Button variant="primary" onClick={handleShow} style={{width:"100%"}}>
            <FaCalendar/> Visualizar lista de eventos
        </Button>

        <Modal show={show} onHide={handleClose} dialogClassName="modal-xl" centered={true}>
            <Modal.Header closeButton>
                <Modal.Title>Lista de Eventos</Modal.Title>
                <PDFDownloadLink document={<ExportPdf data={data ?? []}/>} fileName={`${orden}.pdf`}>
                    {({ blob, url, loading:loadingExport, error: errorExport }) =>
                        // loadingExport ? <span>Loading document...</span> : <span>Download now!</span>
                        <Button className="ms-2" disabled={isLoading || loadingExport || data === undefined || data.length === 0} variant="primary"><FaCloudDownloadAlt />Exportar como pdf</Button>
                    }
                </PDFDownloadLink>
            </Modal.Header>
            <Modal.Body>
                {isLoading ?
                    <span className='d-flex justify-content-center'><Spinner name='wandering-cubes' /></span> :
                    <div className='content' id="content">
                    {data?.map(event  => {
                        return (
                            <div>
                                <div className="titleEvent" style={{display: "block"}}>
                                    <FaMapMarkerAlt/> <span>{event.tipo} {event.fecha}</span>
                                </div>
                                <div className="content" dangerouslySetInnerHTML={{__html: event.contenido}}>
                                </div>
                                <hr />
                            </div>
                        )
                    })}
                </div>
                }
            </Modal.Body>
        </Modal>
    </>);
}

export default PopupEvent;