import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from 'hooks/useAuth';
import { useState } from 'react';
import {
	Alert,
	Button,
	Col,
	Dropdown,
	Form,
	Modal,
	Row,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { FaKey } from 'react-icons/fa';
import { updateExecutiveRequest } from 'services/updateExecutive';
import updateUser from 'services/updateUser';
import updateUserPassword, {
	updateUserPasswordRequest,
} from 'services/updateUserPassword';

export type PopupClientChangePasswordProps = {};

const PopupClientChangePassword = ({}: PopupClientChangePasswordProps) => {
	const queryClient = useQueryClient();
	const [show, setShow] = useState(false);
	const defaultValues: updateUserPasswordRequest = {
		password: '',
	};

	const user = useAuth();

	const {
		reset: resetForm,
		register,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<updateUserPasswordRequest>({
		defaultValues: defaultValues,
	});

	const { error, isError, mutate, isLoading } = useMutation<
		void,
		Error,
		updateUserPasswordRequest,
		any
	>(
		(body: updateUserPasswordRequest) =>
			updateUserPassword(body, user.userLogged?.token ?? ''),
		{
			onMutate: (variables) => {
				var toastID = toast.loading(
					'Actualizando, por favor espere...'
				);
				return { toastID };
			},
			onSuccess: (data, variables) => {
				toast.success('Actualizacion de usuario exitosa');
				setShow(false);
			},
			onError: (err) => {
				toast.error('Ocurrio durante la Actualizacion del usuario');
			},
			onSettled: (data, err, variables, context) => {
				toast.dismiss(context?.toastID);
				// queryClient.invalidateQueries({ queryKey: ['permission', variables.userID] })
			},
		}
	);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const onSubmit = (submitData: updateUserPasswordRequest) =>
		mutate(submitData);

	return (
		<>
			<Dropdown.Item
				className="dropdown-item"
				onClick={handleShow}
			>
				<FaKey
					style={{
						fontSize: '17px',
						marginRight: '10px',
						width: '24px',
						textAlign: 'center',
					}}
				/>{' '}
				Cambio de contraseña
			</Dropdown.Item>

			<Modal
				show={show}
				onHide={handleClose}
				centered={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>Cambio de contraseña</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Row>
							<Col sm={12}>
								<Form.Label>Contraseña</Form.Label>
								<Form.Control
									type="password"
									placeholder="Contraseña"
									{...register('password', {
										required: 'Contraseña requerida',
									})}
								/>
								{errors.password && (
									<Form.Text className="text-danger">
										{errors.password.message}
									</Form.Text>
								)}
							</Col>
						</Row>
						{isError && (
							<Alert variant="danger">{error.message}</Alert>
						)}
						<Row className="justify-content-md-end">
							<Col
								style={{
									display: 'flex',
									justifyContent: 'end',
								}}
							>
								<Button
									type="submit"
									variant="primary"
								>
									Actualizar
								</Button>
							</Col>
						</Row>
					</Form>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default PopupClientChangePassword;
