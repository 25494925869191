import { useQuery } from '@tanstack/react-query';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { CountrySelect } from 'components/PopupCreateExecutive/components/CountrySelect/CountrySelect';
import TableV2 from 'components/TableV2/TableV2';
import { useMemo, useState } from 'react';
import { Card, Col, Container, Form, Row } from 'react-bootstrap';
import getExecutiveDashboard, {
	executiveDashboardRequest,
} from 'services/getExecutiveDashboard';
import getExecutiveUser from 'services/getExecutiveUser';
import UserTableColumDef from './components/ExecutiveTableColumDef';

import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	Colors,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { DatePicker } from 'components/DatePicker/DatePicker';
import { Prev } from 'react-bootstrap/esm/PageItem';
import moment, { Moment } from 'moment';
import toast from 'react-hot-toast';
import { NoData } from 'components/NoData/NoData';

ChartJS.register(ArcElement, Tooltip, Legend, Colors);
// interface filter{
//     email: string,
//     startDate: string,
//     endDate: string,
//     country: string
// }
var Spinner = require('react-spinkit');

const randomRgb = ({ opacity }: { opacity: number }) => {
	const r = Math.floor(Math.random() * 255);
	const b = Math.floor(Math.random() * 255);
	const g = Math.floor(Math.random() * 255);
	return `rgb(${r}, ${b}, ${g}, ${opacity})`;
};

const ExecutiveDashboardPage = () => {
	const [filter, setFilters] = useState<{ since: any; until: any }>({
		since: moment(),
		until: moment(),
	});

	const dateFormat = (date: string) =>
		date !== null && date !== '' ? moment(date).format('YYYY/MM/DD') : date;
	const fetchDataOptions: executiveDashboardRequest = {
		since: dateFormat(filter.since),
		until: dateFormat(filter.until),
	};
	const { data, error, isFetching, isInitialLoading } = useQuery({
		queryKey: ['executive-dashboard-data', fetchDataOptions],
		queryFn: async () => {
			if (!isInitialLoading) {
				toast.loading('Cargando...');
			}
			const data = await getExecutiveDashboard(fetchDataOptions);
			return data;
		},
		staleTime: 5000,
		refetchOnWindowFocus: false,
		keepPreviousData: true,
		onSuccess: (data) => {
			if (!isInitialLoading) {
				toast.dismiss();
				toast.success('Carga Exitosa');
			}
		},
		onError: () => {
			toast.dismiss();
			toast.error('Ocurrio un error');
		},
	});

	if (isInitialLoading) {
		return (
			<Spinner
				className="main-loading"
				name="wandering-cubes"
			/>
		);
	}

	const clientByExecutiveChartColors = (
		data ?? { clients_by_executive: [] }
	).clients_by_executive.map((x, _) => randomRgb({ opacity: 0.5 }));
	const clientByExecutiveChart = {
		labels: (data ?? { clients_by_executive: [] }).clients_by_executive.map(
			(x) => x.created_by_name
		),
		datasets: [
			{
				label: '# de clientes creados:',
				data: (
					data ?? { clients_by_executive: [] }
				).clients_by_executive.map((x) => x.creados),
				backgroundColor: clientByExecutiveChartColors,
				borderColor: clientByExecutiveChartColors,
				borderWidth: 1,
			},
		],
	};

	const clientByCountryChartColors = (
		data ?? { clients_by_country: [] }
	).clients_by_country.map((x, _) => randomRgb({ opacity: 0.5 }));
	const clientByCountryChart = {
		labels: (data ?? { clients_by_country: [] }).clients_by_country.map(
			(x) => x.country_name
		),
		datasets: [
			{
				label: '# de clientes creados:',
				data: (
					data ?? { clients_by_country: [] }
				).clients_by_country.map((x) => x.creados),
				backgroundColor: clientByCountryChartColors,
				borderColor: clientByCountryChartColors,
				borderWidth: 1,
			},
		],
	};

	return (
		<>
			<Container fluid>
				<Row>
					<Col sm={4}>
						<DatePicker
							startDate={filter.since}
							endDate={filter.until}
							placeholder="Fecha inicio - Fecha final"
							onChange={([start, end]) =>
								setFilters((prev: any) => ({
									...prev,
									since: start ?? '',
									until: end ?? '',
								}))
							}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={6}>
						<Card className="custom-card overflow-hidden">
							<Card.Header className=" border-bottom-0 d-flex">
								<h3 className="card-title mb-2 ">
									Clientes por ejecutivo
								</h3>
							</Card.Header>
							<Card.Body>
								{(data ?? { clients_by_executive: [] })
									.clients_by_executive.length < 1 ? (
									<NoData />
								) : (
									<Pie data={clientByExecutiveChart} />
								)}
							</Card.Body>
						</Card>
					</Col>
					<Col sm={6}>
						<Card className="custom-card overflow-hidden">
							<Card.Header className=" border-bottom-0 d-flex">
								<h3 className="card-title mb-2 ">
									Clientes por pais
								</h3>
							</Card.Header>
							<Card.Body>
								{(data ?? { clients_by_executive: [] })
									.clients_by_executive.length < 1 ? (
									<NoData />
								) : (
									<Pie data={clientByCountryChart} />
								)}
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default ExecutiveDashboardPage;
