import { Button } from "react-bootstrap"
import { useRecoilState } from "recoil";
import IIndexable from "types/IIndexable"
import { object } from "underscore";
import { FaFileExcel } from "react-icons/fa";
import * as XLSX from 'xlsx';
import { ColunmHeader as internationalHeader } from "pages/home/components/TrackingTableColumDef";
import { ColunmHeader as nationalHeader } from "pages/national/components/NationalTableColumDef";
import { useLocation } from "react-router-dom";
import excelInternationalDataAtom from "atoms/excelInternationalDataAtom";
import excelNationalDataAtom from "atoms/excelNationalDataAtom";

export type ExportExcelButton = {
    columOrder:Array<string>;
    columVisibility:IIndexable;
}

export const ExportExcelButton = (porps:ExportExcelButton)=>{
    const [excelInData,] = useRecoilState(excelInternationalDataAtom);
    const [excelNaData,] = useRecoilState(excelNationalDataAtom);
    const location = useLocation();
    const ColunmHeader = location.pathname === '/international/' ? internationalHeader : nationalHeader;
    const excelData = location.pathname === '/international/' ? excelInData : excelNaData;
    
    // const param:Params = { ...filter, page:'0', size:'1000'};
    // const { data, error, isFetching, refetch } = useQuery(
    //     ['tracking-excel-data', param],
    //     () => getDataTraking(param),
    //     { enabled:true },
    // )

    const downloadExcel = (data:any) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "tracking.xlsx");
    };

    const handleClick = () => {
        if(excelData !== undefined){
            let columVisibilityIsEmpty = !Object.keys(porps.columVisibility).length;
            let new_data = (excelData ?? []).map(x => {
                let columnsVisible = porps.columOrder.filter(c => c !== 'action' && (porps.columVisibility[c] || columVisibilityIsEmpty));
                let values = columnsVisible.map(key => (x as IIndexable)[key]);
                let item:any = object(columnsVisible.map(k => ColunmHeader[k]), values);
                return item;
            });
            downloadExcel(new_data);
        }
    };

    return (<Button 
            onClick={handleClick}
            disabled={excelData === undefined || excelData.length === 0}
        >
            <FaFileExcel />Exportar como excel
        </Button>)
}
