import instance from "helpers/interceptor";

const execute = (executiveID:string) : Promise<void> => {
    return instance.patch('/executives/'+executiveID+'/toggle')
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;