import { ColumnDef } from '@tanstack/react-table';
import { Datum } from 'types/trackingResponse';
import { PopupActions } from 'components/PopupActions/PopupActions';
import Tooltip from 'components/Tooltip/Tooltip';
import IIndexable from 'types/IIndexable';
import PopupReview from 'components/PopupReview/PopupReview';
import PopupLastStatus from 'components/PopupLastStatus/PopupLastStatus';
import PopupQuantityPackages from './PopupQuantityPackages/PopupQuantityPackages';

export const ColunmHeader: IIndexable = {
	ot: 'OT Delpa',
	otDate: 'Fecha OT',
	status: 'Estado Embarque',
	refSender: 'Ref. Remitente',
	refReceiver: 'Ref. Consignatario',
	sender: 'Remitente',
	receiver: 'Consignatario',
	originCountry: 'Pais origen',
	destinationCountry: 'Pais destino',
	mode: 'Modalidad',
	service: 'Servicio',
	etd: 'ETD',
	eta: 'ETA',
	mercaderiaListaParaEmbarque: 'Mercaderia lista para embarque',
	recogida_en_origen: 'Recogida en origen',
	fecha_arribo_aduana_origen: 'Arribo Aduana de Origen',
	fecha_liberacion_aduana_origen: 'Liberacion Aduana Origen',
	fecha_ingreso_aduana_destino: 'Ingreso Aduana Destino',
	fecha_liberacion_aduana_destino: 'Liberacion Aduana Destino',
	fecha_entrega_final_cliente: 'Entrega cliente final',
	ultimo_status: 'Ultimo estado',
	action: '',
	event: 'Ultimo estatus',
	numero_documento_embarque: 'Documento de embarque',
	metros_cubicos: 'Metros cubicos',
	peso_bruto: 'Peso bruto(Kg)',
	peso_neto: 'Peso neto(Kg)',
	tarifa_crt: 'Tarifa crt',
	cantidad_bultos: 'Descripción de Bultos',
};

const TrackingTableColumDef: ColumnDef<Datum>[] = [
	{
		header: '',
		id: 'action',
		enableSorting: false,
		accessorFn: (val: any) => val,
		cell: (props: any) => (
			<PopupActions data={props.getValue()} type="international" />
		),
		enableHiding: false,
	},
	{
		accessorKey: 'ot',
		id: 'ot',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Identificador de la orden"
				children={<span>{ColunmHeader['ot']}</span>}
			/>
		),
		cell: (props) => (
			<div>
				<span>{props.getValue<string>()}</span>
				<PopupReview
					orden={{
						ot: props.row.original.ot.toString(),
						pais_destino_id: props.row.original.destination_id,
						pais_emisor_id: props.row.original.origin_id,
						sender: props.row.original.sender,
						addressee: props.row.original.receiver,
						reference:
							props.row.original.refSender +
							'_' +
							props.row.original.refReceiver,
						transport_type: props.row.original.mode,
					}}
					// orden={props.row.original.ot.toString()}
					transportWay="international"
					has_review={props.row.original.has_review}
				/>
				{/* )} */}
			</div>
		),
	},
	{
		accessorKey: 'otDate',
		id: 'otDate',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Fecha de creación de la orden"
				children={<span>{ColunmHeader['otDate']}</span>}
			/>
		),
	},
	{
		accessorKey: 'status',
		id: 'status',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Estado de la order"
				children={<span>{ColunmHeader['status']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'event',
		id: 'event',
		header: () => (
			<Tooltip
				text="Ultimo estatus"
				children={<span>{ColunmHeader['event']}</span>}
			/>
		),
		cell: (props) =>
			props.getValue<string | null>() === null ? null : (
				<PopupLastStatus text={props.getValue<string>()} />
			),
	},
	{
		accessorKey: 'refSender',
		id: 'refSender',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Ref. Remitente"
				children={<span>{ColunmHeader['refSender']}</span>}
			/>
		),
	},
	{
		accessorKey: 'refReceiver',
		id: 'refReceiver',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Ref. Consignatario"
				children={<span>{ColunmHeader['refReceiver']}</span>}
			/>
		),
	},
	{
		accessorKey: 'sender',
		id: 'sender',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Remitente"
				children={<span>{ColunmHeader['sender']}</span>}
			/>
		),
	},
	{
		accessorKey: 'receiver',
		id: 'receiver',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Consignatario"
				children={<span>{ColunmHeader['receiver']}</span>}
			/>
		),
	},
	{
		accessorKey: 'originCountry',
		id: 'originCountry',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Pais origen"
				children={<span>{ColunmHeader['originCountry']}</span>}
			/>
		),
	},
	{
		accessorKey: 'destinationCountry',
		id: 'destinationCountry',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Pais destino"
				children={<span>{ColunmHeader['destinationCountry']}</span>}
			/>
		),
	},
	{
		accessorKey: 'mode',
		id: 'mode',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Modalidad"
				children={<span>{ColunmHeader['mode']}</span>}
			/>
		),
	},
	{
		accessorKey: 'service',
		id: 'service',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Servicio"
				children={<span>{ColunmHeader['service']}</span>}
			/>
		),
	},
	{
		accessorKey: 'etd',
		id: 'etd',
		enableSorting: true,
		header: () => (
			<Tooltip text="ETD" children={<span>{ColunmHeader['etd']}</span>} />
		),
	},
	{
		accessorKey: 'eta',
		id: 'eta',
		enableSorting: true,
		header: () => (
			<Tooltip text="ETA" children={<span>{ColunmHeader['eta']}</span>} />
		),
	},

	{
		accessorKey: 'mercaderiaListaParaEmbarque',
		id: 'mercaderiaListaParaEmbarque',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Mercaderia lista para embarque"
				children={
					<span>{ColunmHeader['mercaderiaListaParaEmbarque']}</span>
				}
			/>
		),
	},
	{
		accessorKey: 'recogida_en_origen',
		id: 'recogida_en_origen',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Recogida en origen"
				children={<span>{ColunmHeader['recogida_en_origen']}</span>}
			/>
		),
	},
	{
		accessorKey: 'fecha_arribo_aduana_origen',
		id: 'fecha_arribo_aduana_origen',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Arribo Aduana de Origen"
				children={
					<span>{ColunmHeader['fecha_arribo_aduana_origen']}</span>
				}
			/>
		),
	},
	{
		accessorKey: 'fecha_liberacion_aduana_origen',
		id: 'fecha_liberacion_aduana_origen',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Liberacion Aduana Origen"
				children={
					<span>
						{ColunmHeader['fecha_liberacion_aduana_origen']}
					</span>
				}
			/>
		),
	},
	{
		accessorKey: 'fecha_ingreso_aduana_destino',
		id: 'fecha_ingreso_aduana_destino',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Ingreso Aduana Destino"
				children={
					<span>{ColunmHeader['fecha_ingreso_aduana_destino']}</span>
				}
			/>
		),
	},
	{
		accessorKey: 'fecha_liberacion_aduana_destino',
		id: 'fecha_liberacion_aduana_destino',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Liberacion Aduana Destino"
				children={
					<span>
						{ColunmHeader['fecha_liberacion_aduana_destino']}
					</span>
				}
			/>
		),
	},
	{
		accessorKey: 'fecha_entrega_final_cliente',
		id: 'fecha_entrega_final_cliente',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Entrega cliente final"
				children={
					<span>{ColunmHeader['fecha_entrega_final_cliente']}</span>
				}
			/>
		),
	},
	{
		accessorKey: 'ultimo_status',
		id: 'ultimo_status',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Ultimo estado"
				children={<span>{ColunmHeader['ultimo_status']}</span>}
			/>
		),
	},
	{
		accessorKey: 'numero_documento_embarque',
		id: 'numero_documento_embarque',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Documento de embarque"
				children={
					<span>{ColunmHeader['numero_documento_embarque']}</span>
				}
			/>
		),
	},
	{
		accessorKey: 'metros_cubicos',
		id: 'metros_cubicos',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Metros cubicos"
				children={<span>{ColunmHeader['metros_cubicos']}</span>}
			/>
		),
	},
	{
		accessorKey: 'peso_bruto',
		id: 'peso_bruto',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Peso bruto"
				children={<span>{ColunmHeader['peso_bruto']}</span>}
			/>
		),
	},
	{
		accessorKey: 'peso_neto',
		id: 'peso_neto',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Peso neto"
				children={<span>{ColunmHeader['peso_neto']}</span>}
			/>
		),
	},
	{
		accessorKey: 'tarifa_crt',
		id: 'tarifa_crt',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Tarifa crt"
				children={<span>{ColunmHeader['tarifa_crt']}</span>}
			/>
		),
	},
	{
		accessorKey: 'cantidad_bultos',
		id: 'cantidad_bultos',
		enableSorting: true,
		header: () => (
			<Tooltip
				text="Descripción de Bultos"
				children={<span>{ColunmHeader['cantidad_bultos']}</span>}
			/>
		),
		cell: (props) =>
			props.getValue<string | null>() === null ? null : (
				<PopupQuantityPackages text={props.getValue<string>()} />
			),
	},
];

export default TrackingTableColumDef;
