import { FC, useState } from 'react';
import { FaCloudDownloadAlt, FaFileDownload } from 'react-icons/fa';

import './PopupOrderFiles.css';
import { useQuery } from '@tanstack/react-query';
import getFiles from 'services/getFiles';
import _ from 'underscore';
import { trackingType } from 'types/trackingType';
import getNationalFiles from 'services/getNationalFiles';
import {
	Button,
	Modal,
	Collapse,
	Container,
	Row,
	Col,
	Accordion,
} from 'react-bootstrap';
import AccordionHeader from 'react-bootstrap/esm/AccordionHeader';
import { NoData } from 'components/NoData/NoData';

var Spinner = require('react-spinkit');

export type PopupOrderFilesProps = {
	orden: number;
	type: trackingType;
};

const PopupOrderFiles: FC<PopupOrderFilesProps> = ({ orden, type }) => {
	const [show, setShow] = useState(false);
	const { status, data, error, isFetching, isPreviousData, isLoading } =
		useQuery(
			[
				type == 'international'
					? 'internaltional-files'
					: 'national-files',
				orden,
			],
			() =>
				type == 'international'
					? getFiles(orden.toString())
					: getNationalFiles(orden.toString()),
			{ refetchOnWindowFocus: false, enabled: show }
		);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const [open, setOpen] = useState(false);

	return (
		<>
			<Button
				variant="primary"
				onClick={handleShow}
				style={{ width: '100%' }}
			>
				<FaFileDownload /> Visualizar lista de archivos
			</Button>

			<Modal
				show={show}
				onHide={handleClose}
				dialogClassName="modal-xl"
				centered={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>Visualizacion de archivos</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="text">
						<h3>Lista de Archivos</h3>
						<h5>Aqui podra descargar todos los archivos</h5>
					</div>
					{isLoading ? (
						<span className="d-flex justify-content-center">
							<Spinner name="wandering-cubes" />
						</span>
					) : data === undefined || data.length < 1 ? (
						<NoData />
					) : (
						<div className="content">
							{_.map(
								_.groupBy(
									data != undefined ? data : [],
									'tipo'
								),
								(group, i) => {
									return (
										<Container fluid>
											<Row>
												<Col>
													<Accordion>
														<Accordion.Item
															eventKey={i.toString()}
															className="panel-heading-custom"
														>
															<Accordion.Header className="treeviewBtn w-100">
																{group[0].tipo}
															</Accordion.Header>
															<Accordion.Body>
																<Row>
																	<Col id="example-collapse-text">
																		<div className="divFile">
																			{group.map(
																				(
																					filereponse
																				) => (
																					<a
																						className="treeviewLink w-100"
																						target="_blank"
																						href={
																							filereponse.url
																						}
																					>
																						<FaCloudDownloadAlt
																							style={{
																								color: '#38cab3',
																							}}
																						/>{' '}
																						{
																							filereponse.nombre
																						}
																					</a>
																				)
																			)}
																		</div>
																	</Col>
																</Row>
															</Accordion.Body>
														</Accordion.Item>
													</Accordion>
												</Col>
											</Row>
										</Container>
									);
								}
							)}
						</div>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default PopupOrderFiles;
