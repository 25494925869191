import { useQuery } from "@tanstack/react-query";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { FaSearch } from "react-icons/fa";
import getTokenFromUserID, { userTokenResponse } from "services/getTokenFromUserID";

export type PreviewUserButtonProps ={
    userID:string
} 

export const PreviewUserButton = (props:PreviewUserButtonProps)=>{
    const {isInitialLoading, data, error, isFetching, refetch } = useQuery(
        ['preview-token', props.userID],
        () => getTokenFromUserID(props.userID),
        { 
            refetchOnWindowFocus:false, 
            enabled:false,
            onSuccess:(token)=>{
                openTab(token.token);
            },
            onError:()=>{
                toast.error('Ocurrio un error');
            }
        }
    )

    const openTab = (token:string)=>{
        // window.open(`${process.env.PUBLIC_URL}/dashboard?token=${token}`, '_blank');
        var host = window.location.protocol + "//" + window.location.host;
        window.open(`${host}/dashboard?token=${token}`, '_blank');
    }
    
    const handleClick = () => {
        if (data == undefined) {
            refetch();
            return;
        }
        openTab(data.token);
    };

    return <>
        <Button 
            variant="primary"
            className="w-100"
            disabled={isFetching}
            onClick={handleClick}
        ><FaSearch />Preview</Button>
    </>
}