import { useQuery } from '@tanstack/react-query';
import Select from 'react-select';
import getOriginCountries from 'services/getOriginCountries';
import getDestineCountries from 'services/getDestineCountries';
import getCountriesForCreateUser from 'services/getCountriesForCreateUser';
import { forwardRef } from 'react';

export type CountrySelectProps = {
	onChange?: (e: { value: string | undefined }) => void;
	placeholder: string;
	value?: string;
	name?: string;
	disabled?: boolean;
	allCountryOption?: boolean;
};

// export const CountrySelect = forwardRef<Ref, CountrySelectProps>(({ onChange, placeholder }, ref) => {
export const CountrySelect = forwardRef<any, CountrySelectProps>(
	(
		{ onChange, placeholder, value, name, disabled, allCountryOption },
		ref
	) => {
		const { status, data, error, isFetching, isPreviousData, isLoading } =
			useQuery(
				['contries-executive'],
				() => getCountriesForCreateUser(),
				{ refetchOnWindowFocus: false }
			);

		var options =
			data === undefined
				? []
				: data.map((item) => ({
						label: item.country_name,
						value: item.id_country.toString(),
				  }));

		if (allCountryOption !== undefined && allCountryOption) {
			options.unshift({
				label: 'Todos',
				value: '',
			});
		}

		return (
			<Select
				classNamePrefix="react-select"
				isDisabled={disabled}
				name={name}
				ref={ref}
				placeholder={placeholder}
				options={options}
				value={options.find((c) => value == c.value)}
				onChange={(newValue) => {
					if (onChange != undefined) {
						onChange({ value: newValue?.value });
					}
				}}
			/>
		);
	}
);
