import { Container, Row, Col, Card } from 'react-bootstrap';
import React, { Component } from "react";
import './ContactUsPage.css'


const ContactUsPage = () => {
	return (
		<Container>
			<Row>
				<Col className='text-center' xl={12}>
					<h2>Evaluación de Satisfacción del Cliente</h2>
				</Col>
				<Col className='text-justify' xl={12}>
					<h4>En Delpa nos interesa la opinión de los clientes por eso realizaremos en esta sección una herramienta para medir el grado de satisfacción de nuestros usuarios del sistema, y de esta manera mejorar el servicio logrando mantener una relación positiva con los clientes, ya que su opinión y satisfacción es lo más importante para nosotros.</h4>
				</Col>
				<Col className='text-justify' xl={12}>
					<h6>Esta herramienta sirve para comprender mejor la experiencia del cliente.</h6>
				</Col>
			</Row>
			<Row>
				<Col className='text-center d-flex' xl={6}>
					<p className="clasificacion">
						<input id="radio1" type="radio" name="estrellas" value="5" />
						<label htmlFor="radio1">★</label>
						<input id="radio2" type="radio" name="estrellas" value="4" />
						<label htmlFor="radio2">★</label>
						<input id="radio3" type="radio" name="estrellas" value="3" checked />
						<label htmlFor="radio3">★</label>
						<input id="radio4" type="radio" name="estrellas" value="2" />
						<label htmlFor="radio4">★</label>
						<input id="radio5" type="radio" name="estrellas" value="1" />
						<label htmlFor="radio5">★</label>
					</p>
				</Col>
				<Col className='text-center d-flex' xl={6}>
					<p className="clasificacion">
						<input id="radio1" type="radio" name="estrellas" value="5" />
						<label htmlFor="radio1">★</label>
						<input id="radio2" type="radio" name="estrellas" value="4" />
						<label htmlFor="radio2">★</label>
						<input id="radio3" type="radio" name="estrellas" value="3" checked />
						<label htmlFor="radio3">★</label>
						<input id="radio4" type="radio" name="estrellas" value="2" />
						<label htmlFor="radio4">★</label>
						<input id="radio5" type="radio" name="estrellas" value="1" />
						<label htmlFor="radio5">★</label>
					</p>
				</Col>
			</Row>
		</Container>
	);
};

export default ContactUsPage;