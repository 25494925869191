import instance from 'helpers/interceptor';

const execute = (questionID: number): Promise<void> => {
	return instance
		.patch('/questions/' + questionID + '/toggle')
		.then((response) => response.data)
		.catch((response) => {
			throw new Error(response.response.data.error);
		});
};

export default execute;
