import instance from "helpers/interceptor";
import { eventsResponse } from "types/eventsResponse";

const execute = (orderID : string) : Promise<eventsResponse[]> => {
    return instance.get(`/international-orders/${orderID}/events`)
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;