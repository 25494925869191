import instance from "helpers/interceptor";
import TokenService from "./tokenService";

const execute = () : Promise<string[]> => {
    return instance.get('/national-orders/tipoCargaList')
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;