import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import enableQuestion from 'services/enableQuestion';

const EnableQuestion = ({
	status,
	questionID,
}: {
	status: boolean;
	questionID: number;
}) => {
	const queryClient = useQueryClient();

	const [state, setState] = useState(true);
	useEffect(() => setState(status), [status]);

	const { mutate } = useMutation({
		mutationFn: () => enableQuestion(questionID),
		onSuccess: (data, variables) => {
			toast.success(
				`Pregunta ${!state ? 'activada' : 'desactivada'} con exito`
			);
		},
		onError: (err) => {
			setState((prev) => !prev);
			toast.error('Ocurrio un error');
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ['review-questions'],
			});
		},
	});

	return (
		<Form.Label className="custom-switch ps-0">
			<span className="custom-switch-description tx-17 me-2"></span>
			<Form.Control
				checked={state}
				type="checkbox"
				className="custom-switch-input"
				onChange={(e) => {
					setState((prev) => !prev);
					mutate();
				}}
			/>
			<span className="custom-switch-indicator"></span>
		</Form.Label>
	);
};

export default EnableQuestion;
