import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Alert, Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { FaKey } from "react-icons/fa";
import { updateExecutiveRequest } from "services/updateExecutive";
import updateUser from "services/updateUser";

export type PopupUpdateExecutivePasswordProps = {
    email:string
}

const PopupUpdateExecutivePassword = ({ email }:PopupUpdateExecutivePasswordProps)=>{
    const queryClient = useQueryClient();
    const [show, setShow] = useState(false);
    const defaultValues:updateExecutiveRequest = {
        email: email,
        password: '',
    }

    const { getValues, reset:resetForm, setValue, watch, register, trigger, control, handleSubmit, formState: { errors } } = useForm<updateExecutiveRequest>({
        defaultValues: defaultValues
    });

    const { 
        error,
        isError,
        mutate, 
        isLoading,
    } = useMutation<void, Error, updateExecutiveRequest, any>((body:updateExecutiveRequest) => updateUser(body), {
        onMutate: variables => {
            var toastID = toast.loading('Actualizando, por favor espere...');
            return { toastID };
        },
        onSuccess: (data, variables) => {
            toast.success('Actualizacion de usuario exitosa');
            setShow(false);
        },
        onError: err => {
            toast.error('Ocurrio durante la Actualizacion del usuario');
        },
        onSettled:(data, err, variables, context)=>{
            toast.dismiss(context?.toastID);
            // queryClient.invalidateQueries({ queryKey: ['permission', variables.userID] })
        }
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const onSubmit = (submitData:updateExecutiveRequest) => mutate(submitData);

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                <FaKey /> Cambio de contraseña
            </Button>
    
            <Modal show={show} onHide={handleClose} dialogClassName="modal-xl" centered={true}>
                <Modal.Header closeButton>
                    <Modal.Title>Cambio de contraseña</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col sm={6}>
                                <Form.Label>Correo Electronico</Form.Label>
                                <Form.Control readOnly disabled type="text" placeholder="Correo Electronico" {...register('email', { required:'Correo electronico requerido' })}/>
                                { errors.email && <Form.Text className="text-danger">{ errors.email.message }</Form.Text> }
                            </Col>
                            <Col sm={6}>
                                <Form.Label>Contraseña</Form.Label>
                                <Form.Control type="password" placeholder="Contraseña" {...register('password', { required:'Contraseña requerida' })}/>
                                { errors.password && <Form.Text className="text-danger">{ errors.password.message }</Form.Text> }
                            </Col>
                        </Row>
                        {(isError) &&
                            (<Alert variant='danger'>
                                { error.message }
                            </Alert>)
                        }
                        <Row className="justify-content-md-end">
                            <Col sm={1}>
                                <Button 
                                    type="submit"
                                    variant="primary" 
                                >
                                    Actualizar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </>);
}

export default PopupUpdateExecutivePassword;