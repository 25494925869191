import { useQuery } from '@tanstack/react-query';
import Select from 'react-select';
import getReceivers from 'services/getReceivers';

export type ReceptorSelectProps = {
	onChange?: (e: { value: string | undefined }) => void;
	placeholder: string;
};

export const ReceptorSelect = ({
	onChange,
	placeholder,
}: ReceptorSelectProps) => {
	const { status, data, error, isFetching, isPreviousData, isLoading } =
		useQuery(['receivers-data'], () => getReceivers(), {
			refetchOnWindowFocus: false,
		});

	const options =
		data === undefined
			? []
			: data.map((item) => ({
					label: item.name,
					value: item.ref,
			  }));

	return (
		<Select
			classNamePrefix="react-select"
			placeholder={placeholder}
			options={options}
			isMulti
			onChange={(newValue) => {
				if (onChange != undefined) {
					onChange({ value: newValue.map((i) => i.value).join(',') });
				}
			}}
		/>
	);
};
