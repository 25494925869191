import { FC, useEffect, useRef, useState } from 'react';
import { FaStarHalfAlt } from 'react-icons/fa';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// import './PopupLastMile.css';
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	Modal,
	ProgressBar,
	Row,
} from 'react-bootstrap';
import Rating from '@mui/material/Rating';
import IIndexable from 'types/IIndexable';
import getReviewQuestions, { question } from 'services/getReviewQuestions';
import insertReview, {
	answer,
	insertReviewRequest,
} from 'services/insertReview';
import toast from 'react-hot-toast';
import { nationalTrackingResponse } from 'types/nationalTrackingResponse';
import getReviewByOT, { answers } from 'services/getReviewByOT';
import updateReview, { updateReviewRequest } from 'services/updateReview';
import { useAuth } from 'hooks/useAuth';
import { type } from 'os';

var Spinner = require('react-spinkit');

export type CreateReviewProps = {
	pais_destino_id: number;
	pais_emisor_id: number;
	ot: string;
	sender: string;
	addressee: string;
	reference: string;
	transport_type: 'Aereo' | 'Maritimo' | 'Terrestre';
};

export type PopupReviewProps = {
	orden: CreateReviewProps;
	transportWay: 'international' | 'national';
	has_review: boolean;
};

const CreateReview = ({
	show,
	orden,
	transportWay,
	questions: quest,
	onSuccess,
}: {
	show: boolean;
	orden: CreateReviewProps;
	transportWay: 'international' | 'national';
	questions: question[];
	onSuccess?: () => void;
}) => {
	const queryClient = useQueryClient();
	const { userLogged } = useAuth();

	const [review, setReview] = useState<IIndexable>({});
	// const { status, data, error, isFetching, isLoading } = useQuery(
	// 	['review', 'questions'],
	// 	() => getReviewQuestions(),
	// 	{ refetchOnWindowFocus: false, enabled: show }
	// );

	const { mutate, isLoading: isLoadingSubmit } = useMutation<
		void,
		Error,
		insertReviewRequest,
		any
	>({
		mutationFn: (request) => insertReview(request),
		onMutate: () => {
			var id = toast.loading('Guardando valoración...');
			return { id };
		},
		onSuccess: (data, variable, context) => {
			toast.success('Valoracion guardada', { id: context.id });

			queryClient.invalidateQueries({
				queryKey: [`tracking-${transportWay}-table-data`],
			});
			if (onSuccess) onSuccess();
		},
		onError: (err, variable, context) => {
			toast.error(err.message, { id: context.id });
		},
	});

	const questions = quest.filter(
		(x) => x.is_active && x.transport_way === transportWay
	);

	const handleSubmit = (e: any) => {
		e.preventDefault();

		let answers: answer[] = Object.entries(review).map(
			([key, val]: [string, number]) => ({
				id_question: Number(key),
				ot: orden.ot,
				review: val,
				transport_way: transportWay,

				sender: orden.sender,
				addressee: orden.addressee,
				reference: orden.reference,
				transport_type:
					orden.transport_type === 'Aereo'
						? 'aerial'
						: orden.transport_type === 'Terrestre'
						? 'terrestrial'
						: 'maritime',
				origin_country_id: orden.pais_emisor_id,
				destiny_country_id: orden.pais_destino_id,
			})
		);
		mutate({ answers: answers });
	};

	return (
		<Form
			onSubmit={handleSubmit}
			className="content"
			id="content"
		>
			<div>
				<table className="table table-bordered table-striped">
					<thead>
						<tr>
							<th>Pregunta</th>
							<th>Calificación</th>
						</tr>
					</thead>
					<tbody>
						{(questions ?? []).map((x, i) => (
							<tr key={i}>
								<td
									style={{
										justifyContent: 'space-between',
									}}
									className="d-flex align-items-center"
									key={i}
								>
									{x.question}
								</td>
								<td>
									<Rating
										readOnly={userLogged?.role !== 'client'}
										name="no-value"
										size="medium"
										value={review[x.id] ?? 0}
										max={5}
										onChange={(event, newValue) => {
											setReview((prev) => ({
												...prev,
												[x.id]: newValue,
											}));
										}}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>

				{userLogged?.role === 'client' && (
					<Row>
						<Col></Col>
						<Col>
							<Button
								disabled={
									Object.keys(review).length !==
										questions.length ||
									isLoadingSubmit ||
									Object.values(review).some(
										(x) => x === null
									)
								}
								variant="primary"
								type="submit"
							>
								Guardar
							</Button>
						</Col>
						<Col></Col>
					</Row>
				)}
			</div>
		</Form>
	);
};

type updateForm = {
	answerID: string;
	data: updateReviewRequest;
};
const UpdateReview = ({
	answers,
	questions,
	onSuccess,
	orden,
	transportWay,
}: {
	show: boolean;
	orden: string;
	answers: answers[];
	questions: question[];
	transportWay: 'international' | 'national';
	onSuccess?: () => void;
}) => {
	const queryClient = useQueryClient();
	const { userLogged } = useAuth();

	const [review, setReview] = useState<any[]>(
		answers.map((a) => {
			let found = questions.find((q) => q.id === a.id_question);
			return {
				id_answer: a.id,
				id: a.id_question,
				question: found?.question,
				review: a.review,
			};
		})
	);
	const { mutate, isLoading: isLoadingSubmit } = useMutation<
		void,
		Error,
		updateForm,
		any
	>({
		mutationFn: (request) => updateReview(request.answerID, request.data),
		onMutate: () => {
			var id = toast.loading('Guardando valoración...');
			return { id };
		},
		onSuccess: (data, variable, context) => {
			toast.success('Valoracion guardada', { id: context.id });
			if (onSuccess) onSuccess();
			queryClient.invalidateQueries({
				queryKey: ['review', 'answer', orden, transportWay],
			});
		},
		onError: (err, variable, context) => {
			toast.error(err.message, { id: context.id });
		},
	});

	return (
		<Form
			className="content"
			id="content"
		>
			<div>
				<table className="table table-bordered table-striped">
					<thead>
						<tr>
							<th>Pregunta</th>
							<th>Calificación</th>
						</tr>
					</thead>
					<tbody>
						{(review ?? []).map((x, i) => (
							<tr key={i}>
								<td
									style={{
										justifyContent: 'space-between',
									}}
									className="d-flex align-items-center"
								>
									{x.question}
								</td>
								<td>
									<Rating
										readOnly={userLogged?.role !== 'client'}
										name="no-value"
										size="medium"
										value={x.review ?? 0}
										max={5}
										onChange={(event, newValue) => {
											let indexFound = review.findIndex(
												(q) => q.id === x.id
											);
											let found = review.find(
												(q) => q.id === x.id
											);
											found.review = newValue ?? 1;
											setReview((prev) =>
												Object.assign([], review, {
													indexFound: found,
												})
											);
											mutate({
												answerID: x.id_answer,
												data: { review: newValue ?? 1 },
											});
										}}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</Form>
	);
};

const PopupReview: FC<PopupReviewProps> = ({
	orden,
	transportWay,
	has_review,
}) => {
	const queryClient = useQueryClient();

	const [show, setShow] = useState(false);
	const answerQuery = useQuery(
		['review', 'answer', orden, transportWay],
		() => getReviewByOT({ ot: orden.ot, transport_way: transportWay }),
		{
			refetchOnWindowFocus: false,
			enabled: show,
		}
	);

	const questionsQuery = useQuery(
		['review', 'questions'],
		() => getReviewQuestions(),
		{ refetchOnWindowFocus: false, enabled: show }
	);

	const handleClose = () => {
		setShow(false);
		queryClient.invalidateQueries({
			queryKey: ['review', 'answer', orden, transportWay],
		});
	};
	const handleShow = () => setShow(true);

	return (
		<>
			{has_review ? (
				<Button
					style={{
						border: 'none',
						background: 'inherit',
					}}
					variant="secondary"
					size="sm"
					onClick={handleShow}
				>
					<Rating
						name="read-only"
						value={1}
						max={1}
						readOnly
					/>
				</Button>
			) : (
				<Button
					variant="danger"
					size="sm"
					onClick={handleShow}
				>
					¡Evaluanos!
				</Button>
			)}

			<Modal
				show={show}
				onHide={handleClose}
				centered={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>Evaluar servicio</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						<h5 className="text-justify">
							¿Qué tan satisfecho estás con los siguientes
							aspectos de nuestra marca?
						</h5>
						{answerQuery.isLoading || questionsQuery.isLoading ? (
							<span className="d-flex justify-content-center">
								<Spinner name="wandering-cubes" />
							</span>
						) : !has_review ? (
							<CreateReview
								show={show}
								orden={orden}
								transportWay={transportWay}
								onSuccess={handleClose}
								questions={questionsQuery.data ?? []}
							/>
						) : (
							<UpdateReview
								show={show}
								orden={orden.ot}
								transportWay={transportWay}
								answers={
									answerQuery.data === undefined
										? []
										: answerQuery.data.user_reviews
								}
								questions={questionsQuery.data ?? []}
							/>
						)}
						<ReviewCard
							total_votes={
								answerQuery.data === undefined
									? 0
									: answerQuery.data.total_votes
							}
							stats={
								answerQuery.data === undefined
									? []
									: answerQuery.data.stats
							}
						/>
					</Container>
				</Modal.Body>
			</Modal>
		</>
	);
};

const ReviewCard = ({
	stats,
	total_votes,
}: {
	// stats: [{ votes: number; points: number }];
	stats: any[];
	total_votes: number;
}) => {
	let points = stats
		.map((x) => Number(x.points) * Number(x.votes))
		.reduce((acc, x) => {
			acc += x;
			return acc;
		}, 0);
	let votes = stats.reduce((acc, x) => {
		acc += Number(x.votes);
		return acc;
	}, 0);

	let consolidate = points === 0 && votes === 0 ? 0 : points / votes;
	return (
		<div className="d-flex justify-content-center mt-2">
			<Card style={{ width: 'fit-content' }}>
				<Card.Body>
					<Card.Title>Opiniones de clientes</Card.Title>

					<div>
						<span>
							<Rating
								readOnly
								name="no-value"
								size="medium"
								value={5}
								max={5}
							/>
							{consolidate} de 5
						</span>
						<Form.Text muted>
							{total_votes} calificaciones globales
						</Form.Text>
						<div>
							<ReviewResult
								starNumber={5}
								percent={stats
									.filter((x) => x.points === 5)
									.reduce((acc, x) => {
										acc += Number(x.perc_votes);
										return acc;
									}, 0)}
							/>
							<ReviewResult
								starNumber={4}
								percent={stats
									.filter((x) => x.points === 4)
									.reduce((acc, x) => {
										acc += Number(x.perc_votes);
										return acc;
									}, 0)}
							/>
							<ReviewResult
								starNumber={3}
								percent={stats
									.filter((x) => x.points === 3)
									.reduce((acc, x) => {
										acc += Number(x.perc_votes);
										return acc;
									}, 0)}
							/>
							<ReviewResult
								starNumber={2}
								percent={stats
									.filter((x) => x.points === 2)
									.reduce((acc, x) => {
										acc += Number(x.perc_votes);
										return acc;
									}, 0)}
							/>
							<ReviewResult
								starNumber={1}
								percent={stats
									.filter((x) => x.points === 1)
									.reduce((acc, x) => {
										acc += Number(x.perc_votes);
										return acc;
									}, 0)}
							/>
						</div>
					</div>
				</Card.Body>
			</Card>
		</div>
	);
};

const ReviewResult = ({
	starNumber,
	percent,
}: {
	starNumber: number;
	percent: number;
}) => {
	return (
		<>
			<div className="mb-1">
				<div className="d-flex">
					<span className="tx-13 font-weight-semibold">
						{starNumber} extrellas
					</span>
					<div className="ms-auto">({percent}%)</div>
				</div>
				<div className="progress ht-8 br-5 mt-2">
					<ProgressBar
						now={percent}
						animated
						variant="warning"
					/>
				</div>
			</div>
		</>
	);
};

export default PopupReview;
