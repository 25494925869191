import instance from 'helpers/interceptor';

export type updateReviewRequest = {
	review: number;
};

const execute = (
	answerID: string,
	data: updateReviewRequest
): Promise<void> => {
	return instance
		.patch(`/answer/${answerID}`, data)
		.then((response) => response.data)
		.catch((response) => {
			throw new Error(response.response.data.error);
		});
};

export default execute;
