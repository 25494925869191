import instance from "helpers/interceptor";

export type executive = {
    email: string;
    id: string;
    country_id:string;
    name:string;
    created_by:string;
    last_login:Date;
    country_name:string;
    created_by_name:string;
    enable: boolean;
}

const execute = () : Promise<Array<executive>> => {

    return instance.get('/executives')
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;