import instance from "helpers/interceptor";

export type companies = {
    nombre_usuario: number;
    empresa_usuario: string;
    email: string;
    rut_usuario: string;
    rut_cliente: string;
    nombre_fantasia: string;
    active: boolean;
}

const execute = () : Promise<companies[]> => {

    return instance.get('/companies')
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;