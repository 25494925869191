import instance from "helpers/interceptor";
import { createUserRequest } from "types/createUserRequest";
import TokenService from "./tokenService";

const execute = (data:createUserRequest) : Promise<void> => {
    return instance.post('/users', data)
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;