import { Rating } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import { PopupActions } from 'components/PopupActions/PopupActions';
import PopupLastStatus from 'components/PopupLastStatus/PopupLastStatus';
import PopupReview from 'components/PopupReview/PopupReview';
import Tooltip from 'components/Tooltip/Tooltip';
import TokenService from 'services/tokenService';
import IIndexable from 'types/IIndexable';
import { Datum } from 'types/nationalTrackingResponse';

export const ColunmHeader: IIndexable = {
	ot: 'Orden',
	fecha_emision: 'Fecha de emision',
	pais_emisor: 'Pais emisor',
	pais_origen: 'Pais origen',
	pais_destino: 'Pais destino',
	// tipo_flete: 'Tipo de flete',
	cliente_facturacion: 'Cliente facturacion',
	referencia_cliente: 'Referencia cliente',
	remitente: 'Remitente',
	referencia_remitente: 'Referencia remitente',
	destinatario: 'Destinatario',
	referencia_destinatario: 'Referencia destinatario',
	modal: 'Modal',
	servicio: 'Servicio',
	producto: 'Producto',
	ciudad_recogida: 'Ciudad recogida',
	ciudad_entrega: 'Ciudad entrega',
	direccion_recogida: 'Direccion recogida',
	direccion_entrega: 'Direccion entrega',
	fecha_recogida: 'Fecha recogida',
	estado_recogida: 'Estado recogida',
	fecha_entrega: 'Fecha entrega',
	estado_entrega: 'Estado entrega',
	estado_embarque: 'Estado enbarque',
	peso_bruto: 'Peso bruto',
	volumen: 'Volumen',
	cantidad: 'Cantidad',
	embalaje: 'Embalaje',
	tipo_carga: 'Tipo de carga',
	event: 'Ultimo estatus',
};

const NationalTableColumDef: ColumnDef<Datum>[] = [
	{
		enableSorting: false,
		enableHiding: false,
		header: '',
		id: 'action',
		accessorFn: (val: any) => val,
		cell: (props: any) => (
			<PopupActions
				data={props.getValue()}
				type="national"
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'ot',
		id: 'ot',
		header: () => (
			<Tooltip
				text="ot"
				children={<span>{ColunmHeader['ot']}</span>}
			/>
		),
		cell: (props) => (
			<div>
				<div>{props.getValue<string>()}</div>
				<PopupReview
					orden={{
						ot: props.row.original.ot,
						pais_destino_id: props.row.original.pais_destino_id,
						pais_emisor_id: props.row.original.pais_emisor_id,
						sender: props.row.original.remitente,
						addressee: props.row.original.destinatario,
						reference: props.row.original.referencia_destinatario,
						transport_type: 'Terrestre',
					}}
					transportWay="national"
					has_review={props.row.original.has_review}
				/>
			</div>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'fecha_emision',
		id: 'fecha_emision',
		header: () => (
			<Tooltip
				text="Fecha de emision"
				children={<span>{ColunmHeader['fecha_emision']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'pais_emisor',
		id: 'pais_emisor',
		header: () => (
			<Tooltip
				text="Pais origen"
				children={<span>{ColunmHeader['pais_emisor']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'pais_destino',
		id: 'pais_destino',
		header: () => (
			<Tooltip
				text="Pais destino"
				children={<span>{ColunmHeader['pais_destino']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'event',
		id: 'event',
		header: () => (
			<Tooltip
				text="Ultimo estatus"
				children={<span>{ColunmHeader['event']}</span>}
			/>
		),
		cell: (props) =>
			props.getValue<string | null>() === null ? null : (
				<PopupLastStatus text={props.getValue<string>()} />
			),
	},
	{
		enableSorting: true,
		accessorKey: 'cliente_facturacion',
		id: 'cliente_facturacion',
		header: () => (
			<Tooltip
				text="Cliente facturacion"
				children={<span>{ColunmHeader['cliente_facturacion']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'referencia_cliente',
		id: 'referencia_cliente',
		header: () => (
			<Tooltip
				text="Referencia cliente"
				children={<span>{ColunmHeader['referencia_cliente']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'remitente',
		id: 'remitente',
		header: () => (
			<Tooltip
				text="Remitente"
				children={<span>{ColunmHeader['remitente']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'referencia_remitente',
		id: 'referencia_remitente',
		header: () => (
			<Tooltip
				text="Referencia remitente"
				children={<span>{ColunmHeader['referencia_remitente']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'destinatario',
		id: 'destinatario',
		header: () => (
			<Tooltip
				text="Destinatario"
				children={<span>{ColunmHeader['destinatario']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'referencia_destinatario',
		id: 'referencia_destinatario',
		header: () => (
			<Tooltip
				text="Referencia destinatario"
				children={
					<span>{ColunmHeader['referencia_destinatario']}</span>
				}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'modal',
		id: 'modal',
		header: () => (
			<Tooltip
				text="Modal"
				children={<span>{ColunmHeader['modal']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'servicio',
		id: 'servicio',
		header: () => (
			<Tooltip
				text="Servicio"
				children={<span>{ColunmHeader['servicio']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'producto',
		id: 'producto',
		header: () => (
			<Tooltip
				text="Producto"
				children={<span>{ColunmHeader['producto']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'ciudad_recogida',
		id: 'ciudad_recogida',
		header: () => (
			<Tooltip
				text="Ciudad recogida"
				children={<span>{ColunmHeader['ciudad_recogida']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'ciudad_entrega',
		id: 'ciudad_entrega',
		header: () => (
			<Tooltip
				text="Ciudad entrega"
				children={<span>{ColunmHeader['ciudad_entrega']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'direccion_recogida',
		id: 'direccion_recogida',
		header: () => (
			<Tooltip
				text="Direccion recogida"
				children={<span>{ColunmHeader['direccion_recogida']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'direccion_entrega',
		id: 'direccion_entrega',
		header: () => (
			<Tooltip
				text="Direccion entrega"
				children={<span>{ColunmHeader['direccion_entrega']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'fecha_recogida',
		id: 'fecha_recogida',
		header: () => (
			<Tooltip
				text="Fecha recogida"
				children={<span>{ColunmHeader['fecha_recogida']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'estado_recogida',
		id: 'estado_recogida',
		header: () => (
			<Tooltip
				text="Estado recogida"
				children={<span>{ColunmHeader['estado_recogida']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'fecha_entrega',
		id: 'fecha_entrega',
		header: () => (
			<Tooltip
				text="'Fecha entrega"
				children={<span>{ColunmHeader['fecha_entrega']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'estado_entrega',
		id: 'estado_entrega',
		header: () => (
			<Tooltip
				text="Estado entrega"
				children={<span>{ColunmHeader['estado_entrega']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'estado_embarque',
		id: 'estado_embarque',
		header: () => (
			<Tooltip
				text="Estado enbarque"
				children={<span>{ColunmHeader['estado_embarque']}</span>}
			/>
		),
	},

	{
		enableSorting: true,
		accessorKey: 'peso_bruto',
		id: 'peso_bruto',
		header: () => (
			<Tooltip
				text="Peso bruto"
				children={<span>{ColunmHeader['peso_bruto']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'volumen',
		id: 'volumen',
		header: () => (
			<Tooltip
				text="Volumen"
				children={<span>{ColunmHeader['volumen']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'cantidad',
		id: 'cantidad',
		header: () => (
			<Tooltip
				text="Cantidad"
				children={<span>{ColunmHeader['cantidad']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'embalaje',
		id: 'embalaje',
		header: () => (
			<Tooltip
				text="Embalaje"
				children={<span>{ColunmHeader['embalaje']}</span>}
			/>
		),
	},
	{
		enableSorting: true,
		accessorKey: 'tipo_carga',
		id: 'tipo_carga',
		header: () => (
			<Tooltip
				text="Tipo de carga"
				children={<span>{ColunmHeader['tipo_carga']}</span>}
			/>
		),
	},
];

export default NationalTableColumDef;
