import { session } from "./login";

const getUser = () : session | null => {
    let userLocal = localStorage.getItem("sesion");
    if (userLocal == null) return null;
    return JSON.parse(userLocal);
};

const setUser = (user:session) => {
    localStorage.setItem("sesion", JSON.stringify(user));
};

const removeUser = () => {
    localStorage.removeItem("sesion");
};

const TokenService = {
    getUser,
    setUser,
    removeUser,
};

export default TokenService;