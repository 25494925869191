import { useQuery } from '@tanstack/react-query';
import Select from 'react-select';
import getNationalTypeLoad from 'services/getNationalTypeLoad';

export type NationalTipoEmbalajeSelectProps = {
	onChange?: (e: { value: string | undefined }) => void;
	placeholder: string;
};

export const NationalTipoEmbalajeSelect = ({
	onChange,
	placeholder,
}: NationalTipoEmbalajeSelectProps) => {
	const { status, data, error, isFetching, isPreviousData, isLoading } =
		useQuery(['national-tipo-embalaje-data'], () => getNationalTypeLoad(), {
			refetchOnWindowFocus: false,
		});

	const options =
		data === undefined
			? []
			: data
					.filter((x) => x)
					.map((item) => ({
						label: item,
						value: item,
					}));

	return (
		<Select
			classNamePrefix="react-select"
			placeholder={placeholder}
			options={options}
			isMulti
			onChange={(newValue) => {
				if (onChange != undefined) {
					onChange({ value: newValue.map((i) => i.value).join(',') });
				}
			}}
		/>
	);
};
