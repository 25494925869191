import React, { useMemo, useState } from 'react';
import {
	Breadcrumb,
	Button,
	Card,
	Col,
	ListGroup,
	ListGroupItem,
	Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import getDashboardInfo, {
	getDashashboardInfoRequest,
} from 'services/getDashboardInfo';
import Icon from '@mdi/react';
import { mdiTruck, mdiAirplaneTakeoff, mdiFerry, mdiHome } from '@mdi/js';
import './Dashboard.css';
import { DatePicker } from 'components/DatePicker/DatePicker';
import dateFormat from 'helpers/dateFormat';
import moment from 'moment';
var _ = require('lodash');

var Spinner = require('react-spinkit');

const Dashboard = () => {
	const [filter, setFilter] = useState<any>({
		since: '',
		until: '',
	});
	const defaultRange = useMemo(
		() => ({ start: moment().subtract(1, 'y'), end: moment() }),
		[]
	);

	const param: getDashashboardInfoRequest = {
		since: dateFormat(
			filter.since === '' ? defaultRange.start : filter.since
		),
		until: dateFormat(
			filter.until === '' ? defaultRange.end : filter.until
		),
	};
	const { data, error, isFetching, isInitialLoading } = useQuery(
		['tracking-dashboard', param],
		() => getDashboardInfo(param),
		{ staleTime: 5000, refetchOnWindowFocus: false }
	);

	if (isInitialLoading) {
		return (
			<Spinner
				className="main-loading"
				name="wandering-cubes"
			/>
		);
	}

	const listLastOrders = data?.recents_register.map((recentRegister, i) => {
		let icon = '';
		let colorClass = '';

		if (recentRegister.mode === 'Terrestre') {
			icon = mdiTruck;
			colorClass = 'bg-primary';
		}

		if (recentRegister.mode === 'Maritimo') {
			icon = mdiFerry;
			colorClass = 'bg-warning';
		}

		if (recentRegister.mode === 'Aereo') {
			icon = mdiAirplaneTakeoff;
			colorClass = 'bg-danger';
		}

		let classRound = `me-3 text-primary transaction-icon ${colorClass}`;

		return (
			<a
				key={i}
				target="_blank"
				href={`https://trackingdelpa.com/index.html?order=${recentRegister.ot}&type=in`}
				className="border-0"
			>
				<ListGroupItem className="list-group-item-action border-0">
					<div className="media mt-0">
						<span className={classRound}>
							<Icon
								path={icon}
								size={1}
								color="white"
							/>
						</span>
						<div className="media-body">
							<div className="d-flex align-items-center">
								<div>
									<span className="tx-10">
										<span className="font-weight-bold">
											Orden:{' '}
										</span>
										{recentRegister.ot}
									</span>
									<div className="mt-0">
										<div className="mb-1 tx-13 font-weight-sembold text-dark">
											<div className="me-3 tx-10">
												<span className="font-weight-bold">
													Remitente:{' '}
												</span>
												<span>
													{recentRegister.sender}
												</span>
											</div>
											<div className="me-3 tx-10">
												<span className="font-weight-bold">
													Consignatario:{' '}
												</span>
												<span>
													{recentRegister.receiver}
												</span>
											</div>
											<span className="badge badge-primary">
												{recentRegister.ultimo_status}
											</span>
										</div>
										<p className="mb-0 tx-12 text-muted">
											{recentRegister.otDate}
										</p>
									</div>
									<div>
										<div className="tx-10">
											<span className="font-weight-bold">
												Ref.Sender:{' '}
											</span>
											{recentRegister.refSender}
										</div>
										<div className="tx-10">
											<span className="font-weight-bold">
												Ref.Receptor:{' '}
											</span>
											{recentRegister.refReceiver}
										</div>
									</div>
								</div>
								<span className="ms-auto wd-25p tx-12 d-flex justify-content-start">
									<div className="d-flex flex-column float-end font-weight-semibold">
										<span>
											<span className="font-weight-bold">
												Desde:
											</span>
											{recentRegister.originCountry}
										</span>
										<span>
											<span className="font-weight-bold">
												Hasta:
											</span>
											{recentRegister.destinationCountry}
										</span>
									</div>
								</span>
							</div>
						</div>
					</div>
				</ListGroupItem>
			</a>
		);
	});

	const listEnviadosDesde = data?.enviados_desde.map((exportRegisters, i) => {
		let maxCuenta = _.sumBy(data?.enviados_desde, 'cuenta');

		let percentage = ((exportRegisters.cuenta / maxCuenta) * 100).toFixed(
			2
		);

		return (
			<div
				className="mb-4"
				key={i}
			>
				<div className="d-flex">
					<span className="tx-13 font-weight-semibold">
						{exportRegisters.pais_origen}
					</span>
					<div className="ms-auto">
						<span className="number-font">
							{exportRegisters.cuenta}
						</span>{' '}
						({percentage}%)
					</div>
				</div>
				<div className="progress progress-style ht-5 mt-2 mb-4">
					<div
						className="progress-bar bg-primary"
						style={{ width: percentage + '%' }}
						role="progressbar"
						aria-valuenow={exportRegisters.cuenta}
						aria-valuemin={0}
						aria-valuemax={maxCuenta}
					></div>
				</div>
			</div>
		);
	});

	const listEnviadosHasta = data?.enviados_hasta.map((exportRegisters, i) => {
		let maxCuenta = _.sumBy(data?.enviados_hasta, 'cuenta');

		let percentage = ((exportRegisters.cuenta / maxCuenta) * 100).toFixed(
			2
		);

		return (
			<div
				className="mb-4"
				key={i}
			>
				<div className="d-flex">
					<span className="tx-13 font-weight-semibold">
						{exportRegisters.pais_destino}
					</span>
					<div className="ms-auto">
						<span className="number-font">
							{exportRegisters.cuenta}
						</span>{' '}
						({percentage}%)
					</div>
				</div>
				<div className="progress progress-style ht-5 mt-2 mb-4">
					<div
						className="progress-bar bg-primary"
						style={{ width: percentage + '%' }}
						role="progressbar"
						aria-valuenow={exportRegisters.cuenta}
						aria-valuemin={0}
						aria-valuemax={maxCuenta}
					></div>
				</div>
			</div>
		);
	});

	return (
		<div>
			{/* <!-- breadcrumb --> */}
			<div className="breadcrumb-header justify-content-between">
				<div className="left-content">
					<span className="main-content-title mg-b-0 mg-b-lg-1">
						DASHBOARD
					</span>
					<DatePicker
						startDate={
							filter.since !== ''
								? filter.since
								: defaultRange.start
						}
						endDate={
							filter.until !== ''
								? filter.until
								: defaultRange.end
						}
						placeholder="Fecha ot inicio - Fecha ot final"
						onChange={([start, end]) =>
							setFilter((oldFilter: any) => {
								return {
									...oldFilter,
									since: start == null ? '' : start,
									until: end == null ? '' : end,
								};
							})
						}
					/>
				</div>
			</div>

			<Row>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
				>
					<Col
						xl={12}
						lg={12}
						md={12}
						sm={12}
						className="px-0"
					>
						<Card className=" px-3 ps-4">
							<Row className="index1">
								<Col
									xl={6}
									lg={6}
									md={6}
									sm={6}
									xxl={3}
								>
									<Row className=" border-end bd-xs-e-0 p-3">
										<div className="col-3 d-flex align-items-center justify-content-center">
											<div className="circle-icon bg-primary text-center align-self-center overflow-hidden shadow">
												<Icon
													path={mdiTruck}
													size={1}
													color="white"
												/>
											</div>
										</div>
										<div className="col-9 py-0">
											<div className="pt-4 pb-3">
												<div className="d-flex">
													<h6 className="mb-2 tx-12">
														Ordenes Internacionales
														Terrestres
													</h6>
												</div>
												<div className="pb-0 mt-0">
													<div className="d-flex">
														<h4 className="tx-18 font-weight-semibold mb-0">
															{data
																?.register_count
																.terrestre ?? 0}
														</h4>
													</div>
												</div>
											</div>
										</div>
									</Row>
								</Col>
								<Col
									xl={6}
									lg={6}
									md={6}
									sm={6}
									xxl={3}
								>
									<Row className=" border-end bd-md-e-0 bd-xs-e-0 bd-lg-e-0 bd-xl-e-0  p-3">
										<div className="col-3 d-flex align-items-center justify-content-center">
											<div className="circle-icon bg-warning text-center align-self-center overflow-hidden shadow">
												<Icon
													path={mdiAirplaneTakeoff}
													size={1}
													color="white"
												/>
											</div>
										</div>
										<div className="col-9">
											<div className="pt-4 pb-3">
												<div className="d-flex">
													<h6 className="mb-2 tx-12">
														Ordenes Internacionales
														Aereos
													</h6>
												</div>
												<div className="pb-0 mt-0">
													<div className="d-flex">
														<h4 className="tx-18 font-weight-semibold mb-0">
															{data
																?.register_count
																.aereo ?? 0}
														</h4>
													</div>
												</div>
											</div>
										</div>
									</Row>
								</Col>
								<Col
									xl={6}
									lg={6}
									md={6}
									sm={6}
									xxl={3}
								>
									<Row className=" border-end bd-xs-e-0  p-3">
										<div className="col-3 d-flex align-items-center justify-content-center">
											<div className="circle-icon bg-secondary text-center align-self-center overflow-hidden shadow">
												<Icon
													path={mdiFerry}
													size={1}
													color="white"
												/>
											</div>
										</div>
										<div className="col-9">
											<div className="pt-4 pb-3">
												<div className="d-flex">
													<h6 className="mb-2 tx-12">
														Ordenes Internacionales
														Maritimos
													</h6>
												</div>
												<div className="pb-0 mt-0">
													<div className="d-flex">
														<h4 className="tx-18 font-weight-semibold mb-0">
															{data
																?.register_count
																.maritimo ?? 0}
														</h4>
													</div>
												</div>
											</div>
										</div>
									</Row>
								</Col>
								<Col
									xl={6}
									lg={6}
									md={6}
									sm={6}
									xxl={3}
								>
									<Row className="p-3">
										<div className="col-3 d-flex align-items-center justify-content-center">
											<div className="circle-icon bg-info text-center align-self-center overflow-hidden shadow">
												<Icon
													path={mdiHome}
													size={1}
													color="white"
												/>
											</div>
										</div>
										<div className="col-9">
											<div className="pt-4 pb-3">
												<div className="d-flex	">
													<h6 className="mb-2 tx-12">
														Ordenes Nacionales
													</h6>
												</div>
												<div className="pb-0 mt-0">
													<div className="d-flex">
														<h4 className="tx-18 font-weight-semibold mb-0">
															{data
																?.register_count
																.nacional ?? 0}
														</h4>
													</div>
												</div>
											</div>
										</div>
									</Row>
								</Col>
							</Row>
						</Card>
					</Col>
					<Col
						xl={12}
						lg={12}
						md={12}
						sm={12}
						className="px-0"
					>
						<Row>
							<Col
								sm={12}
								lg={12}
								xl={4}
							>
								<Card>
									<Card.Header className=" pb-3">
										<h3 className="card-title mb-2">
											Importaciones
										</h3>
									</Card.Header>
									<Card.Body className=" p-0 customers mt-1">
										<div className="country-card pt-0">
											{listEnviadosDesde}
										</div>
									</Card.Body>
								</Card>
							</Col>

							<Col
								sm={12}
								lg={12}
								xl={4}
							>
								<Card>
									<Card.Header className=" pb-3">
										<h3 className="card-title mb-2">
											Exportaciones
										</h3>
									</Card.Header>
									<Card.Body className=" p-0 customers mt-1">
										<div className="country-card pt-0">
											{listEnviadosHasta}
										</div>
									</Card.Body>
								</Card>
							</Col>

							<Col
								xs={12}
								lg={12}
								md={12}
								xl={12}
								xxl={4}
							>
								<Card className="overflow-hidden">
									<Card.Header className=" pb-1">
										<h3 className="card-title mb-2">
											Ultimas Ordenes Internacionales
										</h3>
									</Card.Header>
									<Card.Body className=" p-0 customers mt-1">
										{listLastOrders}
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Col>
				</Col>
			</Row>
		</div>
	);
};

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

export default Dashboard;
