import { useQuery } from '@tanstack/react-query';
import Select from 'react-select';
import getServices from 'services/getServices';

export type ServiceSelectProps = {
	onChange?: (e: { value: string | undefined }) => void;
	placeholder: string;
};

export const ServiceSelect = ({
	onChange,
	placeholder,
}: ServiceSelectProps) => {
	const { status, data, error, isFetching, isPreviousData, isLoading } =
		useQuery(['services-data'], () => getServices(), {
			refetchOnWindowFocus: false,
		});

	const options =
		data === undefined
			? []
			: data.map((item) => ({
					label: item.service,
					value: item.service,
			  }));

	return (
		<Select
			classNamePrefix="react-select"
			placeholder={placeholder}
			options={options}
			isMulti
			onChange={(newValue) => {
				if (onChange != undefined) {
					onChange({ value: newValue.map((i) => i.value).join(',') });
				}
			}}
		/>
	);
};
