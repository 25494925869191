import instance from "helpers/interceptor";

export interface DateCreated {
    date: string;
    timezone_type: number;
    timezone: string;
}

export interface DateModified {
    date: string;
    timezone_type: number;
    timezone: string;
}

export interface Billing {
    first_name: string;
    last_name: string;
    company: string;
    address_1: string;
    address_2: string;
    city: string;
    state: string;
    postcode: string;
    country: string;
    email: string;
    phone: string;
}

export interface Shipping {
    first_name: string;
    last_name: string;
    company: string;
    address_1: string;
    address_2: string;
    city: string;
    state: string;
    postcode: string;
    country: string;
}

export interface DateCompleted {
    date: string;
    timezone_type: number;
    timezone: string;
}

export interface DatePaid {
    date: string;
    timezone_type: number;
    timezone: string;
}

export interface MetaData {
    id: number;
    key: string;
    value: string;
}

export interface Taxes {
    total: any[];
    subtotal: any[];
}

export interface MetaData2 {
    id: number;
    key: string;
    value: string;
}

export interface Tag {
    term_id: number;
    name: string;
    slug: string;
    term_group: number;
    term_taxonomy_id: number;
    taxonomy: string;
    description: string;
    parent: number;
    count: number;
    filter: string;
}

export interface Categoria {
    term_id: number;
    name: string;
    slug: string;
    term_group: number;
    term_taxonomy_id: number;
    taxonomy: string;
    description: string;
    parent: number;
    count: number;
    filter: string;
}

export interface LineItem {
    id: number;
    order_id: number;
    name: string;
    product_id: number;
    variation_id: number;
    quantity: number;
    tax_class: string;
    subtotal: string;
    subtotal_tax: string;
    total: string;
    total_tax: string;
    taxes: Taxes;
    meta_data: MetaData2[];
    tags: Tag[];
    categorias: Categoria[];
    marcas: string;
    sku: string;
    price: string;
}

export interface ShippingLines {
    465805: any[];
}

export interface Orden {
    id: number;
    parent_id: number;
    status: string;
    currency: string;
    version: string;
    prices_include_tax: boolean;
    date_created: DateCreated;
    date_modified: DateModified;
    discount_total: string;
    discount_tax: string;
    shipping_total: string;
    shipping_tax: string;
    cart_tax: string;
    total: string;
    total_tax: string;
    customer_id: number;
    order_key: string;
    billing: Billing;
    shipping: Shipping;
    payment_method: string;
    payment_method_title: string;
    transaction_id: string;
    customer_ip_address: string;
    customer_user_agent: string;
    created_via: string;
    customer_note: string;
    date_completed: DateCompleted;
    date_paid: DatePaid;
    cart_hash: string;
    number: string;
    meta_data: MetaData[];
    line_items: LineItem[];
    tax_lines: any[];
    shipping_lines: ShippingLines;
    fee_lines: any[];
    coupon_lines: any[];
    status_api: string;
    fechacreacion: Date;
}

export interface SubresourceUris {
    media: string;
}

export interface Datos {
    body: string;
    numSegments: string;
    direction: string;
    from: string;
    to: string;
    dateUpdated: Date;
    price?: any;
    errorMessage?: any;
    uri: string;
    accountSid: string;
    numMedia: string;
    status: string;
    messagingServiceSid?: any;
    sid: string;
    dateSent?: any;
    dateCreated: Date;
    errorCode?: any;
    priceUnit?: any;
    apiVersion: string;
    subresourceUris: SubresourceUris;
}

export interface Notificacionwsshopperdetalle {
    status: number;
    datos: Datos;
}

export interface SubresourceUris2 {
    media: string;
}

export interface Datos2 {
    body: string;
    numSegments: string;
    direction: string;
    from: string;
    to: string;
    dateUpdated: Date;
    price?: any;
    errorMessage?: any;
    uri: string;
    accountSid: string;
    numMedia: string;
    status: string;
    messagingServiceSid?: any;
    sid: string;
    dateSent?: any;
    dateCreated: Date;
    errorCode?: any;
    priceUnit?: any;
    apiVersion: string;
    subresourceUris: SubresourceUris2;
}

export interface Notificacionwspickingdetalle {
    status: number;
    datos: Datos2;
}

export interface SubresourceUris3 {
    media: string;
}

export interface Datos3 {
    body: string;
    numSegments: string;
    direction: string;
    from: string;
    to: string;
    dateUpdated: Date;
    price?: any;
    errorMessage?: any;
    uri: string;
    accountSid: string;
    numMedia: string;
    status: string;
    messagingServiceSid?: any;
    sid: string;
    dateSent?: any;
    dateCreated: Date;
    errorCode?: any;
    priceUnit?: any;
    apiVersion: string;
    subresourceUris: SubresourceUris3;
}

export interface Notificacionwsrepartodetalle {
    status: number;
    datos: Datos3;
}

export interface SubresourceUris4 {
    media: string;
}

export interface Datos4 {
    body: string;
    numSegments: string;
    direction: string;
    from: string;
    to: string;
    dateUpdated: Date;
    price?: any;
    errorMessage?: any;
    uri: string;
    accountSid: string;
    numMedia: string;
    status: string;
    messagingServiceSid?: any;
    sid: string;
    dateSent?: any;
    dateCreated: Date;
    errorCode?: any;
    priceUnit?: any;
    apiVersion: string;
    subresourceUris: SubresourceUris4;
}

export interface Notificacionwsentregadetalle {
    status: number;
    datos: Datos4;
}

export interface ManifiestosHijo {
    confirmados: any[];
    metadata: any[];
    norecibidos: any[];
    pedidos: string[];
    parent: string[];
    _id: string;
    id: string;
    fechadespacho: string;
    operador: string;
    propietario: string;
    operador_nombre: string;
    tipo: string;
    vehiculoasignado: string;
    status: string;
    fechasignacion: string;
    idvehiculo: string;
}

export interface ManifiestosPadre {
    confirmados: any[];
    metadata: any[];
    norecibidos: any[];
    pedidos: any[];
    parent: any[];
    _id: string;
    id: string;
    propietario: string;
    asignacion: string;
    fechadespacho: string;
    idvehiculo: string;
    operador: string;
    operador_nombre: string;
    status: string;
    vehiculoasignado: string;
}

export interface Etiqueta {
    _id: string;
    idrecurso: string;
    numero: number;
    identificador: string;
    ot: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface LastMileResponse {
    _id: string;
    pedido: string;
    propietario: string;
    comunaprueba: string;
    metodo: string;
    orden: Orden;
    priority: string;
    notificacionwsshopper: string;
    notificacionwsshopperdetalle: Notificacionwsshopperdetalle;
    boleta: string;
    bultos: string;
    congelado: string;
    fechadespacho: string;
    manifiesto: string;
    nota: string;
    ot: string;
    pagolisto: string;
    productos: any[];
    refrigerado: string;
    responsable: string;
    responsablepago: string;
    responsablepagofecha: string;
    vistobueno: string;
    comuna: string;
    lat: string;
    lng: string;
    pais: string;
    notificacionwspicking: string;
    notificacionwspickingdetalle: Notificacionwspickingdetalle;
    estadoentrega: string;
    fechaentrega: string;
    notificacionwsentrega: string;
    notificacionwsreparto: string;
    notificacionwsrepartodetalle: Notificacionwsrepartodetalle;
    notificacionwsentregadetalle: Notificacionwsentregadetalle;
    conductor: boolean;
    estados: any[];
    manifiestos_hijo: ManifiestosHijo[];
    manifiestos_padre: ManifiestosPadre[];
    etiquetas: Etiqueta[];
}

const execute = (orderID : string) : Promise<LastMileResponse> => {
    return instance.get(`/national-orders/${orderID}/lastMile`)
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;