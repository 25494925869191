import './MultiTrackingPage.css'
import { Container, Row, Col } from 'react-bootstrap';
import React from 'react';

const MultiTrackingPage = () => {
	return (
		<Container>
			<Row>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'center'}}
				>
					<img
						src={require('../../assets/img/multitracking.png')}
						className="w-75"
						alt="embebido"
					/>
					<p className='my-3 text-3xl'>MULTI-TRACKING</p>
				</Col>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'justify'}}
				>
					<p className='text-xl'>Nuestra nueva plataforma Multi-Tracking te ofrece seguimiento detallado en un solo lugar. Estamos conectados con los principales transportistas y carriers del mundo para brindarte acceso a la información. Podrás rastrear múltiples envíos a diferentes destinos, todo desde una sola plataforma.</p>
				</Col>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'center'}}
				>
					<p className='text-lg font-bold'>BENEFICIOS PARA NUESTROS CLIENTES</p>
				</Col>
				<Col
					xxl={12}
					xl={12}
					lg={12}
					md={12}
					sm={12}
					style={{'textAlign':'justify'}}
				>
					<ul className="list-disc ml-5 text-lg lg:text-xl flex flex-col gap-2"><li>Agrupa en un solo lugar envíos de diferentes compañías de transporte.</li><li>Realiza un seguimiento completo de todas las etapas de tus envíos, tanto nacionales como internacionales.</li><li>Identifica y visualiza de manera clara una variedad de envíos de diferentes proveedores y destinos, todo en un solo lugar.</li></ul>
				</Col>
			</Row>
		</Container>
	);
};

export default MultiTrackingPage;
