import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import enableExecutive from 'services/enableExecutive';

const EnableExecutive = ({
	status,
	executiveID,
}: {
	status: boolean;
	executiveID: string;
}) => {
	const queryClient = useQueryClient();

	const [state, setState] = useState(true);
	useEffect(() => setState(status), [status]);

	const { mutate } = useMutation({
		mutationFn: () => enableExecutive(executiveID),
		onSuccess: (data, variables) => {
			toast.success(
				`Cuenta ${!state ? 'activada' : 'desactivada'} con exito`
			);
		},
		onError: (err) => {
			setState((prev) => !prev);
			toast.error('Ocurrio un error');
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ['user-data', 'executive-data'],
			});
		},
	});

	return (
		<div className="eyes-content">
			<input
				className="switch"
				{...{
					id: executiveID,
					type: 'checkbox',
					checked: state,
					onChange: (e) => {
						setState((prev) => !prev);
						mutate();
					},
				}}
			/>{' '}
			<label
				htmlFor={executiveID}
				className="switchLabel"
			>
				Toggle
			</label>
		</div>
	);
};

export default EnableExecutive;
