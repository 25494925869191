import instance from "helpers/interceptor";
import { updateUserRequest } from "types/createUserRequest";
import IIndexable from "types/IIndexable";

const execute = (params:updateUserRequest) : Promise<void> => {
    let paramsWitoutNulls = Object
    .entries(params)
    .reduce((acc, [key, value]) =>{
        if(value !== '' && value !== undefined){
            (acc as IIndexable)[key] = value; 
        }
        return acc;
    }, {});

    return instance.put(`/users`, paramsWitoutNulls)
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;