import { useMemo } from 'react';
import Select from 'react-select';

export type StatusSelectProps = {
	onChange?: (e: { value: string | undefined }) => void;
	placeholder: string;
};

export const StatusSelect = ({ onChange, placeholder }: StatusSelectProps) => {
	let options = useMemo(
		() =>
			[
				'Anulada',
				'Arribada',
				'Arribado',
				'En Transito Internacional',
				'En Transito Puerto Embarque',
				'Ingreso Puerto Embarque',
				'Pendiente Entrega Proveedor',
				'Sin Estado',
			].map((item) => ({
				label: item,
				value: item,
			})),
		[]
	);

	return (
		<Select
			classNamePrefix="react-select"
			placeholder={placeholder}
			options={options}
			isMulti
			onChange={(newValue) => {
				if (onChange != undefined) {
					onChange({ value: newValue.map((i) => i.value).join(',') });
				}
			}}
		/>
	);
};
