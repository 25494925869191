import { FC, useState } from 'react';
import { FaCalendar, FaCircle, FaMapMarkerAlt } from 'react-icons/fa';
import { useQuery } from '@tanstack/react-query';
import './PopupLastMile.css';
import {
	Accordion,
	Button,
	Card,
	Col,
	Container,
	Form,
	Modal,
	Row,
} from 'react-bootstrap';
import getNationalLastMile from 'services/getNationalLastMile';
import moment from 'moment';
import { NoData } from 'components/NoData/NoData';

var Spinner = require('react-spinkit');

export type PopupLastMileProps = {
	orden: number;
};

const StateCard = ({ data }: any) => {
	const info = data.metadatos;
	return (
		<Row>
			<Col sm={12}>
				{info.map((x: any, i: number) => {
					if (
						x.tipo_accion === 'campo-texto' ||
						x.tipo_accion === 'selector'
					) {
						return (
							<div key={i}>
								<span className="font-weight-bold">
									{x.titulo}:
								</span>{' '}
								{x.valor}
							</div>
						);
					}

					if (x.tipo_accion === 'carga-imagenes') {
						return (
							<div key={i}>
								<div className="font-weight-bold">
									{x.titulo}
								</div>
								<Row>
									{x.valores.map((y: any, j: number) => {
										return (
											<a
												key={j}
												className="zoom"
												href={y.url}
												target="_blank"
											>
												<Card.Img
													style={{
														width: '100px',
													}}
													variant="top"
													src={y.url}
												/>
											</a>
										);
									})}
								</Row>
							</div>
						);
					}

					if (x.tipo_accion === 'firma-digital') {
						return (
							<Card.Img
								key={i}
								style={{ width: '100px' }}
								variant="top"
								src={x.valor}
							/>
						);
					}

					return null;
				})}
			</Col>
		</Row>
	);
};

moment.locale('es', {
	months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split(
		'_'
	),
	monthsShort:
		'Enero._Feb._Mar_Abr._May_Jun_Jul._Ago_Sept._Oct._Nov._Dec.'.split('_'),
	weekdays: 'Domingo_Lunes_Martes_Miercoles_Jueves_Viernes_Sabado'.split('_'),
	weekdaysShort: 'Dom._Lun._Mar._Mier._Jue._Vier._Sab.'.split('_'),
	weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),
});

const PopupLastMile: FC<PopupLastMileProps> = ({ orden }) => {
	const [show, setShow] = useState(false);
	const { status, data, error, isFetching, isPreviousData, isLoading } =
		useQuery(
			['national-last-mile', orden],
			() => getNationalLastMile(orden.toString()),
			{ refetchOnWindowFocus: false, enabled: show }
		);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<>
			<Button
				variant="primary"
				onClick={handleShow}
				style={{ width: '100%' }}
			>
				<FaCalendar /> Visualizar ultima milla
			</Button>

			<Modal
				show={show}
				onHide={handleClose}
				// dialogClassName="modal-xl"
				centered={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>Información de ultima milla</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{isLoading ? (
						<span className="d-flex justify-content-center">
							<Spinner name="wandering-cubes" />
						</span>
					) : !data || !data.orden ? (
						<NoData />
					) : (
						<Container fluid className="content" id="content">
							<span>
								<span className="font-weight-bold">
									Pedido:
								</span>{' '}
								{`${data?.pedido}`}{' '}
								{moment(data?.orden.fechacreacion).format(
									'DD-MM-YYYY HH:mm:ss'
								)}
							</span>
							<div>
								<p className="h5">Informacion de facturacion</p>
								<div>
									<div>
										<span className="font-weight-bold">
											Nombre y apellido:{' '}
										</span>
										<span>{`${data?.orden.billing.first_name} ${data?.orden.billing.last_name}`}</span>
									</div>
									<div>
										<span className="font-weight-bold">
											Direccion:
										</span>{' '}
										<span>
											{data?.orden.billing.address_1}
										</span>
									</div>
								</div>
								<div>
									<div>
										<span className="font-weight-bold">
											Correo:
										</span>{' '}
										<span>{data?.orden.billing.email}</span>
									</div>
									<div>
										<span className="font-weight-bold">
											Telefono:
										</span>{' '}
										<span>{data?.orden.billing.phone}</span>
									</div>
								</div>
							</div>
							<div>
								<hr />
								<p className="h5">Informacion de envio</p>
								<div>
									<div>
										<span className="font-weight-bold">
											Nombre y apellido:{' '}
										</span>
										<span>
											{data?.orden.shipping.first_name}{' '}
											{data?.orden.shipping.last_name}
										</span>
									</div>
									<div>
										<span className="font-weight-bold">
											Direccion:{' '}
										</span>
										<span>
											{data?.orden.shipping.address_1}
										</span>
									</div>
								</div>
							</div>
							<div>
								<hr />
								<p className="h5">Items del envio</p>
								<Row>
									{data?.orden.line_items.map((item) => {
										return (
											<Col xs={4} key={item.id}>
												<Row>
													<span className="font-weight-bold">
														Nombre:{' '}
													</span>
													<span>{item.name}</span>
												</Row>
												<Row>
													<span className="font-weight-bold">
														Cantidad:{' '}
													</span>
													<span>{item.quantity}</span>
												</Row>
											</Col>
										);
									})}
								</Row>
							</div>
							<div>
								<hr />
								<p className="h5">Estados del envio</p>
								<Accordion
									defaultActiveKey={
										data === undefined
											? '0'
											: data.estados.map((_, i) =>
													i.toString()
											  )
									}
									flush
									className="acc"
								>
									{data?.estados.map((estado, i) => (
										<Accordion.Item
											eventKey={i.toString()}
											className="accordion-toggle panel-heading1"
										>
											<Accordion.Header>
												<div>
													<p className="text-muted">
														{moment(
															estado.fecha
														).format(
															'dddd, D [de] MMMM [de] YYYY - HH:mm [Hrs.]'
														)}
													</p>
													<Row>
														<FaCircle
															color={
																estado.estado_color
															}
														/>
														<p>
															{estado.estado}{' '}
															{estado.subestado}
														</p>
													</Row>
												</div>
											</Accordion.Header>
											<Accordion.Body className="borders">
												<Container fluid>
													<Button
														variant="primary"
														target="_blank"
														href={`https://www.google.com/maps/?q=${estado.location.coordinates[0]},${estado.location.coordinates[1]}`}
													>
														<FaMapMarkerAlt />{' '}
														Visualizar ubicación
													</Button>
													<div>
														<span className="font-weight-bold">
															Canal:
														</span>{' '}
														{estado.canal.replace(
															'_',
															' '
														)}
													</div>
													<div>
														<span className="font-weight-bold">
															Por:
														</span>{' '}
														{
															estado.identificador_creador
														}
													</div>
													<StateCard data={estado} />
												</Container>
											</Accordion.Body>
										</Accordion.Item>
									))}
								</Accordion>
							</div>
						</Container>
					)}
				</Modal.Body>
			</Modal>
		</>
	);
};

export default PopupLastMile;
