import instance from "helpers/interceptor";
import { servicesResponse } from "types/servicesResponse";
import TokenService from "./tokenService";

const execute = () : Promise<servicesResponse[]> => {
    return instance.get('/national-orders/productsList')
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;