import { CellContext, ColumnDef } from '@tanstack/react-table';
import Tooltip from 'components/Tooltip/Tooltip';
import IIndexable from 'types/IIndexable';
import { Badge, Button, Form } from 'react-bootstrap';
import { PopupUserActions } from 'components/PopupUserActions/PopupUserActions';
import moment from 'moment';
import { FaTimesCircle } from 'react-icons/fa';
import PopupCompaniesOfUser from 'components/PopupCompaisOfUser/PopupCompaniesOfUser';
import { user } from 'services/getUser';
import { question } from 'services/getReviewQuestions';
import EnableQuestion from './EnableQuestion';

export const ColunmHeader: IIndexable = {
	id: 'ID',
	question: 'Pregunta',
	transport_way: 'Tipo de flete',
	is_active: 'Activo',
};

const questionsTableColumDef: ColumnDef<question>[] = [
	// {
	// 	enableSorting: false,
	// 	enableHiding: false,
	// 	header: '',
	// 	id: 'action',
	// 	accessorFn: (val: question) => val,
	// 	cell: (props) => <PopupUserActions user={props.getValue<user>()} />,
	// },
	{
		accessorKey: 'id',
		id: 'id',
		header: () => (
			<Tooltip
				text="id"
				children={<span>{ColunmHeader['id']}</span>}
			/>
		),
	},
	{
		accessorKey: 'question',
		id: 'question',
		header: () => (
			<Tooltip
				text="Pregunta"
				children={<span>{ColunmHeader['question']}</span>}
			/>
		),
	},
	{
		accessorKey: 'transport_way',
		id: 'transport_way',
		header: () => (
			<Tooltip
				text="Tipo de flete"
				children={<span>{ColunmHeader['transport_way']}</span>}
			/>
		),
		cell: (props) => (
			<span>
				{props.getValue() === 'international'
					? 'internacional'
					: 'nacional'}
			</span>
		),
	},
	{
		accessorKey: 'is_active',
		id: 'is_active',
		header: () => (
			<Tooltip
				text="Activo"
				children={<span>{ColunmHeader['is_active']}</span>}
			/>
		),
		cell: (props: any) => (
			<EnableQuestion
				status={props.getValue()}
				questionID={props.row.original.id}
			/>
		),
	},
];

export default questionsTableColumDef;
