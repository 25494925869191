import { FC } from "react";
import {FaEye, FaEyeSlash} from "react-icons/fa";

type EyeCheckProps = {
    status: boolean
}

export const EyeCheck: FC<EyeCheckProps> = ({status}) => {

    if(status) {
        return (<FaEye/>)
    }

    return (<FaEyeSlash/>);
}