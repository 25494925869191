import instance from "helpers/interceptor";

export type country = {
    code_country:string
    country_name:string
    id_country:number
}

const execute = () : Promise<country[]> => {
    return instance.get('/countries')
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;