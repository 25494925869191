import instance from "helpers/interceptor";
export type createExecutiveRequest = {
    email:string
    password:string,
    country_id:string
    name:string
}


const execute = (data:createExecutiveRequest) : Promise<void> => {
    return instance.post('/executives', data)
    .then(response => response.data)
    .catch(response => {
        throw new Error(response.response.data.error);
    });
}

export default execute;