import IIndexable from 'types/IIndexable';

const defecto: IIndexable = {
	orden_creada:
		'Le permitirá ser notificado cuando su orden de flete ya se encuentre ingresada',
	mercaderia_lista_para_embarque:
		'Le permitirá ser notificado cuando su carga se encuentre preparada y disponible para ser embarcada hacia destino',
	recogida_en_origen:
		'Le permitirá ser notificado cuando su carga haya sido recogida desde la dirección de origen y se encuentre disponible para comenzar su tránsito hacia destino',
	arribo_aduana_de_origen:
		'Le permitirá ser notificado cuando su carga arribe a la aduana de origen y comience su trámite de liberación',
	liberacion_aduana_origen:
		'Le permitirá ser notificado cuando su carga haya sido liberada desde la aduana de origen y continúa su tránsito hacia aduana de destino',
	etd: 'Le permitirá ser notificado cuando su carga haya salido desde la aduana de origen y comience su tránsito hacia la aduana de destino',
	eta: 'Le permitirá ser notificado cuando su carga haya  arribado a la aduana de destino y comienza su trámite de liberación en destino',
	ingreso_aduana_destino:
		'Le permitirá ser notificado cuando su carga haya  ingresado a la aduana de destino y comienza su trámite de liberación en destino',
	liberacion_aduana_destino:
		'Le permitirá ser notificado cuando su carga haya  sido liberada desde la aduana de distinto destino y continúe su tránsito hacia la dirección de entrega final',
	entrega_cliente_final:
		'Le permitirá ser notificado cuando su carga haya sido entregada exitosamente en destino',
};

const eventDescription: IIndexable = {
	['a']: {
		...defecto,
		['etd']:
			'Le permitirá ser notificado cuando su carga haya salido desde el aeropuerto de origen y comience su tránsito hacia el aeropuerto de destino',
		['eta']:
			'Le permitirá ser notificado cuando su carga haya arribado al aeropuerto de destino',
	},
	['t']: { ...defecto },
	['m']: {
		...defecto,
		['etd']:
			'Le permitirá ser notificado cuando su carga haya salido desde el puerto de origen y comience su tránsito hacia el puerto de destino',
		['eta']:
			'Le permitirá ser notificado cuando su carga haya arribado al puerto de destino',
	},
};

export default eventDescription;
