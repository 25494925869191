import { useQuery } from '@tanstack/react-query';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { CountrySelect } from 'components/PopupCreateExecutive/components/CountrySelect/CountrySelect';
import PopupCreateExecutive from 'components/PopupCreateExecutive/PopupCreateExecutive';
import TableV2 from 'components/TableV2/TableV2';
import { useMemo, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import getExecutiveUser from 'services/getExecutiveUser';
import UserTableColumDef from './components/ExecutiveTableColumDef';

interface filter {
	email: string;
	startDate: string;
	endDate: string;
	country: string;
}

const ExecutivePage = () => {
	const [filter, setFilter] = useState<filter>({
		email: '',
		startDate: '',
		endDate: '',
		country: '',
	});

	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});

	const [sorting, setSorting] = useState<SortingState>([]);
	const defaultData = useMemo(() => [], []);
	const columns = useMemo(() => UserTableColumDef, []);
	const { data, error, isFetching, isPreviousData, isLoading } = useQuery(
		['executive-data'],
		() => getExecutiveUser(),
		{ keepPreviousData: true, staleTime: 5000, refetchOnWindowFocus: false }
	);

	const countryChange = (country: string) => {
		setFilter((prev: any) => ({ ...prev, country: country }));
	};

	const filterData = (data ?? [])
		.filter((item) => item.email.toLowerCase().indexOf(filter.email) > -1)
		// .filter(item => filter.startDate == '' ? true : moment(item.created_at).isBetween(filter.startDate, filter.endDate, undefined, '[]'))
		.filter((item) =>
			filter.country == '' ? true : item.country_id == filter.country
		);

	return (
		<>
			<Container fluid>
				<Row>
					<Col sm={4}>
						<Form.Control
							type="text"
							placeholder="Username"
							onChange={(e) =>
								setFilter((prev: any) => ({
									...prev,
									email: e.target.value.toLowerCase(),
								}))
							}
						/>
					</Col>
					<Col sm={4}>
						<CountrySelect
							placeholder="Paises"
							onChange={(e) => countryChange(e.value ?? '')}
							value={filter.country}
							allCountryOption={true}
						/>
					</Col>
				</Row>
			</Container>
			<TableV2
				header={
					<Col>
						<PopupCreateExecutive />
					</Col>
				}
				registerCount={filterData.length}
				// data={ data ?? defaultData }
				data={filterData}
				columns={columns}
				loading={false}
				pagination={{
					pageIndex,
					pageSize,
				}}
				pageCount={0}
			/>
		</>
	);
};

export default ExecutivePage;
