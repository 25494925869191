import { atom } from "recoil";

const headerCartAtom = atom({
    key: 'headerCarts', // unique ID (with respect to other atoms/selectors)
    default: {
        terrestre: 3,
        aereo: 0,
        maritimo: 0
    }, // default value (aka initial value)
});

export default headerCartAtom;