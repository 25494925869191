import { FC, useCallback, useState } from "react";
import "./PopupPermissions.css";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Collapse, Container, Row, Col, Accordion } from "react-bootstrap";
import getPermissionByUser, { permission } from "services/getPermissionByUser";
import updatePermissionByUser, { updatePermissionByUserRequest } from "services/updatePermissionByUser";
import toast from "react-hot-toast";

import Icon from '@mdi/react';
import { mdiAlertOctagon } from '@mdi/js';
import _ from "underscore";

var Spinner = require('react-spinkit');

export type PopupPermissionsProps = {
    userID:string
}

const PermissionSwitch = ({ data, onChange }:{ data:permission, onChange:(value:string, status:boolean) => void })=>{
    const [ state, setState ] = useState(data.active);
    return (<div className='eyes-content'>
        <input className='switch'
            {...{
                id:data.description,
                type: 'checkbox',
                checked: state,
                onChange: (e)=> {
                    setState(prev => !prev);
                    onChange(data.id.toString(), e.target.checked)
                }

            }}
        />{' '}<label htmlFor={data.description} className="switchLabel">Toggle</label>
        <span>{data.description}</span>
    </div>)
}

const PopupPermissions: FC<PopupPermissionsProps> = ({ userID })=>{
    const queryClient = useQueryClient();

    const [show, setShow] = useState(false);
    const { status, data, error, isFetching, isPreviousData, isLoading } = useQuery(
        [ `permission`, userID ],
        () => getPermissionByUser(userID) ,
        { 
            refetchOnWindowFocus:false, 
            enabled:show 
        },
    )

    const { 
        mutate, 
    } = useMutation({
        mutationFn:(body:updatePermissionByUserRequest) => updatePermissionByUser(body),
        onMutate: variables => {
            var toastID = toast.loading('Actualizando, por favor espere...');
            return { toastID };
          },
        onSuccess: (data, variables) => {
            toast.success(`Permiso actualizado con exito`);
        },
        onError: asd => {
            toast.error('Ocurrio un error');
        },
        onSettled:(data, error, variables, context)=>{
            toast.dismiss(context?.toastID);
            queryClient.invalidateQueries({ queryKey: ['permission', variables.userID] })
        }
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onChange = useCallback((permissionID:string, status:boolean) => mutate({ 
        userID,
        permissionID,
        status
    }), []);
    // const onChange = (name:string, status:boolean) => console.log(name, status);
    
    return (
    <>
        <Button variant="primary" onClick={handleShow} style={{width:"100%"}}>
            <Icon 
                path={mdiAlertOctagon}
                size={1}
                color="white"/>Permisos
        </Button>

        <Modal show={show} onHide={handleClose} dialogClassName="modal-xl" centered={true}>
            <Modal.Header closeButton>
                <Modal.Title>Configuración de permisos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isLoading ?
                    <span className='d-flex justify-content-center'><Spinner name='wandering-cubes' /></span> :
                    <Container fluid>
                        <Row className='row-container'>
                            {_.sortBy((data ?? []),['name']).map(item => <PermissionSwitch key={item.name} data={item} onChange={onChange}/>)}
                        </Row>
                    </Container>
                }
            </Modal.Body>
        </Modal>
    </>);
}

export default PopupPermissions;