import { FC, useEffect, useRef, useState } from 'react';
import { FaStarHalfAlt } from 'react-icons/fa';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
// import './PopupLastMile.css';
import {
	Button,
	Card,
	Col,
	Container,
	Form,
	Modal,
	ProgressBar,
	Row,
} from 'react-bootstrap';
import Rating from '@mui/material/Rating';
import IIndexable from 'types/IIndexable';
import getReviewQuestions, { question } from 'services/getReviewQuestions';
import insertReview, {
	answer,
	insertReviewRequest,
} from 'services/insertReview';
import toast from 'react-hot-toast';
import { nationalTrackingResponse } from 'types/nationalTrackingResponse';
import getReviewByOT, { answers } from 'services/getReviewByOT';
import updateReview, { updateReviewRequest } from 'services/updateReview';
import { useAuth } from 'hooks/useAuth';
import { type } from 'os';

var Spinner = require('react-spinkit');

export type PopupPreviewReviewProps = {
	orden: string;
	transportWay: 'international' | 'national';
	id_user: number;
};

type updateForm = {
	answerID: string;
	data: updateReviewRequest;
};

const UpdateReview = ({
	answers,
	questions,
	onSuccess,
	orden,
	transportWay,
}: {
	show: boolean;
	orden: string;
	answers: answers[];
	questions: question[];
	transportWay: 'international' | 'national';
	onSuccess?: () => void;
}) => {
	const queryClient = useQueryClient();
	const { userLogged } = useAuth();

	const [review, setReview] = useState<any[]>(
		answers.map((a) => {
			let found = questions.find((q) => q.id === a.id_question);
			return {
				id_answer: a.id,
				id: a.id_question,
				question: found?.question,
				review: a.review,
			};
		})
	);

	return (
		<Form
			className="content"
			id="content"
		>
			<div>
				<table className="table table-bordered table-striped">
					<thead>
						<tr>
							<th>Pregunta</th>
							<th>Calificación</th>
						</tr>
					</thead>
					<tbody>
						{(review ?? []).map((x, i) => (
							<tr key={i}>
								<td
									style={{
										justifyContent: 'space-between',
									}}
									className="d-flex align-items-center"
								>
									{x.question}
								</td>
								<td>
									<Rating
										readOnly={userLogged?.role !== 'client'}
										name="no-value"
										size="medium"
										value={x.review ?? 0}
										max={5}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</Form>
	);
};

const PopupPreviewReview: FC<PopupPreviewReviewProps> = ({
	orden,
	transportWay,
	id_user,
}) => {
	const queryClient = useQueryClient();

	const [show, setShow] = useState(false);
	const answerQuery = useQuery(
		['review', 'answer', orden, transportWay],
		() =>
			getReviewByOT({
				ot: orden,
				transport_way: transportWay,
				user_id: id_user,
			}),
		{
			refetchOnWindowFocus: false,
			enabled: show,
		}
	);

	const questionsQuery = useQuery(
		['review', 'questions'],
		() => getReviewQuestions(),
		{ refetchOnWindowFocus: false, enabled: show }
	);

	const handleClose = () => {
		setShow(false);
		queryClient.invalidateQueries({
			queryKey: ['review', 'answer', orden, transportWay],
		});
	};
	const handleShow = () => setShow(true);

	return (
		<>
			<Button
				variant="warning"
				size="sm"
				onClick={handleShow}
			>
				Ver revisión
			</Button>
			<Modal
				show={show}
				onHide={handleClose}
				centered={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>Evaluacion de servicio</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container fluid>
						{answerQuery.isLoading || questionsQuery.isLoading ? (
							<span className="d-flex justify-content-center">
								<Spinner name="wandering-cubes" />
							</span>
						) : (
							<UpdateReview
								show={show}
								orden={orden}
								transportWay={transportWay}
								answers={
									answerQuery.data === undefined
										? []
										: answerQuery.data.user_reviews
								}
								questions={questionsQuery.data ?? []}
							/>
						)}
					</Container>
				</Modal.Body>
			</Modal>
		</>
	);
};

export default PopupPreviewReview;
