import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  useQuery,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query"
import { RecoilRoot } from 'recoil';
import 'bootstrap/dist/css/bootstrap.css';
import './index.css';
import './index.scss';
import Loader from "./shade/Loaders/Loaders"
import MyRoutes from "./hooks/requireAuth";
import { Toaster } from 'react-hot-toast';
import RecoilNexus from 'recoil-nexus';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <RecoilRoot>
          <RecoilNexus />
          <BrowserRouter>
            <React.Suspense fallback={<Loader/>}>
              <Toaster 
                position="top-right"
                containerStyle={{
                  zIndex: 1000000000,
                }}
              />
              <MyRoutes />
            </React.Suspense>
          </BrowserRouter>
        </RecoilRoot>
      </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
