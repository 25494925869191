import { Container, Row, Col } from 'react-bootstrap';
import './WhatsappInfoPage.css';

const WhatsappInfoPage = () => {
	return (
		<Container fluid className='background'>
			<Row>
				<Col className='col-per'
					md={3}
				>
					<ul className='ulList'>
						<li className='list'><p>Orden Creada</p><span>17-05-2022</span></li>
						<li className='list'><p>Mercadería lista para embarque</p><span>17-05-2022</span></li>
						<li className='list'><p>Recogida en Origen</p><span>17-05-2022</span></li>
						<li className='list'><p>Arribo Aduana de Origen</p><span>20-05-2022</span></li>
						<li className='list'><p>Liberación Aduana de Origen</p><span>26-05-2022</span></li>
						<li className='list'><p>Salida Aduana Origen (ETD)</p><span>26-05-2022</span></li>
						<li className='list disabled'><p>Arribo Aduana Destion (ETA)</p><span>30-05-2022</span></li>
						<li className='list disabled'><p>Ingreso Aduana Destino</p><span>30-05-2022</span></li>
						<li className='list disabled'><p>Liberación Aduana Destino</p><span>06-06-2022</span></li>
						<li className='list disabled'><p>Entrega cliente final</p><span>10-06-2022</span></li>
					</ul>
				</Col>
				<Col className='col-per d-flex justify-content-center align-items-center'
					md={4}
				>
					<img src={require('../../assets/img/notifications/phone.png')} alt="Phone" className='phone' />
				</Col>
				<Col className='col-per'
					md={5}
				>
					<p className='title'>Notificaciones</p>
					<p className='subtitle'>en tiempo real.</p>
					<article>
					Próximamente podrás configurar notificaciones personalizadas para recibir
					información sobre cada etapa de tu embarque. A través de esta plataforma
					podrás elegir cómo, cuando y por donde recibir notificaciones de los eventos
					de tus envíos.
					</article>
					<div className='mt-5 d-flex justify-content-center align-items-center'>
						<Col md={6}><img src={require('../../assets/img/notifications/social-networks.png')} alt="" /></Col>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default WhatsappInfoPage;
