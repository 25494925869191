import instance from 'helpers/interceptor';

const execute = (email: string): Promise<void> => {
	return instance
		.get('/users/sendresetemail', { params: { email } })
		.then((response) => response.data)
		.catch((response) => {
			throw new Error(response.response.data.error);
		});
};

export default execute;
