import instance from 'helpers/interceptor';

export type answer = {
	id_question: number;
	ot: string;
	review: number;
	transport_way: 'international' | 'national';

	sender: string;
	addressee: string;
	reference: string;
	transport_type: 'aerial' | 'terrestrial' | 'maritime';
	origin_country_id: number;
	destiny_country_id: number;
};
export type insertReviewRequest = {
	answers: answer[];
};

const execute = (data: insertReviewRequest): Promise<void> => {
	return instance
		.post(`/questions/valorate`, data)
		.then((response) => response.data)
		.catch((response) => {
			throw new Error(response.response.data.error);
		});
};

export default execute;
